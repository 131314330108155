import React, { useState, useEffect } from 'react';
import { Breadcrumbs, ThemeProvider, Table, TableCell, Paper, TableHead, TableRow, TableBody, TableContainer, TextField } from '@material-ui/core';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { red, grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate';

import './Especialidades.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import especialidadesData from './especialidadesData';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C3000A",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: red
  },
});

const especialidadesSorted = especialidadesData.sort((a, b) => { return a.nome.localeCompare(b.nome) })

const initPagination = {
  data: especialidadesSorted,
  offset: 0,
  numberPerPage: 10,
  pageCount: 0,
  currentData: []
}

const Especialidades = () => {
  let history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const [ filterTable, setFilterTable ] = useState('');
  const [ pagination, setPagination ] = useState(initPagination);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: especialidadesSorted
      .filter(el => el.nome.toLowerCase()
        .includes(filterTable.toLowerCase())).length / prevState.numberPerPage,
      data: especialidadesSorted
      .filter(el => el.nome.toLowerCase()
        .includes(filterTable.toLowerCase())),
      currentData: especialidadesSorted
        .filter(el => el.nome.toLowerCase()
          .includes(filterTable.toLowerCase()))
            .slice(pagination.offset, pagination.offset + pagination.numberPerPage)
    }))
  }, [pagination.numberPerPage, pagination.offset, filterTable])

  const handlePageClick = event => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage
    setPagination({ ...pagination, offset })
  }

  const handleClick = path => history.push(path);
  const handleChangeFilter = event => {
    setFilterTable(event.target.value)
  }

  return (
    <>
    <Header />
    <MenuRapido />
    <section id="especialidades-page-section"> 
      <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-especialidades">			      
        <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">				      
          <HomeIcon id="breadcrumb-home-icon" />			      
        </a>
        <a
          color="inherit"
          href="/especialidades"
          onClick={() => handleClick("/especialidades-por-unidades")}
          className="breadcrumb-link"
        >
          Especialidades por Unidades
        </a>
      </Breadcrumbs>
      <div id="especialidades-content">
        <h2 className="h2-gray-center">Especialidades por Unidades</h2>
        <div id="espialidades-list-container">
        <ThemeProvider theme={theme}>
        <TextField 
						className="input-filter"
						placeholder="Pesquise por especialidade" 
						onChange={handleChangeFilter}
					/>
        </ThemeProvider>
        {isMobile ?
        <ul id="card-list-mobile"> 
          {
            pagination.currentData
              .map((row, index) => (
                <li 
                  key={row.nome}
                  className={`row-card-mobile${index % 2 === 0 ? "" : "-odd"}`}
                >
                  <p className="title-card-mobile"><strong>{row.nome}</strong></p>
                  <div className="inline-content-card-mobile">
                    <p>
                      Ipiranga: {row.ipiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                    </p>
                    <p>
                      Pompeia: {row.pompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                    </p>
                    <p>
                      Santana: {row.santana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                    </p>
                  </div>
                </li>
              ))
          }
          <div id="footer-table-page">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={pagination.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={true}
            />
          </div>
        </ul>
          :
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell id="table-cell-3"><b>Especialidade</b></StyledTableCell>
                <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {pagination.currentData.map((row) => (
                <StyledTableRow key={row.nome}>
                  <StyledTableCell component="th" scope="row">
                    {row.nome}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.ipiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                  <StyledTableCell align="center">{row.pompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                  <StyledTableCell align="center">{row.santana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <div id="footer-table-page">
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Próxima'}
              breakLabel={'...'}
              pageCount={pagination.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={true}
            />
          </div>
        </TableContainer>
        }
        </div>
      </div>		
    </section>
    <Footer/>
    </>
  )
}

export default Especialidades