// import imagemTeste from '../../images/images/especialidades/teste.webp';

const especialidades =[
        {
            nome: "ALERGIA E IMUNOLOGIA ADULTO",
            alt: "HSC - Especialidade - ALERGIA E IMUNOLOGIA ADULTO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ALERGIA E IMUNOLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - ALERGIA E IMUNOLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ANESTESIOLOGIA",
            alt: "HSC - Especialidade - ANESTESIOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ANGIOLOGIA ",
            alt: "HSC - Especialidade - ANGIOLOGIA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "AVALIAÇÃO DE MARCAPASSO",
            alt: "HSC - Especialidade - AVALIAÇÃO DE MARCAPASSO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "BUCO MAXILO",
            alt: "HSC - Especialidade - BUCO MAXILO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CANCEROLOGIA",
            alt: "HSC - Especialidade - CANCEROLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CARDIOLOGIA",
            alt: "HSC - Especialidade - CARDIOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CARDIOLOGIA CHECK UP",
            alt: "HSC - Especialidade - CARDIOLOGIA CHECK UP",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CARDIOLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - CARDIOLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: false,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "CIRURGIA APARELHO DIGESTIVO / CIRURGIA BARIÁTRICA ",
            alt: "HSC - Especialidade - CIRURGIA APARELHO DIGESTIVO / CIRURGIA BARIÁTRICA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA CABEÇA E PESCOÇO",
            alt: "HSC - Especialidade - CIRURGIA CABEÇA E PESCOÇO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA DE MÃO",
            alt: "HSC - Especialidade - CIRURGIA DE MÃO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA GERAL",
            alt: "HSC - Especialidade - CIRURGIA GERAL",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA ONCOLÓGICA",
            alt: "HSC - Especialidade - CIRURGIA ONCOLÓGICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "CIRURGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - CIRURGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "CIRURGIA PLÁSTICA REPARADORA ",
            alt: "HSC - Especialidade - CIRURGIA PLÁSTICA REPARADORA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA ROBÓTICA",
            alt: "HSC - Especialidade - CIRURGIA ROBÓTICA",
            img: "imagemTeste",
            pompeia: true,
            santana: false,
            ipiranga: false,
            
        },
        {
            nome: "CIRURGIA TORÁCICA",
            alt: "HSC - Especialidade - CIRURGIA TORÁCICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA VASCULAR",
            alt: "HSC - Especialidade - CIRURGIA VASCULAR",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CIRURGIA VASCULAR CATETER",
            alt: "HSC - Especialidade - CIRURGIA VASCULAR CATETER",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CLÍNICA DA DOR",
            alt: "HSC - Especialidade - CLÍNICA DA DOR",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "CLINICA MÉDICA",
            alt: "HSC - Especialidade - CLINICA MÉDICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "COLOPROCTOLOGIA",
            alt: "HSC - Especialidade - COLOPROCTOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "DERMATOLOGIA",
            alt: "HSC - Especialidade - DERMATOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ENDOCRINOLOGIA",
            alt: "HSC - Especialidade - ENDOCRINOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ENDOCRINOLOGIA PEDIÁTRICA ",
            alt: "HSC - Especialidade - ENDOCRINOLOGIA PEDIÁTRICA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "FISIATRIA",
            alt: "HSC - Especialidade - FISIATRIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "FISIOTERAPIA",
            alt: "HSC - Especialidade - FISIOTERAPIA",
            img: "imagemTeste",
            pompeia: false,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "GASTROENTEROLOGIA",
            alt: "HSC - Especialidade - GASTROENTEROLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "GASTROENTEROLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - GASTROENTEROLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "GERIATRIA ",
            alt: "HSC - Especialidade - GERIATRIA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "GINECOLOGIA ",
            alt: "HSC - Especialidade - GINECOLOGIA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "HEMATOLOGIA",
            alt: "HSC - Especialidade - HEMATOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "HEMATOLOGIA PEDIÁTRICA ",
            alt: "HSC - Especialidade - HEMATOLOGIA PEDIÁTRICA ",
            img: "imagemTeste",
            pompeia: true,
            santana: false,
            ipiranga: false,
            
        },
        {
            nome: "HEPATOLOGIA",
            alt: "HSC - Especialidade - HEPATOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "INFECTOLOGIA",
            alt: "HSC - Especialidade - INFECTOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "INFECTOLOGIA PEDIÁTRICA ",
            alt: "HSC - Especialidade - INFECTOLOGIA PEDIÁTRICA ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "MASTOLOGIA",
            alt: "HSC - Especialidade - MASTOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NEFROLOGIA",
            alt: "HSC - Especialidade - NEFROLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NEFROLOGIA PEDIÁTRICA  ",
            alt: "HSC - Especialidade - NEFROLOGIA PEDIÁTRICA  ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "NEUROCIRURGIA",
            alt: "HSC - Especialidade - NEUROCIRURGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NEUROLOGIA",
            alt: "HSC - Especialidade - NEUROLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NEUROLOGIA PEDIÁTRICA  ",
            alt: "HSC - Especialidade - NEUROLOGIA PEDIÁTRICA  ",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "NUTRICIONISTA BARIÁTRICA",
            alt: "HSC - Especialidade - NUTRICIONISTA BARIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NUTROLOGIA ADULTO",
            alt: "HSC - Especialidade - NUTROLOGIA ADULTO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "NUTROLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - NUTROLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: false,
            ipiranga: false,
            
        },
        {
            nome: "OFTALMOLOGIA",
            alt: "HSC - Especialidade - OFTALMOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ONCOLOGIA",
            alt: "HSC - Especialidade - ONCOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "ONCOLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - ONCOLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "ORTOPEDIA/TRAUMATOLOGIA",
            alt: "HSC - Especialidade - ORTOPEDIA/TRAUMATOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "OTORRINOLARINGOLOGIA",
            alt: "HSC - Especialidade - OTORRINOLARINGOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "PEDIATRIA",
            alt: "HSC - Especialidade - PEDIATRIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "PNEUMOLOGIA ADULTO",
            alt: "HSC - Especialidade - PNEUMOLOGIA ADULTO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "PNEUMOLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - PNEUMOLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "PSICOLOGIA ADULTO (BARIÁTRICA)",
            alt: "HSC - Especialidade - PSICOLOGIA ADULTO (BARIÁTRICA)",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "PSIQUIATRIA",
            alt: "HSC - Especialidade - PSIQUIATRIA",
            img: "imagemTeste",
            pompeia: true,
            santana: false,
            ipiranga: false,
            
        },
        {
            nome: "RADIOLOGIA INTERVENCIONISTA NÃO VASCULAR",
            alt: "HSC - Especialidade - RADIOLOGIA INTERVENCIONISTA NÃO VASCULAR",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "RADIOLOGIA INTERVENCIONISTA VASCULAR",
            alt: "HSC - Especialidade - RADIOLOGIA INTERVENCIONISTA VASCULAR",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "RADIOTERAPIA",
            alt: "HSC - Especialidade - RADIOTERAPIA",
            img: "imagemTeste",
            pompeia: true,
            santana: false,
            ipiranga: false,
            
        },
        {
            nome: "REUMATOLOGIA",
            alt: "HSC - Especialidade - REUMATOLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "TMO - TRANSPLANTE DE MEDULA ÓSSEA",
            alt: "HSC - Especialidade - TMO",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        },
        {
            nome: "UROLOGIA",
            alt: "HSC - Especialidade - UROLOGIA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: true,
            
        },
        {
            nome: "UROLOGIA PEDIÁTRICA",
            alt: "HSC - Especialidade - UROLOGIA PEDIÁTRICA",
            img: "imagemTeste",
            pompeia: true,
            santana: true,
            ipiranga: false,
            
        }
    ]
    
export default especialidades