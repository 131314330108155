import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga';

import { ga } from './helpers/config';

ReactGA.initialize(ga, { debug: false })
ReactGA.pageview(window.location.pathname + window.location.search)

  ReactDOM.render(
    <>
      <App />
    </>,
  document.getElementById('root')
);