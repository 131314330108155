import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// import FormLP from '../../components/FormLP/FormLP.component.jsx';

// import logoHSCCinza from './images/logoHSC-cinza.png';
// import logoHSC from '../../images/logos/logodefault.png';
// import DownloadIcon from './images/download-icon.png'
import TextMainBanner from '../../../images/images/conteudos-especiais/abril-azul/banner-main-text-img.png'
import TextSubtitleBanner from '../../../images/images/conteudos-especiais/abril-azul/banner-subtitle-img.png'
import MensagemBanner from './images/Mensagem-banner.png'
import Dados from '../../../images/images/conteudos-especiais/abril-azul/dados-abril-azul.png'
import Crianca from '../../../images/images/conteudos-especiais/abril-azul/crianca-aprendendo.png'

import './AbrilAzul.style.scss';
// import { SocialMOCK } from './LPData.js';

const IframeAbrilAzul = () => {
    const is1300 = useMediaQuery({ query: '(max-width: 1300px)' });

    const location = useLocation();

    const downloadPage = () => {
        window.print()
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <div className='header-abr'>         
                {is1300 ? (
                    <div className='banner-content-abr'>
                        <div className='banner-text-main-abr'>
                            <img src={TextMainBanner} alt="" />

                            <img src={TextSubtitleBanner} alt="" />
                        </div>
                        <img className='banner-solo-msg' src={MensagemBanner} alt="" />
                    </div>
                ) : (
                    <div className='banner-content-abr-solo'>
                        <img src={MensagemBanner} alt="" />
                    </div>
                )}               
            </div>

            <div className='dados-abr'>
                <img src={Dados} alt="Dados" className='img-dados' />

                <img src={Crianca} alt="Crianca" className='img-crianca' />
            </div>

            <div className='texto-explicativo'>
                <div className='textura-abr'>
                        <p className='textura-texto-abr'>
                        <strong>Durante o mês de abril, a Rede de Hospitais São Camilo de São Paulo adere à campanha <strong style={{color: '#315DA8', fontWeight: '900'}}>Abril Azul de conscientização do autismo</strong> (TEA – Transtorno do Espectro Autista), objetivo é dar visibilidade ao tema e compartilhar informações. </strong>

                        <br /><br />
                        O autismo é definido, segundo a OMS, como uma série de condições caracterizadas por algum grau de comprometimento no comportamento social, na comunicação e na linguagem, assim como interesses e atividades restritos que são únicos para o indivíduo e realizados de forma repetitiva. 
                        </p>
                </div>

                <div className='mensagem-destaque-abr'>
                Confira os sinais mais comuns, o tratamento disponível e como lidar com o diagnóstico.  Mês de conscientização do autismo: diminuir o preconceito para aumentar as oportunidades! 
                </div>
            </div>

            <div className='risk-title-container-abr'>
                <h2 className='risk-title'>Sinais mais comuns:</h2>
                <hr className='hr-abr-risk'/>

                <div className='risk-conteudo'>
                    <div className='risk-bullets-main-abr'>
                        <ul>
                            <li>• Não manter contato visual por mais de 2 segundos;</li> 
                            <li>• Não atender quando chamado pelo nome;</li> 
                            <li>• Isolar-se ou não se interessar por outras crianças;</li> 
                            <li>• Alinhar objetos;</li> 
                            <li>• Ser muito preso a rotinas a ponto de entrar em crise;</li> 
                            <li>• Não brincar com brinquedos de forma convencional;</li> 
                            <li>• Fazer movimentos repetitivos sem função aparente;</li> 
                            <li>• Não falar ou não fazer gestos para mostrar algo;</li> 
                        </ul>
                    </div>

                    <div className='risk-bullets-main-abr'>
                        <ul>
                            <li>• Repetir frases ou palavras em momentos inadequados, sem a devida função (ecolalia);</li> 
                            <li>• Não compartilhar seus interesses e atenção, apontando para algo ou não olhar quando apontamos algo;</li> 
                            <li>• Girar objetos sem uma função aparente;</li> 
                            <li>• Interesse restrito por um único assunto (hiperfoco);</li> 
                            <li>• Não imitar;</li> 
                            <li>• Não brincar de faz-de-conta;</li> 
                            <li>• Hipersensibilidade ou hiper-reatividade sensorial.</li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div className='treatment-main-abr'>
                <h2 className='treatment-title-abr'>Tratamento</h2>

                <hr style={{alignSelf: 'center', marginBottom: '20px'}} className='hr-abr-risk'/>

                <p style={{textAlign: 'center', fontSize: '24px'}}>Até agora, não há exames de imagem ou laboratoriais que sejam definitivos para diagnosticar o TEA. O diagnóstico é clínico, feito por um médico. Não existe um padrão de tratamento, cada paciente exige avaliação individualizada e acompanhamento multidisciplinar, além do apoio e acolhimento de familiares, amigos e da comunidade que frequenta. O uso de medicamentos só é indicado quando surgem complicações e comorbidades. </p>
            </div>

            <div className='recomendations-title-container-abr'>
                <h2 className='recomendations-title'>Recomendações para lidar com o autismo </h2>
                <hr className='hr-abr-recomendations'/>

                <div className='recomendations-bullets-main'>
                    <div>
                        <ul>
                            <li>• Ter em casa uma pessoa com formas graves de autismo pode representar um fator de desequilíbrio para toda a família. <strong>Por isso, todos os envolvidos precisam de atendimento e orientação especializados;</strong></li>  
                            <li>• É fundamental descobrir um meio ou técnica que possibilitem estabelecer <strong>algum tipo de comunicação com o autista;</strong></li>
                        </ul>
                    </div>
                   
                    <div>
                        <ul>
                            <li>• Autistas têm dificuldade de lidar com mudanças, por menores que sejam, <strong>por isso é importante manter o seu mundo organizado e dentro da rotina;</strong></li> 
                            <li>• Autistas de bom rendimento podem apresentar desempenho em determinadas áreas do conhecimento com características de genialidade.</li> 
                        </ul>
                    </div>
                </div>
            </div>

            
            <div className='agende-main-abr'>
                <p className='agende-text-abr'>Na Rede de Hospitais São Camilo temos profissionais especializados para diagnosticar, tratar e acompanhar sua saúde, afinal,<strong> nossa missão é cuidar da sua vida e valorizá-la.</strong></p>

                <a className='agende-button-abr' href='https://centralpaciente.hospitalsaocamilosp.org.br/acesso' target='_blank' rel='noreferrer'>Agende seu horário com o pediatra</a>
            </div>

            {/* <div className='form-wrapper'>
                <div className='form'>
                    <h3 className='form-title'>Quero receber informações e conteúdos do Hospital São Camilo</h3>

                    <div>
                        <FormLP />
                    </div> 
                </div>
            </div> */}
            

            {/* <div className='footer'>
                <a href="/" >
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: 'center', alignItems: 'center'}}>
                    <p style={{color: "#606060", fontSize: "16px"}}>Diretor Médico: Dr. Fernando Pompeu - CRM 125543</p>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>
                </div>


                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </div> */}
        </main>
    )
}

export default IframeAbrilAzul;