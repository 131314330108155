import React, { useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import config from '../../helpers/config';

import './Login.style.scss';
import logo from '../../images/logos/logodefault.png'

const theme = createMuiTheme({
	palette: {
		primary: grey,
		secondary: red
	},
});

const Login = () => {
    let history = useHistory();
    const [ email, setEmail ] = useState();
    const [ password, setPassword ] = useState();
    const [ error, setError ] = useState(false);
    const [ showLoading, setShowLoading ] = useState(false);

    const handleLogin = () => {
        setShowLoading(true);

        fetch(`${config.host}/auth`, {
            method: 'post',
            headers: {
                hsc_key: "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(res => {
            console.log(res.status)
            if(res.status !== 200) {
                setShowLoading(false);
                setError(true);
                return
            } else {
                res.json()
                    .then(res => {
                        console.log(res)
                        localStorage.setItem("userInfo", JSON.stringify(res));
                        setShowLoading(false);
                        history.push('/admin/lista-novidades');
                    })
                    .catch(err => {
                        setShowLoading(false);
                        console.log(err);
                        setError(true);
                    })
            }
        })
        
        
    }

    return (
        <main className="login-page-container">
            <div className="login-container">
                <ThemeProvider theme={theme}>
                    <img id="login-logotipo" src={logo} alt="Logotipo HSC" />
                    <h2 className="h2-gray-center">Login</h2>
                    <TextField 
                        label="E-mail"
                        placeholder="Insira seu E-mail"
                        type="email"
                        error={error}
                        onChange={
                            e => {
                                setError(false);
                                setEmail(e.target.value)
                            }    
                        }
                        onKeyPress={e => e.key === 'Enter' ? handleLogin() : ''}
                        helperText=""
                    />
                    <TextField 
                        label="Senha"
                        placeholder="Insira sua senha"
                        type="password"
                        error={error}
                        onChange={e => {
                            setError(false);
                            setPassword(e.target.value)
                            }
                        }
                        onKeyPress={e => e.key === 'Enter' ? handleLogin() : ''}
                        helperText={error ? `Credenciais incorretas` : ''}
                    />
                    <input 
                        id="submit-form" 
                        type="submit" 
                        value="Acessar"
                        onClick={() => handleLogin()}
                    />
                    {
                        showLoading ?
                            <CircularProgress color="primary" />
                        : ''
                    }
                </ThemeProvider>
            </div>
        </main>
    )
}

export default Login;