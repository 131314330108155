import React, { useState } from 'react';
import { useHistory } from 'react-router';
import SearchIcon from '@material-ui/icons/Search';

import './Search.style.scss'

const Search = () => {
	let history = useHistory();
	const [ textToSearch, setTextToSearch ] = useState('');

	const handleChange = event => setTextToSearch(event.target.value);
	const handleClick = () => { if(textToSearch.length > 0) history.push(`/pesquisa/${textToSearch}`) };
	const handleKeypress = e => {
		//it triggers by pressing the enter key
	  if (e.charCode === 13) {
		handleClick();
	  }
	};

	return (
		<div id="search-input-container">
			<input 
				type="text" 
				value={textToSearch} 
				onChange={handleChange}
				onKeyPress={handleKeypress}
				placeholder="Pesquise aqui" 
				id="search-input-home" 
				maxLength="255"
			/>
			<SearchIcon 
				id="search-icon-home" 
				onClick={handleClick}/>
		</div>
	)
}

export default Search