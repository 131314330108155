import React, { useLayoutEffect } from 'react';
import {  Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import parse from "html-react-parser";

import { useHistory,  useLocation } from 'react-router-dom';

import './Conveniencias.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import BannerConveniencias from "../../images/images/unidades/banner-conveniencias.jpg";
import IconTime from "../../images/images/unidades/Horario.png";
import IconLocation from "../../images/images/unidades/Localização.png";
import conveniencias from './convenienciasData';

const Conveniencias = () => {
    let history = useHistory();
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        history.push(path);
    }
    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="conveniencias-container">
                <div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <a color="inherit" href="/conveniencias" onClick={() => handleClick("/conveniencias")} className="breadcrumb-link" >
                                Conveniências
                            </a>
                        </Breadcrumbs>
                        <TituloPrincipal titulo="Conveniências" />
                    </div>
                </div>
                <div className='content-container'>
                    <img className='conveniencias-banner' src={BannerConveniencias} alt="" />

                    <p>Pensando em tornar a experiência do paciente o mais agradável possível, nossas Unidades contam com estrutura de alimentação e estacionamento.</p>

                    <p>Confira logo abaixo quais são os serviços oferecidos por unidade, além de informações de atendimento:</p>
                    {conveniencias.map(item => (
                        <>
                            <div className='unidade-title'>
                                <h4>Unidade {item.unidade}</h4>
                            </div>

                            <div className='conveniencia-item-container'>
                            {item.conveniencias.map(conveniencia => (
                                <div className='conveniencia-item'>
                                    <img className='conveniencia-item-img' src={conveniencia.image} alt="" />

                                    <h5 className='conveniencia-item-title'>{conveniencia.title.toUpperCase()}</h5>

                                    <p>{conveniencia.description}</p>

                                    <div className='conveniencia-item-location'>
                                        <img className='conveniencia-item-location-img' src={IconLocation} alt="" />
                                        <p><strong>Localização: </strong> {conveniencia.local}</p>
                                    </div>

                                    <div className='conveniencia-item-funcionamento'>
                                        <img className='conveniencia-item-funcionamento-img' src={IconTime} alt="" />
                                        <p><strong>Funcionamento: </strong> {parse(conveniencia.funcionamento)}</p>
                                    </div>

                                    {conveniencia.additionalInfo ? (
                                        <p>{conveniencia.additionalInfo}</p>
                                    ) : (
                                        null
                                    )}
                                </div>
                                    
                                    ))}
                                    </div>
                        </>
                    ))}
                    

                    <div className='conveniencias-container'>

                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Conveniencias