import linkedin from './images/linkedin.png'
import facebook from './images/facebook.png'
import instagram from './images/instagram.png'
import spotify from './images/spotify.png'
import youtube from './images/youtube.png'

export const SocialMOCK = [
    {
        id: '1',
        name: 'Instagram',
        href: 'https://www.instagram.com/hospitalsaocamilosp/?hl=pt-br',
        Image: instagram
    },
    {
        id: '2',
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/hospitalsaocamilosp/?originalSubdomain=br',
        Image: linkedin
    },
    {
        id: '3',
        name: 'Youtube',
        href: 'https://www.youtube.com/user/RedeSaoCamiloSP',
        Image: youtube
    },
    {
        id: '4',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/4Ov84RYyq1XTHqxH8soaPj?si=uoyQg3YTR-m8tezKnZxexg',
        Image: spotify
    },
    {
        id: '5',
        name: 'Facebook',
        href: 'https://www.facebook.com/hospitalsaocamilosp/',
        Image: facebook
    },
]