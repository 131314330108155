import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Breadcrumbs, CircularProgress, 
    TextField, Button, FormControl,
    Select, InputLabel, MenuItem } from '@material-ui/core';
import { grey, red, green } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

import config from '../../helpers/config';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import MyUploadAdapter from '../../components/CKEditor/MyCustomUploader.component';

import doGet from '../../helpers/request';

import './GestaoNovidade.style.scss';

const novidadeExample = {
    // id: "",
    novidade_type: "",
    image: "",
    publish_date: "",
    link: "",
    author: "",
    category: null,
    title: "",
    title_text: "",
    original_text: "",
    createdAt: "",
    updatedAt: ""
};

const theme = createMuiTheme({
	palette: {
		primary: grey,
		secondary: red
    },
});

const buttonTheme = createMuiTheme({
	palette: {
		primary: green,
		secondary: red
    },
});

const GestaoNovidade = () => {
    const nowDate = new Date();
    const user = JSON.parse(localStorage.getItem("userInfo"))
    let history = useHistory();
    const { id } = useParams();
    const [ createNovidade, setCreateNovidade ] = useState(true);
    const [ novidade, setNovidade ] = useState(novidadeExample);
    const [ stateLoaded, setStateLoaded ] = useState(false);
    const [ imageUploaded, setImageUploaded ] = useState(true);
    const [ uploadAttemptOcurred, setUploadAttemptOcurred ] = useState(false);
    const [ publicImageUploaded, setPublicImageUploaded ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ openTipo, setOpenTipo ] = useState(false);
    const [ tipoSelected, setTipoSelected ] = useState(false);
    const [ unableToUpload, setUnableToUpload ] = useState(true);
    const [ errDate, setErrDate ] = useState(false);
    const [ errDisplayOrder, setErrDisplayOrder ] = useState(false);
    const [ defaultLink, setDefaultLink ] = useState("");
    const [ showLoadingSend, setShowLoadingSend ] = useState(false);
    // const [ openCategoria, setOpenCategoria ] = useState(false);

    const removeSpecialChars = (str) => {
        return str
                .replace(/\s/g, "-")
                .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                .replace(/@/g, "")
                .replace(/´/g, "")
                .replace(/`/g, "")
                .replace(/[ÀÁÂÃÄÅ]/g, "A")
                .replace(/[àáâãäå]/g, "a")
                .replace(/[ÈÉÊË]/g, "E")
                .replace(/[èéêë]/g, "e")
                .replace(/[ÌÍÏÎ]/g, "I")
                .replace(/[ìíïî]/g, "i")
                .replace(/[ÒÓÕÔÖ]/g, "O")
                .replace(/[òóõôö]/g, "o")
                .replace(/[ÙÚÜÛ]/g, "U")
                .replace(/[ùúüû]/g, "u")
                .replace(/[ç]/g, "c")
    }

    useEffect(() => {
        if(createNovidade) {
            setNovidade({
                ...novidade,
                publish_date: `${nowDate.getFullYear()}-${(nowDate.getMonth() + 1).toString().padStart(2, '0')}-${nowDate.getDate().toString().padStart(2, '0')}`
            })
        }
    }, [novidade.publish_date])

    useEffect(() => {
        if(!user || !user.admin) {
            history.push('/admin/login')
        } else {
            fetch(`${config.host}/auth/verify`, {
                method: 'post',
                headers: {
                    "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                    "authorization": user.token
                }
            })
            .then(res => {
                if(res.status !== 200) {
                    history.push('/admin/login')
                }
            })
        }
    }, [])

    useEffect(() => {
        if(novidade.display_home_order > 15) {
            setErrDisplayOrder(true)
        } else {
            setErrDisplayOrder(false)
        }
    }, [novidade])

    useEffect(() => {
        if(tipoSelected === "blog") {
            setDefaultLink(`/blog/${removeSpecialChars(novidade.title_text.toLowerCase())}`)
        } else if(tipoSelected === "imprensa") {
            setDefaultLink(`/imprensa/${removeSpecialChars(novidade.title_text.toLowerCase())}`)
        } else if(tipoSelected === "banner-redirect") {
            setDefaultLink("")
        }
    }, [novidade.title_text, tipoSelected, createNovidade])

    useEffect(() => {
        const getNovidadeInfo = (id) => {
            doGet(`${config.host}/novidades/?id=${id}`)
                .then(res => {
                    setNovidade(res);
                    setTipoSelected(res.novidade_type);
                    setStateLoaded(true);
                })
                .catch(err => {
                    console.log(err)
                    setError(true);
                    setStateLoaded(true);
                })
        }

        if(id) {
            getNovidadeInfo(id);
            setCreateNovidade(false);
        } else {
            setStateLoaded(true);
        }

    }, [id])

    useEffect(() => {
        if(createNovidade) {
            if(tipoSelected === "banner") {
                if(publicImageUploaded &&
                    publicImageUploaded !== "false" &&
                    novidade.title_text !== "" &&
                    tipoSelected &&
                    // novidade.category &&
                    !errDisplayOrder) {
                        setDefaultLink("")
                        setUnableToUpload(false)
                    }
            } else if(tipoSelected === "banner-redirect"){
                if(publicImageUploaded &&
                    publicImageUploaded !== "false" &&
                    novidade.title_text !== "" &&
                    tipoSelected &&
                    novidade.link &&
                    // novidade.category &&
                    !errDisplayOrder) {
                        setUnableToUpload(false)
                    }
            } else {
                if(publicImageUploaded &&
                    publicImageUploaded !== "false" &&
                    tipoSelected &&
                    novidade.title_text !== "" &&
                    // novidade.category &&
                    !errDisplayOrder) {
                        setUnableToUpload(false)
                    }
            }
        } else {
            setUnableToUpload(false)
        }
            
    }, [createNovidade, novidade, publicImageUploaded, tipoSelected, errDisplayOrder])

    const inputChanges = e => {
        setNovidade({
            ...novidade,
            [e.target.name]: e.target.value
        })
    }

    const handleUpload = e => {
        if(e.target.files[0].size / 1024 / 1024 > 5) {
            alert('Tamanho do arquivo excede o limite de 5MB')
        } else {
            let data = new FormData();
            Array.from(e.target.files).forEach((el, index) => {
                data.append(`imagem${index}`, el);
            })
    
            setImageUploaded(false);
            
            fetch(`https://hospitalsaocamilosp.org.br/api/upload`, {
                method: "POST",
                headers: {
                    "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                    "authorization": user.token,
                },
                body: data
            })
            .then(res => {
                console.log(res.status)
                if(res.status === 201) {
                    setImageUploaded(true);
                    setUploadAttemptOcurred(true);
                } else if(res.status === 401) {
                    history.push('/admin/login')
                } else {
                    setImageUploaded(true);
                    setPublicImageUploaded("false");
                    setUploadAttemptOcurred(true);
                }
                res.json()
                    .then(res => {
                        setPublicImageUploaded(res.url);
                    })
                    .catch(err => {
                        console.log(err)
                        setImageUploaded(true);
                        setUploadAttemptOcurred(true);
                    })
            })
        }
    }

    const handleSubmit = () => {
        setShowLoadingSend(true);

        if(createNovidade) {
            fetch(`${config.host}/novidades/`, {
                method: 'post',
                headers: {
                    "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                    "authorization": user.token,
                    "content-type": 'application/json'
                },
                body: JSON.stringify({
                    ...novidade,
                    novidade_type: tipoSelected,
                    image: publicImageUploaded,
                    title: tipoSelected !== "banner" && tipoSelected !== "banner-redirect" ? removeSpecialChars(novidade.title_text.toLowerCase()) : null,
                    author: user.nome,
                    author_depto: user.departamento, 
                    active: true,
                    display_home_order: novidade.display_home_order ? novidade.display_home_order : null,
                    link: tipoSelected !== "banner-redirect" ? defaultLink : novidade.link,
                    publish_date: novidade.publish_date.split("-").reverse().join("/")
                })
            })
            .then(res => {
                setShowLoadingSend(false);

                if(res.status === 201) {
                    history.push('/admin/lista-novidades');
                } else if(res.status === 403 || res.status === 401) {
                    alert("Acesso restrito. Por favor, acesse suas credenciais.")
                    history.push('/admin/login');
                } else {
                    alert("Ocorreu um erro ao se comunicar com o servidor. Revise e tente novamente.")
                }
            })
        } else {
            fetch(`${config.host}/novidades/${id}`, {
                method: 'put',
                headers: {
                    "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                    "authorization": user.token,
                    "content-type": 'application/json'
                },
                body: JSON.stringify({
                    ...novidade,
                    novidade_type: tipoSelected,
                    image: publicImageUploaded ? publicImageUploaded : novidade.image,
                    title: tipoSelected !== "banner" && tipoSelected !== "banner-redirect" ? removeSpecialChars(novidade.title_text.toLowerCase()) : null,
                    author: user.nome,
                    author_depto: user.departamento, 
                    active: true,
                    display_home_order: novidade.display_home_order ? novidade.display_home_order : null,
                    link: tipoSelected === "banner-redirect" ? novidade.link : defaultLink,
                    publish_date: novidade.publish_date ? 
                                    novidade.publish_date.includes("-") ? 
                                        novidade.publish_date.split("-").reverse().join("/") 
                                        : 
                                        novidade.publish_date
                                    :
                                    null
                })
            })
            .then(res => {
                setShowLoadingSend(false);

                if(res.status === 200) {
                    history.push('/admin/lista-novidades');
                } else if(res.status === 403 || res.status === 401) {
                    history.push('/admin/login');
                } else {
                    alert("Ocorreu um erro ao se comunicar com o servidor. Revise e tente novamente.")
                }
            })
        }
    }

    const handleClick = path => history.push(path)

    return (
        <main>
            <Header />
            <section className="gestao-publicacao-container">
                <div className="div-gestao-publicacao-container-bg">
                    <div className="div-gestao-publicacao-container">
                        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <div color="inherit" className="breadcrumb-link">
                                <a  className="breadcrumb-anchor" href="/admin/lista-novidades" onClick={() => handleClick("/lista-novidades")}>Publicações</a>
                            </div>
                            <div color="inherit" className="breadcrumb-link">
                                Gestão de Publicação
                            </div>
                        </Breadcrumbs>
                        <TituloPrincipal titulo={createNovidade ? "Criação de Publicação" : "Edição de Publicação"} />
                        {
                            stateLoaded ?
                                error ? 
                                    'Ocorreu um erro'  
                                : 
                                <>
                                <div className="div-form-publicacao">
                                    <ThemeProvider theme={theme}>
                                    <div className="div-form-publicacao-fields">
                                        <TextField 
                                            label="Título"
                                            name="title_text"
                                            fullWidth
                                            defaultValue={novidade.title_text ? novidade.title_text : ''}
                                            onChange={inputChanges}
                                        />
                                        <FormControl>
                                            <InputLabel required id="demo-controlled-open-select-label">
                                                Tipo
                                            </InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                open={openTipo}
                                                onClose={() => setOpenTipo(false)}
                                                onOpen={() => setOpenTipo(true)}
                                                value={tipoSelected}
                                                onChange={e => setTipoSelected(e.target.value)}
                                                className="smallFields"
                                            >
                                                <MenuItem value="banner">banner</MenuItem>
                                                <MenuItem value="banner-redirect">banner-redirect</MenuItem>
                                                <MenuItem value="blog">blog</MenuItem>
                                                <MenuItem value="imprensa">imprensa</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField 
                                            label="Data de Publicação"
                                            name="publish_date"
                                            className="smallFields"
                                            type="date"
                                            disabled={tipoSelected === "banner" || tipoSelected === "banner-redirect"}
                                            error={errDate}
                                            helperText={errDate ? 'Insira a data no padrão DD/MM/AAAA' : ''}
                                            defaultValue={novidade.publish_date?.split("/").reverse().join("-")}
                                            onChange={inputChanges}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        {
                                            createNovidade ?
                                                tipoSelected === "banner-redirect" ?
                                                    <TextField 
                                                        required={tipoSelected === "banner-redirect"}
                                                        label="Link"
                                                        name="link"
                                                        fullWidth
                                                        value={novidade.link ? novidade.link : defaultLink}
                                                        onChange={inputChanges}
                                                    />
                                                    :
                                                    <TextField 
                                                        required={novidade.novidade_type !== "banner" || tipoSelected !== "banner"}
                                                        disabled={tipoSelected !== "banner-redirect"}
                                                        label="Link"
                                                        name="link"
                                                        fullWidth
                                                        value={defaultLink}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                :
                                                <TextField 
                                                    required={novidade.novidade_type !== "banner" || tipoSelected !== "banner"}
                                                    disabled={tipoSelected !== "banner-redirect"}
                                                    label="Link"
                                                    name="link"
                                                    fullWidth
                                                    value={defaultLink}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />

                                        }
                                        <TextField 
                                            label="Ordem de Exibição"
                                            name="display_home_order"
                                            className="smallFields"
                                            error={errDisplayOrder}
                                            helperText={errDisplayOrder ? "Limite de 15 itens" : ''}
                                            defaultValue={novidade.display_home_order ? novidade.display_home_order : ''}
                                            onChange={inputChanges}
                                        />
                                        <TextField 
                                            required
                                            label="Autor"
                                            name="author"
                                            className="smallFields"
                                            disabled
                                            defaultValue={createNovidade ? user.nome : novidade.author}
                                            onChange={inputChanges}
                                        />
                                        <TextField 
                                            fullWidth
                                            required
                                            label="Departamento"
                                            name="author_depto"
                                            disabled
                                            defaultValue={createNovidade ? user.departamento : novidade.author_depto}
                                            onChange={inputChanges}
                                        />
                                        {/* <FormControl className="select-dropdown" fullWidth>
                                            <InputLabel required id="demo-controlled-open-select-label" >Categoria</InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                open={openCategoria}
                                                onClose={() => setOpenCategoria(false)}
                                                onOpen={() => setOpenCategoria(true)}
                                                value={novidade.category}
                                                onChange={event => setNovidade({...novidade, category: event.target.value})}
                                            >
                                                <MenuItem value="Imprensa">Imprensa</MenuItem>
                                                <MenuItem value="Blog">Blog</MenuItem>
                                            </Select>
                                        </FormControl>*/}
                                    </div>
                                    <div className="div-form-publicacao-import-image">
                                        {
                                            publicImageUploaded !== false && 
                                            publicImageUploaded !== "false" ? 
                                                <img className="image-preview-carousel" src={publicImageUploaded} alt={publicImageUploaded} />
                                            :
                                                createNovidade ? 
                                                    ''
                                                :
                                                    <img className="image-preview-carousel" src={novidade.image} alt={novidade.title_text} />
                                        }
                                        <div className="form-publicacao-column-images">
                                            <Button
                                                className="div-form-button"
                                                variant="contained"
                                                // disabled={!createNovidade && publicImageUploaded !== false && publicImageUploaded !== "false"}
                                                component="label">
                                                    Selecione a Imagem
                                                <input type="file" onChange={handleUpload} hidden accept=".png,.svg,.jpg,.jpeg,.gif,.mp4,.webp"/>
                                            </Button>
                                            {
                                                !imageUploaded ?
                                                    <CircularProgress />
                                                : 
                                                    publicImageUploaded === "false"?
                                                        <HighlightOffIcon />
                                                    :
                                                        uploadAttemptOcurred ? 
                                                            <CheckSharpIcon />
                                                        :
                                                            ''
                                            }
                                        </div>
                                    </div>
                                    </ThemeProvider>
                                </div>
                                {novidade.novidade_type === "banner" || tipoSelected === "banner" || novidade.novidade_type === "banner-redirect" || tipoSelected === "banner-redirect" ?
                                    ''
                                    :
                                    <CKEditor
                                        className="ck-editor-container"
                                        editor={ ClassicEditor }
                                        data={novidade.original_text ? novidade.original_text : ''}
                                        config= {{
                                            mediaEmbed: {previewsInData: true}
                                        }}
                                        onReady={ editor => {
                                            editor.plugins.get("FileRepository").createUploadAdapter = function(loader) {
                                                return new MyUploadAdapter(loader);
                                            };
                                        }}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData()
                                            setNovidade({ ...novidade, original_text: data })
                                        }}
                                    />
                                }
                                <ThemeProvider theme={buttonTheme}>
                                    <Button
                                        className="div-form-button"
                                        id="submit-form-button"
                                        variant="contained"
                                        color="primary"
                                        disabled={unableToUpload}
                                        onClick={handleSubmit}
                                        component="label">
                                            Enviar
                                    </Button>
                                    {
                                        showLoadingSend ?
                                            <CircularProgress />
                                        : ''
                                    }
                                </ThemeProvider>
                                </>
                            :
                            <ThemeProvider theme={theme}>
                                <CircularProgress />
                            </ThemeProvider>
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default GestaoNovidade;