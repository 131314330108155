import React, { useState, useEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';
import BannerEventos from '../../images/images/banner/banner-eventos.webp'


import './Eventos.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

const axios = require('axios');

const Eventos = () => {
  let history = useHistory();
  const handleClick = path => history.push(path);

  const [activeEvents, setActiveEvents] = useState([]);
  const [eventsFiltered, setEventsFiltered] = useState([]);

  // const [pastEvents, setPastEvents] = useState([]);
  // const [pastEventsFiltered, setPastEventsFiltered] = useState([]);
  // const [pastEventsDates, setPastEventsDates] = useState([])

  const [categories, setCategories] = useState([])

  async function getEventos() {
    try {
      const response = await axios.get('https://hospitalsaocamilosp.org.br/api/events');  

      console.log(response.data.data);

      if (response.status === 200) {
        setActiveEvents(response.data.data.filter(event => checkAtiveByDate(event.end_date) && !(event.name.toLowerCase().includes('teste')))); 

        setCategories(removeDuplicates(response.data.data.filter(event => checkAtiveByDate(event.end_date)).map(cat => cat.category_prim.name)));
        // setPastEvents(response.data.filter(event => event.published === 0));
      } else {
        // Handle non-success status codes if needed.
        console.error('Ops, tivemos o seguinte erro:', response.status);
      }
    } catch (error) {
      console.error('Erro ao buscar eventos:', error);
      // Handle the error, e.g., show an error message to the user.
    }
  } 

  const filterEvents = (data) => {
    const filteredEvents = activeEvents.filter((event) => event.category_prim.name === data && event.published === 1);

    data === 'todas' ? setEventsFiltered([]) : setEventsFiltered(filteredEvents);
  }

  // const filterPastEvents = (data) => {
  //   const filteredEvents = pastEvents.filter((event) => formatDate(event.start_date) === data);

  //   data === 'todas' ? setPastEventsFiltered([]) : setPastEventsFiltered(filteredEvents);
  // }

  function formatDate(dateString, dateHour) {
    const [date, time] = dateString.split(' ');

    const [DD, MM, YYYY] = date.split('-');    

    const [HH, mm ] = time.split(':');

    return dateHour ? `${YYYY}/${MM}/${DD} às ${HH}:${mm}` : `${MM}/${DD}`;
  }
  
  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  function checkAtiveByDate(dateString) {
    // Converte a string em um objeto Date
    const dataFornecida = new Date(dateString);
  
    // Obtém a data de hoje
    const dataDeHoje = new Date();

    // Compara as datas
    return dataFornecida > dataDeHoje;
  }
  
  useEffect(() => {
    getEventos(); 
    // setPastEventsDates(removeDuplicates(pastEvents.map(a => formatDate(a.start_date))));
  }, []); 

  return (
   <>
    <Header />

    <MenuRapido />
   
    <div className='main-wrapper'>
      <section className='main-title'>
        <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-medicos">			      
          <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">				      
            <HomeIcon id="breadcrumb-home-icon" />			      
          </a>
          <a
            color="inherit"
            href="/eventos"
            onClick={() => handleClick("/eventos")}
            className="breadcrumb-link"
          >
            Eventos e Reuniões Científicas
          </a>
        </Breadcrumbs>

        <TituloPrincipal titulo={"Eventos e Reuniões Científicas"} />
      </section>

      <section className='main-description'>
        <img src={BannerEventos} alt="banner" className='banner-events' />

        <h2 className='events-title'>Eventos e Reuniões Científicas</h2>

        <p className='description-item'>
        Os Eventos e Cursos são atividades de ensino e aprendizagem que têm como objetivo proporcionar atualização, integração e troca de experiências entre diferentes profissionais e áreas.
          <br /><br />
          A Rede de Hospitais São Camilo de São Paulo conta com espaços de Auditórios em suas Unidades para receber e promover eventos e Reuniões Científicas. 
          <br /><br />
          Se você tem interesse em conhecer os eventos da Rede São Camilo de São Paulo, ser um parceiro ou patrocinador entre em contato através do e-mail: <a href="mailto:eventos@hospitalsaocamilosp.org.br">eventos@hospitalsaocamilosp.org.br</a>
        </p>
      </section>


        {activeEvents.length === 0 ?
          null 
          :
          <section className='main-events'>
            <h2 className='events-title'>Agenda de eventos</h2>

            <div className='filter-wrapper'>
              <label className='filter-title'>Filtrar por:</label>
              <select className='filter-select' defaultValue='todas' onChange={e => filterEvents(e.target.value)}>
                <option value="todas" key='todas'>Todas as categorias</option>
                {categories.map((item, index) => (<option value={item} key={item + index}>{item}</option>))}
              </select>
            </div>
            <div className='events-wrapper'>
              {
                eventsFiltered.length === 0 ? 
                activeEvents.map(item => 
                  <div className='events-item' key={item.id}>
                    <div className='events-item-image' style={{backgroundImage: `url(${item.image})`}} />
                    <h3 className='events-item-title'>{item.name}</h3>
                    <p className='events-item-date'>{formatDate(item.start_date, true)}</p>
  
                    <a className='events-item-button' href={item.url} target='_blank' rel="noreferrer">Saiba Mais!</a>
                  </div>
                ) :
                eventsFiltered.map(item => 
                  <div className='events-item' key={item.id}>
                    <div className='events-item-image' style={{backgroundImage: `url(${item.image})`}} />
                    <h3 className='events-item-title'>{item.name}</h3>
                    <p className='events-item-date'>{formatDate(item.start_date, true)}</p>
  
                    <a className='events-item-button' href={item.url} target='_blank' rel="noreferrer">Saiba Mais!</a>
                  </div>
                )
              }
            </div>
          </section>
        }

      {/* <section className='past-events'>
        <h2 className='events-title'>Eventos Realizados</h2>

        {pastEvents.length === 0 ?
          <p className='events-not-found'>Nenhum evento realizado.</p> 
          :
          <>
            <div className='filter-wrapper'>
              <label className='filter-title'>Filtrar por:</label>
              <select className='filter-select' defaultValue='todas' onChange={e => filterPastEvents(e.target.value)}>
                <option value="todas" key='todas'>Todas as datas</option>
                {pastEventsDates.map((item, index) => (<option value={item} key={item + index}>{item}</option>))}
              </select>
            </div>
            <div className='events-wrapper'>
              {
                pastEventsFiltered.length === 0 ? 
                pastEvents.map(item => 
                  <div className='events-item' key={item.id}>
                    <img className='events-item-image' src={item.image} alt={item.name} />
                    <h3 className='events-item-title'>{item.name}</h3>
                    <p className='events-item-date'>{formatDate(item.start_date)}</p>
  
                    <a className='events-item-button' href={item.url} target='_blank' rel="noreferrer">Saiba Mais!</a>
                  </div>
                ) :
                pastEventsFiltered.map(item => 
                  <div className='events-item' key={item.id}>
                    <img className='events-item-image' src={item.image} alt={item.name} />
                    <h3 className='events-item-title'>{item.name}</h3>
                    <p className='events-item-date'>{formatDate(item.start_date)}</p>
  
                    <a className='events-item-button' href={item.url} target='_blank' rel="noreferrer">Saiba Mais!</a>
                  </div>
                )
              }
            </div>
          </>
        }
      </section> */}
    </div>

	  <Footer />
   </>
  )
};

export default Eventos