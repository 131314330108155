import React, { useState, useEffect } from 'react';
import { Breadcrumbs, ThemeProvider, Table, TableCell, Paper, TableHead, TableRow, TableBody, TableContainer, TextField } from '@material-ui/core';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { red, grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate';

import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import './Elegibilidade.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import exames from './exames.json';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C3000A",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: red
  },
});

const examesSorted = exames.sort((a, b) => { return a.Nome.localeCompare(b.Nome) })

const initPagination = {
  data: examesSorted,
  offset: 0,
  numberPerPage: 10,
  pageCount: 0,
  currentData: []
}

const Elegibilidade = () => {
  let history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const [ filterTable, setFilterTable ] = useState('');
  const [ pagination, setPagination ] = useState(initPagination);

  const handleClick = path => history.push(path);
  const handleChangeFilter = event => setFilterTable(event.target.value)

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,

      pageCount: examesSorted
        .filter(el => el.Nome.toLowerCase()
          .includes(filterTable.toLowerCase())).length / prevState.numberPerPage,

      data: examesSorted
        .filter(el => el.Nome.toLowerCase()
          .includes(filterTable.toLowerCase())),

      currentData: examesSorted
        .filter(el => el.Nome.toLowerCase()
          .includes(filterTable.toLowerCase()))
            .slice(pagination.offset, pagination.offset + pagination.numberPerPage)
    }))
  }, [pagination.numberPerPage, pagination.offset, filterTable])

  const handlePageClick = event => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage
    setPagination({ ...pagination, offset })
  }

  return (
    <>
    <Header />
    <MenuRapido />
    <section id="elegibilidade-page-section"> 
      <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-elegibilidade">			      
        <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">				      
          <HomeIcon id="breadcrumb-home-icon" />			      
        </a>
        <a
          color="inherit"
          href="/elegibilidade-de-exames"
          onClick={() => handleClick("/elegibilidade-de-exames")}
          className="breadcrumb-link"
        >
          Elegibilidade de Exames
        </a>
      </Breadcrumbs>
      <div id="elegibilidade-content">
        <h2 className="h2-gray-center">Elegibilidade de Exames</h2>
        <div id="elegibilidade-list-container">
        <ThemeProvider theme={theme}>
        <TextField 
						id="input"
						placeholder="Pesquise por exames" 
						onChange={handleChangeFilter}
					/>
        </ThemeProvider>
        {isMobile ?
          <ul id="card-list-mobile"> 
            {
              pagination.currentData
                .map((row, index) => (
                  <li 
                    key={row.CRM}
                    className={`row-card-mobile${index % 2 === 0 ? "" : "-odd"}`}
                  >
                    <p className="title-card-mobile"><strong>{row.Nome}</strong></p>
                    <div className="inline-content-card-mobile">
                      <p>
                        Ipiranga: {row.Ipiranga === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Pompeia: {row.Pompeia === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Santana: {row.Santana === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Centro Médico: {row.CentroMedico === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                    </div>
                  </li>
                ))
            }
            <div id="footer-table-page">
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={true}
              />
            </div>
          </ul>        
          :
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell id="table-cell-3"><b>Exame</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Centro Médico</b></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {pagination.currentData
                  .map((row) => (
                  <StyledTableRow key={row.Nome}>
                    <StyledTableCell component="th" scope="row">
                      {row.Nome}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.Ipiranga === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.Pompeia === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.Santana === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.CentroMedico === "1" ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <div id="footer-table-page">
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próxima'}
                breakLabel={'...'}
                pageCount={pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={true}
              />
            </div>
          </TableContainer>
        }
        </div>
      </div>		
    </section>
    <Footer/>
    </>
  )
}

export default Elegibilidade