import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import './OutubroRosa.style.scss'

import logoHSC from '../../images/logos/logodefault.png';
import cancerDataImg from './images/cancer-data-img.png';
import Banner from './images/banner-outubro-rosa.png';
import Book from './images/book.png';
import locationIcon from './images/location-icon.png';
import mulheresSilluete from './images/mulheres-silhueta.png';

import { SocialMOCK, PatientHistory, campaignLocations } from './LPData';

import parse from 'html-react-parser';

const OutubroRosa = () => {
    const [open, setOpen] = useState('')
    const location = useLocation();

    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <section id='header'>
                <a href="/">
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </section>

            <img src={Banner} className='banner' alt='Banner Outubro Rosa' />

            <section id='cancer-data'>
                <div className='cancer-data-text-img'>
                    <div className='cancer-data-text'>
                        <h2 className='cancer-data-text-title'>Dados sobre o <br /><strong>câncer de mama</strong></h2>

                        <p className='cancer-data-text-content'>
                           O câncer de mama é o <strong className='highlight-pink'>tipo de câncer mais letal para mulheres no mundo. </strong>  
                            Por isso, é importante estar em dia com as consultas e exames, além de realizar, 
                           sempre que possível o autoexame. <br /><br />

                           <strong 
                            style={{ 
                                color: `#58595B`, 
                            }}>
                                Para facilitar seu entendimento, fizemos um material especial sobre o tema.
                           </strong> 
                        </p>
                    </div>

                    {isMobile ?
                        <img class="cancer-data-img" src={cancerDataImg} alt="Rosto Mulher" />
                        :
                        <div class="cancer-data-img" 
                            style={{ 
                                backgroundImage: `url(${cancerDataImg})` 
                            }}>
                        </div>
                    }          
                </div>

                <div className='cancer-data-btn-img'>
                    <a className='cancer-data-btn' href="https://hospitalsaocamilosp.org.br/arquivos/Infografico_C%C3%A2ncer_de_Mama.pdf" target='_blank' rel='noreferrer'>
                        Confira aqui
                    </a>

                    <img className='cancer-data-btn-image' src={Book} alt="Livro" />
                </div>
            </section>

            <section id='campaign'>
                <div className='campaign-text'>
                    <h2>Conheça a nossa campanha:</h2>

                    <div className='campaign-text-content-wrapper'>
                        <p className='campaign-text-content'>
                            A vida é um livro adquirido no momento em que somos concebidos, em branco. 
                            Uma obra que é escrita diariamente por meio das experiências, trocas, encontros e 
                            desencontros, não é possível determinar o próximo capítulo com precisão, é viável 
                            trabalhar em si e planejar o que se deseja.
                        </p>

                        <p className='campaign-text-content'>
                            Quando os planos fogem daquilo que é programado e esperado, é indispensável coragem 
                            e sabedoria para lidar com o inesperado, e disposição para reescrever os próximos 
                            parágrafos e ressignificar a continuidade da história. 

                            <br /> 
                            <br /> 

                            <strong className='highlight-pink'>O câncer não é o início e não precisa ser o fim da sua história.</strong> 
                        </p>
                    </div>
                </div>

                <div className='campaign-video'>
                    <img className='campaign-video-img' src={mulheresSilluete} alt="Mulheres" />

                    <p className='campaign-video-text'>
                        Veja o vídeo que fizemos sobre nossa campanha, com a ajuda 
                        dos nossos colaboradores:  
                    </p>

                    <div className='iframe-wrapper'>
                        <iframe 
                            className='iframe'
                            src="https://www.youtube.com/embed/rTWLPwv3cOA?si=XepTjtsqUU_fg04T" 
                            title="Vídeo campanha" 
                            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                        >
                        </iframe>
                    </div>
                </div>
            </section>

            <section id='pacients-histories'>
                <h2>Conheça a história dos nossos pacientes:</h2>

                {PatientHistory.map((item, index) =>
                    (index % 2 === 0) ?
                        <>
                            <div className='patient-odd' key={item.id}>
                                <img className='patient-img' src={item.imageBook} alt={`Banner ${item.name}`} />

                                <div className='patient-container'>
                                    <img className='patient-container-img' src={item.imageTitle} alt={`Título ${item.name}`} />
                                    <p className='patient-container-text'>{item.textPreview}</p>
                                    <button className='patient-container-button' onClick={() => setOpen(item.name)}>...Leia mais ▼</button>
                                </div>
                            </div>

                            {
                                open === item.name && open !== '' ? 
                                <div className='modal-main' key={item.id}>
                                    <div className='modal-container'>
                                        <div className='modal-img-btn'>
                                            <img className='modal-img' src={item.imageTitle} alt={`Título ${item.name}`} />
                                            <button className='modal-button' onClick={() => setOpen('')}>X</button>
                                        </div>
                                        <p className='modal-text'>{parse(item.TextFull)}</p>
                                    </div>
                                </div> 
                                : 
                                null
                            }
                        </>
                    :
                        <>
                            <div className='patient-even' key={item.id}>
                                <div className='patient-container'>
                                    <img className='patient-container-img' src={item.imageTitle} alt={`Título ${item.name}`} />
                                    <p className='patient-container-text'>{item.textPreview}</p>
                                    <button className='patient-container-button' onClick={() => setOpen(item.name)}>...Leia mais ▼</button>
                                </div>

                                <img className='patient-img' src={item.imageBook} alt={`Banner ${item.name}`} />
                            </div>

                            {
                                open === item.name && open !== '' ? 
                                <div className='modal-main' key={item.id}>
                                    <div className='modal-container'>
                                        <div className='modal-img-btn'>
                                            <img className='modal-img' src={item.imageTitle} alt={`Título ${item.name}`} />
                                            <button className='modal-button' onClick={() => setOpen('')}>X</button>
                                        </div>
                                        <p className='modal-text'>{parse(item.TextFull)}</p>
                                    </div>
                                </div>  
                                : 
                                null
                            }
                        </>
                )}
            </section>

            {/* <section id='locations'>
                <h2 className='locations-title'>Onde estaremos esse mês:</h2>

                <p className='locations-desc'>Para ampliar a mensagem sobre o Outubro Rosa, estaremos presentes em diversos locais durante esse mês, com dicas de prevenção, divulgando as histórias dos nossos pacientes. Confira os locais onde estaremos:  </p>
                
                <div className='locations-wrapper'>
                    {campaignLocations.map(local => 
                        <div className='locations-item' key={local.id}>
                            <img className='locations-icon' src={locationIcon} alt="ícone" />

                            <div className='location-title-container'>
                                <p className='location-title'>{local.location}</p>
                            </div>

                            <p> <strong>Data:</strong> {local.date} </p>

                            {local.hour ? <p> <strong>Horário:</strong> {local.hour} </p> : null}

                            {local.dateExtra && local.hourExtra ? 
                            <>
                                <p> <strong>Data:</strong> {local.dateExtra} </p>
                                <p> <strong>Horário:</strong> {local.hourExtra} </p>
                            </>
                            :
                            null
                            }
                        </div>
                    )}
                </div>
            </section> */}

            <div className='footer'>
                <a href="/" >
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: 'center', alignItems: 'center'}}>
                    <p style={{color: "#606060", fontSize: "16px"}}>Diretor Médico: Dr. Fernando Pompeu - CRM 125543</p>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>
                </div>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </div>
        </main>
    )
}

export default OutubroRosa;