import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { grey, red, green } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import config from "../../helpers/config";

import "./FormGranja.style.scss";
import ReactGA from "react-ga4";

// ReactGA.initialize("UA-72749737-1");

const theme = createMuiTheme({
  palette: {
    primary: grey,
  },
});

const buttonTheme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red,
  },
});

const FormGranja = () => {
  const location = useLocation();

  const { register, handleSubmit, reset } = useForm();
  const [mensagemFaleConosco, setMensagemFaleConosco] = useState(false);
  const [openAssunto, setOpenAssunto] = useState(false);
//   const [openUnidade, setOpenUnidade] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disableUploadButton, setDisableUploadButton] = useState(true);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  //verify form to enable upload button
  useEffect(() => {
    if (
    //   mensagemFaleConosco.unidade &&
      mensagemFaleConosco.assunto &&
      mensagemFaleConosco.mensagem
    )
      setDisableUploadButton(false);
  }, [mensagemFaleConosco]);

  const onSubmit = (data) => {
    setOpen(true);

    fetch(`${config.host}/send-mail/FormGranja`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        ...data,
        assunto: mensagemFaleConosco.assunto,
        // unidade: mensagemFaleConosco.unidade,
        mensagem: mensagemFaleConosco.mensagem,
      }),
    })
      .then((res) => {
        console.log(res.status);

        if (res.status === 200) {
          setOpen(false);
          setSuccess(true);
          setShowAlert(true);
          reset();
        } else {
          setOpen(false);
          setSuccess(false);
          setShowAlert(true);
        }
      })
      .catch((err) => {
        setOpen(false);
        setShowAlert(true);
        setSuccess(false);
        console.log(err);
      });
  };

  const handleChangeAssunto = (event) =>
    setMensagemFaleConosco({
      ...mensagemFaleConosco,
      assunto: event.target.value,
    });
//   const handleChangeUnidade = (event) =>
//     setMensagemFaleConosco({
//       ...mensagemFaleConosco,
//       unidade: event.target.value,
//     });
  const handleChangeMensagem = (event) =>
    setMensagemFaleConosco({
      ...mensagemFaleConosco,
      mensagem: event.target.value,
    });

  const handleCloseAssunto = () => setOpenAssunto(false);
  const handleOpenAssunto = () => setOpenAssunto(true);

//   const handleCloseUnidade = () => setOpenUnidade(false);
//   const handleOpenUnidade = () => setOpenUnidade(true);

  return (
    <>
      <section id="form-granja-section">
        <div id="form-form-granja-container">
          {/* <TituloPrincipal titulo="Fale Conosco" /> */}
          {/* <h2 className="h2-gray-center">Fale Conosco</h2> */}
          <div className="form-granja-card-container">
            {/* <CardMedia className="form-granja-card-container-image" image={imgFaleConosco} alt="Fale Conosco" /> */}
          </div>
          <div className="form-granja-form">
            <form id="form-form-granja" onSubmit={handleSubmit(onSubmit)}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  className="input-big"
                  label="Nome"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                  floatingLabelFocusStyle="#FFF"
                  {...register("nome")}
                />
                <TextField
                  required
                  className="input-big"
                  label="Sobrenome"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                  {...register("sobrenome")}
                />
                <TextField
                  required
                  label="E-mail"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                  type="email"
                  className="input-big"
                  {...register("email")}
                />
                <InputMask
                  {...register("telefone")}
                  mask="(99) 99999-9999"
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      required
                      id="telefone-input"
                      label="Telefone"
                      InputLabelProps={{
                        style: { color: '#fff' },
                      }}
                      InputProps={{
                        style: { color: '#fff' },
                      }}
                      {...register("telefone")}
                    />
                  )}
                </InputMask>

                <FormControl className="form-granja-select-dropdown" >
                  <InputLabel required id="demo-controlled-open-select-label" style={{color: '#ffffff'}}>
                    Assunto
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={openAssunto}
                    onClose={handleCloseAssunto}
                    onOpen={handleOpenAssunto}
                    value={mensagemFaleConosco.assunto}
                    onChange={handleChangeAssunto}
                    SelectDisplayProps={{
                      style: { color: '#fff' },
                    }}
                  >
                    <MenuItem value="Linhas de Cuidados">
                      Linhas de Cuidados*
                    </MenuItem>
                    <MenuItem value="Orçamento">Orçamento</MenuItem>
                    <MenuItem value="Agendar Visita">Agendar Visita</MenuItem>
                  </Select>
                </FormControl>

                <p className="left"  style={{color: '#ffffff'}}>
                  *Cuidados Paliativos / Reabilitação Física / Longa Permanência
                  / Reintegração Social e Suporte Espiritual.
                </p>

                <TextField
                  required
                  fullWidth
                  id="outlined-multiline-static"
                  label="Mensagem"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                  multiline
                  rows={5}
                  placeholder="Descreva mais sobre as necessidades do paciente, para que nossos especialistas possam estudar melhor os cuidados que o seu familiar necessita."
                  onChange={handleChangeMensagem}
                  variant="outlined"
                  
                />
              </ThemeProvider>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  className="div-form-button"
                  id="submit-form-button"
                  variant="contained"
                  color="primary"
                  disabled={disableUploadButton}
                  // onClick={handleSubmit(onSubmit)}
                  component="label"
                  onClick= {ReactGA.event({
                    category: 'Btn_Envia_Formulario',
                    action: 'Clique',
                    label: 'Btn_Envia_Formulario',
                  })}
                >
                  Enviar
                  <input id="submit-form" type="submit" hidden />
                </Button>
              </ThemeProvider>
            </form>
          </div>
        </div>
      </section>
      {showAlert ? (
        success ? (
          <Alert severity="success">
            <AlertTitle>Sucesso</AlertTitle>
            Sua mensagem foi enviada.{" "}
            <strong>Agradecemos o seu contato!</strong>
          </Alert>
        ) : (
          <Alert severity="error">
            <AlertTitle>Erro</AlertTitle>
            <strong>Sua mensagem não foi enviada.</strong> Se o problema
            persistir favor entrar em contato.
          </Alert>
        )
      ) : null}
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className="modal-loading"
        open={open}
      >
        <CircularProgress color="inherit" />
      </Modal>
    </>
  );
};

export default FormGranja;
