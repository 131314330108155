import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumbs, CircularProgress, Button,
Table, TableBody, TableCell, TableContainer, 
TableHead, TableRow, Paper } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home'
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReactPaginate from 'react-paginate';

import config from '../../helpers/config';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import './ListaNovidades.style.scss';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'rgb(195, 0, 10);',
      color: 'white',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 600
    },
  });

const initPagination = {
    data: [],
    offset: 0,
    numberPerPage: 20,
    pageCount: 0,
    currentData: []
}

const ListaNovidades = () => {
    const classes = useStyles();
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem("userInfo"))
    
    const [ novidades, setNovidades ] = useState([])
    const [ stateLoaded, setStateLoaded ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ pagination, setPagination ] = useState(initPagination)
    
    const handlePageClick = event => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage
        setPagination({ ...pagination, offset })
    }

    const getNovidades = async () => {
        fetch(`${config.host}/novidades`, {
            method: 'get',
            headers: {
                "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                "authorization": user.token,
            }
        })
        .then(res => {
            console.log(res.status)
            if(res.status !== 200) {
                history.push('/admin/login')
            } else {
                res.json()
                    .then(res => {
                        console.log(res)
                        setNovidades(sortByDisplayAscending(res));
                        setStateLoaded(true);
                    })
                    .catch(err => {
                        console.log(err)
                        setStateLoaded(true);
                        setError(true);
                    })
            }
        })
    }

    const sortByDisplayAscending = arr => {
        return arr.sort((a,b) => {
            if(a.display_home_order && b.display_home_order) {
                return parseInt(a.display_home_order) - parseInt(b.display_home_order)
            } else {
                return Infinity
            }
        })
    }

    const sortByDateDescending = arr => {
        return arr.sort((a,b) => {
            if(a.publish_date && b.publish_date && a.publish_date !== "" && b.publish_date !== "") {
                const dateA = a.publish_date.includes("-") ? parseInt(a.publish_date.split("-").join("")) : parseInt(a.publish_date.split("/").reverse().join(""))
                const dateB = b.publish_date.includes("-") ? parseInt(b.publish_date.split("-").join("")) : parseInt(b.publish_date.split("/").reverse().join(""))
    
                return dateB - dateA
            } else {
                return Infinity
            }
        })
    }

    useEffect(() => {
        const displayOrdered = sortByDisplayAscending(novidades.filter(el => el.display_home_order > 0))
        const dateOrdered = sortByDateDescending(novidades.filter(el => !el.display_home_order))

        setPagination((prevState) => ({
            ...prevState,
            pageCount: novidades.length / prevState.numberPerPage,
            data: novidades,
            currentData: [...displayOrdered, ...dateOrdered]
                            .slice(pagination.offset, pagination.offset + pagination.numberPerPage),
        }))
    }, [pagination.numberPerPage, pagination.offset, novidades])

    useEffect(() => {
        if(!user || !user.admin) {
            history.push('/admin/login')
        }
    })

    useEffect(() => {
        getNovidades();
    }, [])

    const handleDelete = id => {
        fetch(`${config.host}/novidades/${id}`, {
            method: 'delete',
            headers: {
                "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                "authorization": user.token,
            }
        })
        .then(res => {
            console.log(res.status);
            getNovidades();
        })
    }

    const handleReactivate = id => {
        fetch(`${config.host}/novidades/reactivate/${id}`, {
            method: 'post',
            headers: {
                "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                "authorization": user.token,
            }
        })
        .then(res => {
            console.log(res.status);
            getNovidades();
        })
    }

    const handleRedisplayHome = id => {
        fetch(`${config.host}/novidades/restore-order-display/${id}`, {
            method: 'post',
            headers: {
                "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                "authorization": user.token,
            }
        })
        .then(res => {
            console.log(res.status);
            getNovidades();
        })
    }

    const handleRemoveDisplayHome = id => {
        fetch(`${config.host}/novidades/remove-order-display/${id}`, {
            method: 'post',
            headers: {
                "hsc_key": "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*",
                "authorization": user.token,
            }
        })
        .then(res => {
            console.log(res.status);
            getNovidades();
        })
    }

    const handleClick = path => history.push(path)

    return (
        <main>
            <Header />
            <section className="publicacoes-container">
                <div className="div-publicacoes-container-bg">
                    <div className="div-publicacoes-container">
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <div color="inherit" className="breadcrumb-link">
                                Publicações
                            </div>
                        </Breadcrumbs>
                        <TituloPrincipal titulo="Gestão de Publicações" />
                    </div>
                </div>
                <div className="div-publicacoes-content">
                    <Button
                        variant="contained"
                        className="publicacoes-add-button"
                        startIcon={<AddSharpIcon />}
                        onClick={() => handleClick("/admin/lista-novidades/gestao/")}
                    >
                        Adicionar Publicação
                    </Button>
                    
                    {
                        stateLoaded ? 
                            error ?
                                'Ops, ocorreu um erro.'
                            :
                            <>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Ordem Carrossel</StyledTableCell>
                                            <StyledTableCell align="center">Imagem</StyledTableCell>
                                            <StyledTableCell align="center">Título</StyledTableCell>
                                            <StyledTableCell align="center">Tipo</StyledTableCell>
                                            <StyledTableCell align="center">Autor</StyledTableCell>
                                            <StyledTableCell align="center">Data de Publicação</StyledTableCell>
                                            <StyledTableCell align="center">Ações</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pagination.currentData.sort((a, b) => 
                                            (a.display_home_order != null ? a.display_home_order : Infinity) 
                                            - 
                                            (b.display_home_order != null ? b.display_home_order : Infinity))
                                            .map(el => 
                                            <StyledTableRow key={el.id}>
                                                <StyledTableCell align="center">{el.display_home_order}</StyledTableCell>
                                                <StyledTableCell align="center"><img className="card-publicacao-imagem" src={el.image} alt={el.title}/></StyledTableCell>
                                                <StyledTableCell align="left">{el.title_text}</StyledTableCell>
                                                <StyledTableCell align="center">{el.novidade_type}</StyledTableCell>
                                                <StyledTableCell align="center">{el.author}</StyledTableCell>
                                                <StyledTableCell align="center">{el.publish_date}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div className="card-publicacao-icons">
                                                        <EditSharpIcon onClick={() => handleClick(`/admin/lista-novidades/gestao/${el.id}`)}/>
                                                        {
                                                            el.display_home_order ?
                                                                <VisibilityIcon onClick={() => handleRemoveDisplayHome(el.id)}/>
                                                                :
                                                                <VisibilityOffIcon onClick={() => handleRedisplayHome(el.id)}/>
                                                        }
                                                        {
                                                            el.active ? 
                                                                <DeleteSharpIcon onClick={() => {
                                                                    if(window.confirm("Tem certeza que deseja excluir?")) handleDelete(el.id);
                                                                }}/> 
                                                            : 
                                                                <AutorenewSharpIcon onClick={() => handleReactivate(el.id)} />
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div id="footer-table-page-novidades">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    pageCount={pagination.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={true}
                                />
                            </div>
                            </>
                        :
                            <CircularProgress color="inherit" />
                    }
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default ListaNovidades