import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import './Elogios.style.scss'

import logoHSC from '../../images/logos/logodefault.png';
import BannerMobile from '../CanalColaborador/images/banner3.jpg';
import BannerDesktop from '../CanalColaborador/images/banner2_1500x500.jpg';

import { SocialMOCK, elogiosMock } from './elogios.data';

const Elogios = () => {
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    const currentURL = window.location.pathname;

    if(currentURL === "/elogios") {
        document.title = 'Elogios - HSC';
    }

    async function downloadImage(imageSrc) {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
      
        const link = document.createElement('a')
        link.href = imageURL
        link.download = 'image file name here'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <section id='header'>
                <a href="/">
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </section>

            {isMobile ? <img src={BannerMobile} className='banner' alt='Banner Canal Colaborador - HSC' /> : <img src={BannerDesktop} className='banner' alt='Banner Canal Colaborador - HSC' />}

            <section className='elogios-main'>
                <h1 className='elogios-title'>Escolha aqui o melhor elogio para fazer ao seu colega!</h1>   

                <div className='elogios-wrapper'>
                    {elogiosMock.map(item =>
                        <div className='elogio-card' key={item.url}>
                            <div className='elogios-item-image' style={{backgroundImage: `url(${item.img})`}} />

                            <a className='elogios-button' href={item.img} download={item.nome}>Download</a>
                        </div>
                    )}
                </div>                       
            </section>

            <section className='footer'>
                <p>Vamos <span>juntos,</span></p>
                <p>vamos <span>com o coração,</span></p>
                <p>vamos <span>além do esperado</span></p>

                <div className='footer-info-wrapper'>
                    <a href="/" >
                        <img className='logo' src={logoHSC} alt="Logo HSC" />
                    </a>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>

                    <div className='social'>
                        {SocialMOCK.map(link => 
                            <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                                <img className='social-item-img' src={link.Image} alt={link.name} />
                            </a>
                        )}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Elogios;