import React, {useLayoutEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CardMedia, Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import './ConteudosEspeciais.style.scss';

import Header from '../../components/Header/Header.component.jsx';
import Footer from '../../components/Footer/Footer.component.jsx';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component.jsx';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component.jsx';

import conteudos from './ConteudosData.js'

const ConteudosEspeciais = () => {
	let history = useHistory();
  const location = useLocation();

	// Scroll to top if path changes
	useLayoutEffect(() => {
        window.scrollTo(0, 0);
  }, [location.pathname]);

	const handleClick = (path) => history.push(path);

  return (
    <>
    <Header/>
		<MenuRapido/>
		<section id="especialidades-medicas-section">
			<div id="especialidades-medicas-content">
				<div id="especialidades-container-header">
					<Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
						<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
							<HomeIcon id="breadcrumb-home-icon" />
						</a>
						<a color="inherit" href="/conteudos-especiais" onClick={() => handleClick("/conteudos-especiais")} className="breadcrumb-link" >
							Conteúdos Especiais
						</a>
					</Breadcrumbs>
					<TituloPrincipal titulo="Conteúdos Especiais" />
				</div>
				<div id="especialidades-container">
					{conteudos.map(el => 
							<div 
								className="card-especialidade-medica" 
								key={el.nome}
								onClick={() => handleClick(`/conteudos-especiais/${el.nomeUrl}`)}
							> 
								<CardMedia 
									className="card-especialidade-medica-image"
									image={el.imagemBanner}
									alt={el.alt}
								/>
								<span className="card-especialidade-medica-title">
									<p><strong>{el.nome}</strong></p>
								</span>
							</div>
						)
					}
				</div>
			</div>
		</section>
		<Footer/>
    </>
  )
}

export default ConteudosEspeciais