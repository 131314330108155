import iconeSeta from '../../images/icons/seta.svg'
import iconePortalPaciente from '../../images/icons/portal-do-paciente.svg'
import iconePortalMedico from '../../images/icons/portal-do-medico.svg'
import iconeServicos from '../../images/icons/serviços.svg'
import iconeMarqueConsulta from '../../images/icons/marque-sua-consulta.svg'


const menuCentralList = [
    {
        id: 1,
        title: 'Portal do Paciente',
        href: 'https://portalpaciente.hospitalsaocamilosp.org.br/',
        target: '_blank',
        title_image: iconePortalPaciente,
        title_image_alt: 'portal-do-paciente',
        title_icon: iconeSeta,
        title_icon_alt: '',
    },
    
    {
        id: 2,
        title: 'Portal do Médico',
        href: 'http://portalmedico.hospitalsaocamilosp.org.br/',
        target: '_blank',
        title_image: iconePortalMedico,
        title_image_alt: 'portal-do-médico',
        title_icon: iconeSeta,
        title_icon_alt: '',
    },
    {
        id: 3,
        title: 'Serviços',
        title_image: iconeServicos,
        href: '/servicos',
        target: '_self',
        title_image_alt: 'servicos',
        title_icon: iconeSeta,
        title_icon_alt: '', 
    },
    {
        id: 4,
        title: 'Marque sua Consulta',
        href: '/agendamento-online',
        target: '_self',
        title_image: iconeMarqueConsulta,
        title_image_alt: 'marque-sua-consulta',
        title_icon: iconeSeta,
        title_icon_alt: '',
    },
]

export default menuCentralList