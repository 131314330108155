import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { Breadcrumbs, Typography, Modal, CircularProgress } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import parse from 'html-react-parser';

import config from '../../helpers/config';

import './NovidadesArticle.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import doGet from '../../helpers/request';

const novidadeExample = {
    id: "",
    novidade_type: "",
    image: "",
    publish_date: "",
    link: "",
    author: "",
    author_depto: "",
    category: "",
    title: "",
    title_text: "",
    original_text: "",
    createdAt: "",
    updatedAt: ""
};

const NovidadesArticle = () => {
    const [novidadeArticle, setNovidadeArticle] = useState(novidadeExample)
    const [stateLoaded, setStateLoaded] = useState(false)
    // const [ linkYoutube, setLinkYoutube ] = useState(false)
    const { title } = useParams();
    let history = useHistory();
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        const filterNovidadPath = async () => {
            doGet(`${config.host}/novidades/?title=${title}`)
                .then(res => {
                    setNovidadeArticle(res)
                })

            setStateLoaded(true);
        }

        filterNovidadPath();
    }, [title])


    const handleClick = (path) => {
        history.push(path)
    }

    novidadeArticle.original_text = novidadeArticle.original_text.replace(/<figure class="image">/g, "")
    novidadeArticle.original_text = novidadeArticle.original_text.replace(/<figure class="media">/g, "")
    novidadeArticle.original_text = novidadeArticle.original_text.replace(/<\/figure>/g, "")

    // const rodapeImprensa = `<p><br /> <strong>Assessoria de Imprensa&nbsp;<br /> M&aacute;quina CW</strong><br /> <strong>Telefone:</strong> <a href="tel:+551131477418">11 3147 7418</a><br /> <strong>Celular:</strong> <a href="tel:+5511970660089">11 97066-0089</a><br /> <strong>E-mail:</strong> <a href="mailto:imprensa.saocamilo@maquinacw.com">imprensa.saocamilo@maquinacw.com</a></p>`

    return (
        <main id="novidade-article-section">
            <Header />
            <MenuRapido />
            <section className="novidades-container">
                <div className="div-novidades-container-bg">
                    <div className="div-novidades-container">
                        {
                            stateLoaded ?
                                <>
                                <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-article" separator="›">
                                    <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                        <HomeIcon id="breadcrumb-home-icon" />
                                    </a>
                                    {
                                        novidadeArticle.novidade_type === "imprensa" ? 
                                            <a color="inherit" href="/imprensa" onClick={() => handleClick("/imprensa")} className="breadcrumb-link" >Imprensa </a>
                                        :
                                            <a color="inherit" href="/blog" onClick={() => handleClick("/blog")} className="breadcrumb-link" >Blog </a>
                                    }
                                    <Typography color="#696969" className="breadcrumb-link">
                                        {novidadeArticle.title_text}
                                    </Typography>
                                </Breadcrumbs>
                                <h2 className="h2-gray-left">{novidadeArticle.title_text}</h2>
                                <p className="p-subtitle-content-gray">
                                    <strong>Publicação: </strong>
                                    {novidadeArticle.publish_date}
                                </p>
                                <p className="p-subtitle-content-gray">
                                    <strong>Autor: </strong>
                                    {novidadeArticle.author_depto}
                                </p>
                                {/* <p className="p-subtitle-content-gray">
                                    <strong>Categoria: </strong>
                                    {novidadeArticle.category}
                                </p> */}
                                <div>
                                    <img className="image-article" alt={novidadeArticle.title} src={novidadeArticle.image} />
                                </div>
                                <div className="article-content">
                                    {
                                        parse(novidadeArticle.original_text
                                            .replace(/<oembed url=/g, `<iframe frameborder="0" allowfullscreen src=`)
                                            .replace(/<\/oembed>/g, "</iframe>")
                                            .replace(/<figure class="media">/g, "")
                                            .replace(/<\/figure>/g, "")
                                            .replace(/<figure class="image">/g, "")
                                            .replace(/<\/figure>/g, "")
                                        )
                                    }
                                </div>
                                </>
                                :
                                <Modal
                                    disablePortal
                                    disableEnforceFocus
                                    disableAutoFocus
                                    className="modal-loading" open={!stateLoaded}
                                >
                                    <CircularProgress color="inherit" />
                                </Modal>
                        }
                        <div className="div-rodape-article">
                            {
                                novidadeArticle.novidade_type === "imprensa" ?
                                    <div className="div-voltar" onClick={() => handleClick("/imprensa")}>Voltar</div>
                                    :
                                    <div className="div-voltar" onClick={() => handleClick("/blog")}>Voltar</div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default NovidadesArticle