import React, { useState } from 'react';
import { TextField, 
	MenuItem, InputLabel, 
	FormControl, Select, 
	Breadcrumbs, CircularProgress,
	Modal } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import HomeIcon from '@material-ui/icons/Home';

import config from '../../helpers/config';

import './VisitaTecnica.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import estados from '../../components/FormEstados/estados';

const theme = createMuiTheme({
	palette: {
		primary: grey,
	},
});

const VisitaTecnica = () => {
	let history = useHistory();
	const { register, handleSubmit, reset } = useForm();
	const [ openEstado, setOpenEstado ] = useState(false);
	const [ openVisitantes, setOpenVisitantes ] = useState(false);
	const [ openUnidades, setOpenUnidades ] = useState(false);
	const [ openPeriodos, setOpenPeriodos ] = useState(false);
	const [ endereco, setEndereco ] = useState({endereco: '', uf: ''});
	const [ unidade, setUnidade ] = useState(false);
	const [ periodo, setPeriodo ] = useState(false);
	const [ visitantes, setVisitantes ] = useState(0);
	const [ open, setOpen ] = useState(false);
	const [ showAlert, setShowAlert ] = useState(false);
	const [ success, setSuccess ] = useState(false);

	const handleClick = path => history.push(path);

	const onSubmit = data => {
		setOpen(true);

		fetch(`${config.host}/send-mail/Visita Técnica`, 
		{
			headers: {
      	'Accept': 'application/json',
      	'Content-Type': 'application/json'
    	},
    	method: "POST",
    	body: JSON.stringify({
				...data,
				qtdVisitantes: visitantes,
				uf: endereco.uf,
				unidade: unidade,
				periodoVisita: periodo
			}),
		})
		.then(res => {
			setOpen(false);
			setShowAlert(true);
			setSuccess(true);

			reset()

			console.log(res.status);
		})
		.catch(err => {
			setOpen(false);
			setShowAlert(true);
			setSuccess(false);

			console.log(err);
		})
	}

	const handleChangeEstado = event => setEndereco({...endereco, uf: event.target.value})
	const handleCloseEstado = () => setOpenEstado(false)
  const handleOpenEstado = () => setOpenEstado(true)
	
	const handleChangeVisitantes = event => setVisitantes(event.target.value)
	const handleCloseVisitantes = () => setOpenVisitantes(false)
  const handleOpenVisitantes = () => setOpenVisitantes(true)
	
	const handleChangeUnidades = event => setUnidade(event.target.value)
	const handleCloseUnidades = () => setOpenUnidades(false)
  const handleOpenUnidades = () => setOpenUnidades(true)

	const handleChangePeriodo = event => setPeriodo(event.target.value)
	const handleClosePeriodos = () => setOpenPeriodos(false)
  const handleOpenPeriodos = () => setOpenPeriodos(true)

  return (
    <>
    <Header />
		<MenuRapido />
    <section id="visita-tecnica-section">
			<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-visita-tecnica" separator="›">
				<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
					<HomeIcon id="breadcrumb-home-icon"  />
				</a>
				<a
					color="inherit"
					href="/visita-tecnica"
					onClick={() => handleClick("/visita-tecnica")}
					className="breadcrumb-link"
				>
					Visita Técnica
				</a>
			</Breadcrumbs>
			<div id="form-visita-tecnica-container">
				<h2 className="h2-gray-center">Visita Técnica</h2>
				<p>Atender bem também consiste em receber de forma positiva profissionais de outras instituições para promover a troca de conhecimento e identificar oportunidade de melhorias. Se você trabalha em outra empresa e tem interesse em conhecer alguma área em nosso hospital, formalize seu contato no email.  </p>
				<form id="form-visita-tecnica" onSubmit={handleSubmit(onSubmit)}>
					<ThemeProvider theme={theme}>
					<TextField 
						required 
						fullWidth
						label="Nome Completo" 
						{...register('nomeCompleto')}
					/>
					<TextField 
						required 
						fullWidth
						label="Empresa/Instituição" 
						{...register('empresa')}
					/>
					<InputMask 
						{...register('cnpj')}
						mask="99.999.999/9999-99"
						maskChar=" "
					>
						{() => 
							<TextField 
								required 
								fullWidth
								label="CNPJ" 
								{...register('cnpj')}
							/>
						}
					</InputMask>
					<TextField 
						required 
						fullWidth
						label="Profissão" 
						{...register('profissao')}
					/>
					<TextField 
						required 
						fullWidth
						label="Cargo" 
						{...register('cargo')}
					/>
					<InputMask 
						{...register('telefone')}
						mask="(99) 999999999"
						maskChar=" "
					>
						{() => <TextField required fullWidth label="Telefone Comercial" {...register('telefone')}/>}
					</InputMask>
					<TextField 
						required 
						fullWidth
						label="Unidade/Depto./Setor que trabalha" 
						{...register('departamento')}
					/>
					<TextField 
						required 
						fullWidth
						label="Endereço Comercial" 
						{...register('enderecoComercial')}
					/>
					<TextField 
						required 
						fullWidth
						label="Cidade" 
						{...register('cidade')}
					/>
					<FormControl fullWidth className="select-dropdown">
						<InputLabel required id="demo-controlled-open-select-label">Estado</InputLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openEstado}
							onClose={handleCloseEstado}
							onOpen={handleOpenEstado}
							value={endereco.uf}
							onChange={handleChangeEstado}
						>
							{ estados.map(el => <MenuItem value={el.uf}>{el.uf}</MenuItem>) }
						</Select>
					</FormControl>
					<InputMask 
						{...register('cep')}
						mask="99999-999"
						maskChar=" "
					>
						{() => 
							<TextField 
							required 
							fullWidth
							label="CEP"
							{...register('cep')}
						/>}
					</InputMask>
					<TextField 
						required 
						fullWidth
						type="email"
						label="E-mail Comercial" 
						{...register('emailComercial')}
					/>
					<TextField
						required
						fullWidth
						id="outlined-multiline-static"
						label="Interesse da Visita Técnica"
						multiline
						rows={5}
						placeholder="Insira aqui o interesse da visita técnica."
						{...register('interesseVisita')}
						variant="outlined"
					/>
					<FormControl fullWidth className="select-dropdown">
						<InputLabel required id="demo-controlled-open-select-label">Quantidade de Visitantes</InputLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openVisitantes}
							onClose={handleCloseVisitantes}
							onOpen={handleOpenVisitantes}
							value={visitantes}
							onChange={handleChangeVisitantes}
						>
							<MenuItem value="1">1</MenuItem>
							<MenuItem value="2">2</MenuItem>
							<MenuItem value="3">3</MenuItem>
						</Select>
					</FormControl>
					<FormControl fullWidth className="select-dropdown">
						<InputLabel required id="demo-controlled-open-select-label">Unidade</InputLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openUnidades}
							onClose={handleCloseUnidades}
							onOpen={handleOpenUnidades}
							value={unidade}
							onChange={handleChangeUnidades}
						>
							<MenuItem value="ipiranga">Ipiranga</MenuItem>
							<MenuItem value="pompeia">Pompeia</MenuItem>
							<MenuItem value="santana">Santana</MenuItem>
						</Select>
					</FormControl>
					{
						visitantes > 0 ?
							Array.apply(null, { length: visitantes }).map((el, index) => 
							<TextField 
								required 
								fullWidth
								label={`Visitante ${index + 1}`} 
								{...register(`visitante${index + 1}`)}
							/>
						)
						: ''
					}
					<TextField 
						required 
						fullWidth
						label="Áreas que deseja visitar" 
						{...register('areasVisita')}
					/>
					<TextField
						required
						label="Data Pretendida da Visita"
						type="date"
						fullWidth
						InputLabelProps={{ 
							shrink: true 
						}}
						{...register('dataVisita')}
					/>
					<FormControl fullWidth className="select-dropdown input-small-3">
						<InputLabel required id="demo-controlled-open-select-label">Período Pretendido</InputLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openPeriodos}
							onClose={handleClosePeriodos}
							onOpen={handleOpenPeriodos}
							value={periodo}
							onChange={handleChangePeriodo}
						>
							<MenuItem value="matutino">Manhã</MenuItem>
							<MenuItem value="vespertino">Tarde</MenuItem>
						</Select>
					</FormControl>
					<InputMask 
						{...register('horaVisita')}
						mask="99:99 às 99:99"
						maskChar="0"
					>
						{() => 
							<TextField 
							required 
							fullWidth
							label="Horário pretendido"
							{...register('horaVisita')}
						/>}
					</InputMask>
					<TextField
						fullWidth
						id="outlined-multiline-static"
						label="Observações"
						multiline
						rows={5}
						placeholder="Insira aqui suas observações"
						{...register('observacoes')}
						variant="outlined"
					/>
					</ThemeProvider>
					<input id="submit-form" type="submit" />
					<Modal 
						disablePortal
  						disableEnforceFocus
  						disableAutoFocus
						className="modal-loading" open={open}
					>
        		<CircularProgress color="inherit" />
      		</Modal>
				</form>
				<p>Obs: O pedido deve ser encaminhado com, no mínimo, 20 (vinte) dias de antecedência da data pretendida para a visita. O responsável do departamento/setor analisa a viabilidade do pedido e o requerente recebe a resposta através de e-mail ou contato telefônico. As visitas não incluem estacionamento, lanches ou refeições, material didático, cópias ou outros. Não são aceitos questionários por e-mail, fax ou carta já que todas as dúvidas devem ser respondidas durante a visita.</p>
			</div>
		</section>
		{
			showAlert ?
				success ?
					<Alert severity="success">
						<AlertTitle>Sucesso</AlertTitle>
						Sua mensagem foi enviada. <strong>Agradecemos o seu contato!</strong>
					</Alert>
					:
					<Alert severity="error">
						<AlertTitle>Erro</AlertTitle>
						<strong>Sua mensagem não foi enviada.</strong> Se o problema persistir favor entrar em contato.
					</Alert>
			:
			null
		}
		<Footer />
    </>
  )
}

export default VisitaTecnica