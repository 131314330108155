import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
	TextField, MenuItem,
	InputLabel, FormControl,
	Select, Breadcrumbs, Button,
	CardMedia, Checkbox, FormControlLabel, Link
} from '@material-ui/core';
import { grey, red, green } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import HomeIcon from '@material-ui/icons/Home';
import config from '../../helpers/config';

import './FaleConosco.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import imgFaleConosco from '../../images/images/fale-conosco/fale-conosco.webp'
import swal from 'sweetalert';


const theme = createMuiTheme({
	palette: {
		primary: grey,
	},
});

const buttonTheme = createMuiTheme({
	palette: {
		primary: green,
		secondary: red
    },
});

const FaleConosco = () => {
	let history = useHistory();
	const location = useLocation();

	const { register, handleSubmit, reset } = useForm();
	const [mensagemFaleConosco, setMensagemFaleConosco] = useState(false);
	const [openAssunto, setOpenAssunto] = useState(false);
	const [openUnidade, setOpenUnidade] = useState(false);
	const [disableUploadButton, setDisableUploadButton] = useState(true);
	const [aceitaTermo, setAceitaTermo] = useState(false);

	


	// Scroll to top if path changes
	useLayoutEffect(() => {
        window.scrollTo(0, 0);
  	}, [location.pathname]);

	//verify form to enable upload button
	useEffect(() => {
		if(mensagemFaleConosco.unidade && 
		   mensagemFaleConosco.assunto &&
		   mensagemFaleConosco.mensagem) setDisableUploadButton(false);
	}, [mensagemFaleConosco])

	
	const onSubmit = (data) => {
		fetch(`${config.host}/send-mail/Fale Conosco`,
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({
					...data,
					assunto: mensagemFaleConosco.assunto,
					unidade: mensagemFaleConosco.unidade,
					mensagem: mensagemFaleConosco.mensagem
				})
			})
			.then(res => {
				console.log(res.status)
				if(res.status === 200) {

				swal("Sucesso!", "Sua mensagem foi enviada. Agradecemos o seu contato!", "success");
				
				setTimeout(() => {  window.location.href = "/fale-conosco"; }, 3000);	
				
				//setOpen(false);
				//setSuccess(true);
				//setShowAlert(true);
				
					if(aceitaTermo === true){
						try {
							fetch(`${config.host}/faleconosco`, {
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json'
								},
								method: 'POST',
								body: JSON.stringify(formData)
							})
						} catch (error) {
							console.log("Erro ao gravar dados na tabela falecon");
						}
					}
						
					
						

					reset();


				} else {
					swal("Erro!", "Sua mensagem não foi enviada.", "error");
					//setOpen(false);
					//setSuccess(false);
					//setShowAlert(true);
				}

				
			})
			.catch(err => {
				console.log(err)
			})
		}

	const handleClick = (path) => history.push(path);

	const handleChangeAssunto = event => setMensagemFaleConosco({ ...mensagemFaleConosco, assunto: event.target.value })
	const handleChangeUnidade = event => setMensagemFaleConosco({ ...mensagemFaleConosco, unidade: event.target.value })
	const handleChangeMensagem = event => setMensagemFaleConosco({ ...mensagemFaleConosco, mensagem: event.target.value })

	const handleCloseAssunto = () => setOpenAssunto(false)
	const handleOpenAssunto = () => setOpenAssunto(true)

	const handleCloseUnidade = () => setOpenUnidade(false)
	const handleOpenUnidade = () => setOpenUnidade(true)

	/////// Inicio

	//Recebe os dados do formulario
	const [formData, setFormData] = useState({
		nomeCompleto: '',
		email: '',
		checado: ''
	});

	//Pega os dados que o usuário digita no form
	const handleChange = e => { e.persist();
		setAceitaTermo(prevState => !prevState);
	 };
	const onChangeInput = e => setFormData( {...formData, [e.target.name]: e.target.value});
	

	///// fim

	return (
		<>
			<Header />
			<MenuRapido />
			<section id="fale-conosco-section">
				<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-fale-conosco" separator="›">
					<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
						<HomeIcon id="breadcrumb-home-icon" />
					</a>
					<a
						color="inherit"
						href="/fale-conosco"
						onClick={() => handleClick("/fale-conosco")}
						className="breadcrumb-link"
					>
						Fale Conosco
				</a>
				</Breadcrumbs>
				<div id="form-fale-conosco-container">
					<TituloPrincipal titulo="Fale Conosco" />
					{/* <h2 className="h2-gray-center">Fale Conosco</h2> */}
					<div className="fale-conosco-card-container">
						<CardMedia className="fale-conosco-card-container-image" image={imgFaleConosco} alt="Fale Conosco" />
					</div>
					<div className="fale-conosco-form">
						<p>Utilize este espaço para solicitar informações, enviar sugestões, dúvidas, elogios ou reclamações.</p>
						<p>Para falar com o SAC,  <a href='/sac' target='_self' rel="noreferrer" >clique aqui.</a></p>
						<p>Para solicitação de visita técnica, <a href='https://www.hospitalsaocamilosp.org.br/visita-tecnica' target='_blank' rel="noreferrer" >clique aqui.</a></p>
						<form id="form-fale-conosco" onSubmit={handleSubmit(onSubmit)}>
					
							<ThemeProvider theme={theme}>

								<TextField
									autoComplete="new-password"
									required
									className="input-big"
									label="Nome Completo"
									{...register('nomeCompleto')}
									onChange={onChangeInput}
									value={formData.nomeCompleto}
									
									
									
								/>
								<TextField
								    autoComplete="new-password"
									required
									label="E-mail"
									type="email"
									className="input-big"
									{...register('email')}
									onChange={onChangeInput}
									value={formData.email}
									
								/>
								<InputMask
									{...register('telefone')}
									mask="(99) 999999999"
									maskChar=" "
								>
									{() => <TextField required id="telefone-input" label="Telefone de Contato" {...register('telefone')} />}
								</InputMask>

								<FormControl className="select-dropdown input-small">
									<InputLabel required id="demo-controlled-open-select-label">Assunto</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										open={openAssunto}
										onClose={handleCloseAssunto}
										onOpen={handleOpenAssunto}
										value={mensagemFaleConosco.assunto}
										onChange={handleChangeAssunto}
									>
										<MenuItem value="informacoes">Informações</MenuItem>
										<MenuItem value="solicitacoes">Solicitações</MenuItem>
										<MenuItem value="duvidas">Dúvidas</MenuItem>
										<MenuItem value="sugestoes">Sugestões</MenuItem>
										<MenuItem value="elogios">Elogios</MenuItem>
										<MenuItem value="agradecimentos">Agradecimentos</MenuItem>
										<MenuItem value="reclamacoes">Reclamações</MenuItem>
										<MenuItem value="outros">Outros</MenuItem>
									</Select>
								</FormControl>

								<FormControl className="select-dropdown input-small">
									<InputLabel required id="demo-controlled-open-select-label" >Unidade</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										open={openUnidade}
										onClose={handleCloseUnidade}
										onOpen={handleOpenUnidade}
										value={mensagemFaleConosco.unidade}
										onChange={handleChangeUnidade}
									>
										<MenuItem value="pompeia">Pompeia</MenuItem>
										<MenuItem value="santana">Santana</MenuItem>
										<MenuItem value="ipiranga">Ipiranga</MenuItem>
										{/*<MenuItem value="jacana">Jaçanã</MenuItem>*/}
										{/* <MenuItem value="granja-viana">Granja Viana</MenuItem> */}
									</Select>
								</FormControl>

								<TextField
									required
									fullWidth
									id="outlined-multiline-static"
									label="Mensagem"
									multiline
									rows={5}
									placeholder="Insira aqui a sua mensagem"
									onChange={handleChangeMensagem}
									variant="outlined"
								/>
							</ThemeProvider>

							<FormControlLabel 
      							
								  control={<Checkbox />}
								  name='checado'
								  onChange={handleChange}
								  checked={aceitaTermo}
								  value="end"
								  labelPlacement="end"
								  //id='checar'
								  label={
									<p>
									  Eu autorizo receber comunicações e campanhas de acordo com meus interesses. <br></br>
									  Ao informar meus dados, eu concordo com a&nbsp;  
									  <Link
										  href="https://www.hospitalsaocamilosp.org.br/duvidas-frequentes/lgpd"
										  target='_blank'
									  >
										Política de Privacidade e com os Termos de Uso.
									  </Link>
									</p>
								  }
								  
								 
								 

    						/>
							
							
							<ThemeProvider theme={buttonTheme}>
								<br></br>
								<Button
								
									className="div-form-button"
									id="submit-form-button"
									variant="contained"
									color="primary"
									disabled={disableUploadButton}
									// onClick={handleSubmit(onSubmit)}
									component="label">
										Enviar
										<input id="submit-form" type="submit" hidden/>
								</Button>
							</ThemeProvider>
						</form>
					</div>
				</div>
			</section>



			
			{/*
				showAlert ?
					success ?
						<Alert severity="success">
							<AlertTitle>Sucesso</AlertTitle>
						Sua mensagem foi enviada. <strong>Agradecemos o seu contato!</strong>
						</Alert>
						:
						<Alert severity="error">
							<AlertTitle>Erro</AlertTitle>
							<strong>Sua mensagem não foi enviada.</strong> Se o problema persistir favor entrar em contato.
					</Alert>
					:
					null
			}
					<Modal 
						disablePortal
  						disableEnforceFocus
  						disableAutoFocus
						className="modal-loading" open={open}
					>
						<CircularProgress color="inherit" />
		</Modal>*/}
			<Footer />
		</>
	)
}

export default FaleConosco