import React, { useLayoutEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import HSCIcon from '../../images/icons/sao-camilo-icone.png'

import { useHistory, useLocation } from 'react-router-dom';

import './MapaSite.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';



const MapaSite = () => {
    let history = useHistory();
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        path.includes('http') ? window.location.href=path : history.push(path);
    }
    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="mapa-container">
                <div className="div-mapa-container-bg">
                    <div className="div-mapa-container">
                        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <a color="inherit" href="/mapa-do-site" onClick={() => handleClick("/mapa-do-site")} className="breadcrumb-link" >
                                Mapa do Site
                            </a>
                        </Breadcrumbs>
                        <TituloPrincipal titulo="Mapa do Site" />
                        <div className="mapa-tab-container">
                            <div className="link-redirect" onClick={() => handleClick("/")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Home</div></div>
                            <div className="link"><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Institucional</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/institucional/nossa-historia")}> <div className="link-secundario">Nossa História</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/institucional/sao-camilo-de-lellis")}> <div className="link-secundario">São Camilo de Lellis</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/institucional/missao-visao-valores")}> <div className="link-secundario">Missão, Visão e Valores</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/institucional/linha-do-tempo")}> <div className="link-secundario">Linha do Tempo</div></div>

                            <div className="link"><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Unidades</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/unidades/santana")}> <div className="link-secundario">Santana</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/unidades/ipiranga")}> <div className="link-secundario">Ipiranga</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/unidades/pompeia")}> <div className="link-secundario">Pompeia</div></div>
                            {/* <div className="link-redirect" onClick={() => handleClick("https://granjaviana.hospitalsaocamilosp.org.br")}> <div className="link-secundario">Granja Viana</div></div> */}
                           

                            <div className="link-redirect" onClick={() => handleClick("/pronto-socorro")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Pronto-Socorro</div></div>

                            <div className="link"><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Especialidades</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/especialidades")}> <div className="link-secundario">Especialidades</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/especialidades-por-unidades")}> <div className="link-secundario">Especialidades por Unidade</div></div>

                            <div className="link-redirect" onClick={() => handleClick("/pronto-socorro")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Pronto-Socorro</div></div>

                            <div className="link-redirect" onClick={() => handleClick("/agendamento-online")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Agendamento Online</div></div>
                            <div className="link-redirect" onClick={() => handleClick("https://portalpaciente.hospitalsaocamilosp.org.br/")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Resultado de Exames</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/planos-de-saude")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Convênios</div></div>
                            <div className="link-redirect" onClick={() => handleClick("https://trabalheconosco.vagas.com.br/hospital-sao-camilo-sp")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Trabalhe Conosco</div></div>
                            
                            <div className="link"><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Dúvidas Frequentes</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/achados-e-perdidos")}> <div className="link-secundario">Achados e Perdidos</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/alimentacao")}> <div className="link-secundario">Alimentação</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/coronavirus")}> <div className="link-secundario">Coronavírus</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/banco-de-sangue")}> <div className="link-secundario">Banco de Sangue</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/estacionamento")}> <div className="link-secundario">Estacionamento</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/horario-de-visitas")}> <div className="link-secundario">Horário de Visitas</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/horario-de-atendimento")}> <div className="link-secundario">Horário de Atendimento</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/sac")}> <div className="link-secundario">SAC</div></div>
                            <div className="link-redirect" onClick={() => handleClick("/duvidas-frequentes/servicos-acompanhantes")}> <div className="link-secundario">Serviços para Acompanhantes</div></div>
                            
                            <div className="link-redirect" onClick={() => handleClick("/medicos")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Encontre seu Médico</div></div>

                            <div className="link-redirect" onClick={() => handleClick("https://portalmedico.hospitalsaocamilosp.org.br/")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Portal do Médico</div></div>

                            <div className="link-redirect" onClick={() => handleClick("/visita-tecnica")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Visita Técnica</div></div>

                            <div className="link-redirect" onClick={() => handleClick("/orcamento")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Orcamento</div></div>

                            <div className="link-redirect" onClick={() => handleClick("https://intranet.hospitalsaocamilosp.org.br/")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Colaboradores</div></div>

                            <div className="link-redirect" onClick={() => handleClick("/fale-conosco")}><img src={HSCIcon} className="hscicon" alt="HSC Icon" /><div className="link-primario">Fale Conosco</div></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default MapaSite