import React from 'react';
import './TituloPrincipal.style.scss'

const TituloPrincipal = (props) => {
    if(props==null || props.titulo==null){
        props.titulo="Título";
    }

    return (
        <>
            <div id="titulo-prinpal-row">
                <div className="background">
                    <div className="title">
                        {props.titulo}
                    </div>
                </div>
            </div>
        </>
    )
  }
export default TituloPrincipal