import React from 'react';
import { useHistory } from 'react-router';

import linkedinIcon from '../../images/icons/footer-linkedin-original.svg'
import twitterIcon from '../../images/icons/footer-twitter-original.svg'
import facebookIcon from '../../images/icons/footer-facebook-original.svg'
import instagramIcon from '../../images/icons/footer-instagram.svg'
import youtubeIcon from '../../images/icons/footer-youtube.svg'
import spotifyIcon from '../../images/icons/footer-spotify.svg'
import phoneIcon from '../../images/icons/footer-phone.svg'

import './Footer.style.scss'

const Footer = () => {
    let history = useHistory();
    const handleClick = path => history.push(path)

    return (
        <footer>
            <div className="direction-container-row">
                <div id="links">
                    <p><a target="_blank" rel="noreferrer" href="https://intranet.hospitalsaocamilosp.org.br/">Colaboradores</a></p>
                    {/* <p onClick={() => handleClick("/canal-do-colaborador")}>Canal do Colaborador</p> */}
                    <p onClick={() => handleClick("/unidades")}>Unidades</p>
                    <p onClick={() => handleClick("/pronto-socorro")}>Pronto-Socorro</p>
                    <p onClick={() => handleClick("/especialidades")}>Especialidades Médicas</p>
                    <p><a target="_self" rel="noreferrer" href="/resultados-exames">Resultados de Exames</a></p>
                    <p onClick={() => handleClick("/agendamento-online")}>Marque sua Consulta</p>
                    <p onClick={() => handleClick("/planos-de-saude")}>Convênios</p>
                    <p><a href="https://trabalheconosco.vagas.com.br/hospital-sao-camilo-sp" rel="noreferrer" target="_blank">Trabalhe Conosco</a></p>
                    <p onClick={() => handleClick("/blog")}>Blog</p>
                    <p onClick={() => handleClick("/imprensa")}>Imprensa</p>
                    <p onClick={() => handleClick("/compliance")}>Compliance</p>
                    <p onClick={() => handleClick("/duvidas-frequentes/lgpd")}>Política de Privacidade</p>
                    <p onClick={() => handleClick("/canal-de-bioetica")}>Canal de Bioética</p>
                    <p onClick={() => handleClick("/sao-camilo-empresas")}>São Camilo nas Empresas</p>
                    <p onClick={() => handleClick("/cadastro-fornecedores")}>Fornecedores</p>
                </div>
                <div className="direction-container-column">
                    <div id="atendimento" className='atendimento'>
                        <p onClick={() => handleClick("/fale-conosco")} className="title-div-footer">
                            <u>FALE CONOSCO</u>
                        </p>
                        <br /><br />
                    </div>
                    <div id="telefone">
                        <div className='telefone-tile'>
                            <img className="icon" src={phoneIcon} alt="Icone de Telefone - Número do Hospital São Camilo" />
                            <p>Telefones:</p>
                        </div>
                        <p><strong>Central de Atendimento: <a className='telefone-item' href="tel:+551131726800">(11) 3172-6800</a></strong></p>
                        <p><strong>Unidade Pompeia: <a className='telefone-item' href="tel:+551136774444">(11) 3677-4444</a>/<a className='telefone-item' href="tel:+551136774500"> (11) 3677-4500</a></strong></p>
                        <p><strong>Unidade Santana: <a className='telefone-item' href="tel:+551129728000">(11) 2972-8000</a>/<a className='telefone-item' href="tel:+551137770755"> (11) 3777-0755</a></strong></p>
                        <p><strong>Unidade Ipiranga: <a className='telefone-item' href="tel:+551120667000">(11) 2066-7000</a> / <a className='telefone-item' href="tel:+551120661600"> (11) 2066-1600</a></strong></p>
                    </div>
                </div>
                <div className="direction-container-column">
                    {/* <a className='logo-alvo' href="http://www.ocancerdemamanoalvodamoda.com.br/" target='_blank' rel="noreferrer" >
                        <img className='logo-alvo' src={logoAlvo} alt="Logo Alvo Azul São Camilo" />
                    </a>
                    <p className='text-alvo-main'>O São Camilo é detentor da Campanha "O Câncer de Mama no Alvo da Moda"<br/>que simboliza a conscientização a respeito do câncer de mama.</p>
                    <p className='text-alvo'>O Câncer de Mama no Alvo da Moda é marca licenciada do Conselho de Designers de Moda da América. Fundação CFDA, EUA - Hospital São Camilo - BR</p> */}
                    <div id="canais-title">
                        <p className="title-div-footer">
                            Nossos canais:
                        </p>
                    </div>
                    <div id="canais">
                        <a href="https://www.linkedin.com/company/hospitalsaocamilosp/?originalSubdomain=br" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={linkedinIcon} alt="LinkedIn Icone link para perfil do HSC" />
                        </a>
                        {/* <a href="https://twitter.com/hospsaocamilosp" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={twitterIcon} alt="Twitter Icone link para perfil do HSC" />
                        </a> */}
                        <a href="https://www.facebook.com/hospitalsaocamilosp/" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={facebookIcon} alt="Facebook Icone link para perfil do HSC" />
                        </a>
                        <a href="https://www.instagram.com/hospitalsaocamilosp/?hl=pt-br" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={instagramIcon} alt="Instagram Icone link para perfil do HSC" />
                        </a>
                        <a href="https://www.youtube.com/user/RedeSaoCamiloSP" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={youtubeIcon} alt="Youtube Icone link para perfil do HSC" />
                        </a>
                        <a href="https://open.spotify.com/show/4Ov84RYyq1XTHqxH8soaPj?si=uoyQg3YTR-m8tezKnZxexg" rel="noreferrer" target="_blank">
                            <img className="social-icon" src={spotifyIcon} alt="Spotify Icone link para perfil do HSC" />
                        </a>
                    </div>
                       <div className="dpo"><br/>
                        <p><strong>Diretor Médico:</strong> Dr. Fernando Pompeu - CRM 125543</p>
                        </div>
                </div>
            </div>
            <div id="copyright">
                <p>© Copyright 2024 | Sociedade Beneficente São Camilo</p>
            </div>
        </footer>
    )
}

export default Footer