class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
      this.url = `https://hospitalsaocamilosp.org.br/api/upload`;
      this.token = JSON.parse(localStorage.getItem("userInfo")).token;
    }

    
    upload() {
      return new Promise(async (resolve, reject) => {
        this.loader.file.then((file) => {
          if(file.size / 1024 / 1024 > 5) return alert("Tamanho do arquivo excede o limite de 5MB")
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        });
      });
    }
  
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }
  
    _initRequest() {
      const xhr = (this.xhr = new XMLHttpRequest());
      xhr.open("POST", this.url, true);
  
      xhr.setRequestHeader("Authorization", this.token);
      xhr.setRequestHeader("hsc_key", "X9aWH@6an&ZAp28$mMzRUg&s@me&Q9r1vEzN&5#tb5j^5NMNB*");
  
      xhr.responseType = "json";
    }
  
    _initListeners(resolve, reject, file) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = `Couldn't upload file: ${file.name}.`;
  
      xhr.addEventListener("error", () => reject(genericErrorText));
      xhr.addEventListener("abort", () => reject());
  
      xhr.addEventListener("load", () => {
        const response = xhr.response;
  
        if (!response || response.error) {
          return reject(
            response && response.error ? response.error.message : genericErrorText
          );
        }

        resolve({default: response.url});
      });
  
      if (xhr.upload) {
        xhr.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }
  
    _sendRequest(file) {
      const data = new FormData();
  
      data.append("attachments", file);
  
      this.xhr.send(data);
    }
}

export default MyUploadAdapter;