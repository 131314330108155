import CapelaIpiranga from "../../images/images/unidades/Capela-Ipiranga.png";
import DonaRosa from "../../images/images/unidades/Dona-Deola.png";
import Estapar from "../../images/images/unidades/Estapar.png";
import CapelaSantana from "../../images/images/unidades/Capela-Santana.png";
import Bauducco from "../../images/images/unidades/Casa-Bauducco.png";
import Carrefour from "../../images/images/unidades/Carrefour-Express.png";
import Ioupie from "../../images/images/unidades/ioupie.png";
import CapelaPompeia from "../../images/images/unidades/Capela-Pompeia.png";

const conveniencias = [
    {
        unidade: 'Pompeia',
        conveniencias: [
            {
                title: 'Serviço religioso',
                image: CapelaPompeia,
                description: 'Espaço destinado aos pacientes e seus familiares que buscam encontrar paz e harmonia física e espiritual.',
                local: '6º andar Bloco I',
                funcionamento: '24h* por dia'
            },
            {
                title: 'Cafeteria e Empório Casa Bauducco',
                image: Bauducco,
                description: 'Refeições rápidas, salgados, produtos forneados artesanais como Panettones e Chocottones, dentre outros itens da marca Bauducco.',
                local: 'Térreo',
                funcionamento: `<br/>Segunda a Sábado: Das 8h às 22h - <br/> Domingos e feriados: Das 8h às 20h`
            },
            {
                title: 'Carrefour Express',
                image: Carrefour,
                description: 'Ambiente agradável para você com produtos prontos para consumo, higiene, refrigerados, entre outros.',
                local: '2º subsolo, em frente ao elevador',
                funcionamento: 'Das 7h às 22h'
            },
            {
                title: 'Café e restaurante dona deôla',
                image: DonaRosa,
                description: 'Restaurante e lanchonete com a qualidade Deôla',
                local: '1º andar, próximo ao Pronto Socorro',
                funcionamento: '24h* por dia',
                additionalInfo: '*A alteração do horário de funcionamento pode ocorrer sem aviso prévio.'
            },
            {
                title: 'Estapar',
                image: Estapar,
                description: 'Oferecemos estacionamento com acesso coberto e serviço de manobristas*',
                local: '2º subsolo, próximo ao Carrefour Express',
                funcionamento: '24h* por dia',
                additionalInfo: '*Verifique a tabela de preços com o prestador de serviço.'
            },
            {
                title: 'Delivery de Lavanderia Ioupie',
                image: Ioupie,
                description: 'Sistema de armário inteligente, que encaminha suas roupas para a lavanderia com comodidade e praticidade. Baixe o aplicativo da Ioupie e saiba mais.',
                local: 'Próximo a entrada da Internação, após os elevadores do Hall Principal.',
                funcionamento: '24 horas por dia [mediante cadastro no aplicativo]',
            }
        ]
    },
    {
        unidade: 'Santana',
        conveniencias: [
            {
                title: 'Serviço religioso',
                image: CapelaSantana,
                description: 'Espaço destinado aos pacientes e seus familiares que buscam encontrar paz e harmonia física e espiritual.',
                local: '5º andar',
                funcionamento: '24h* por dia'
            },
            {
                title: 'Cafeteria e Empório Casa Bauducco',
                image: Bauducco,
                description: 'Refeições rápidas, salgados, produtos forneados artesanais como Panettones e Chocottones, dentre outros itens da marca Bauducco.',
                local: 'Térreo',
                funcionamento: 'Das 7h às 22h'
            },
            {
                title: 'Café e restaurante dona deôla',
                image: DonaRosa,
                description: 'Restaurante e lanchonete com a qualidade Deôla',
                local: '1º andar',
                funcionamento: '24h* por dia',
                additionalInfo: '*A alteração do horário de funcionamento pode ocorrer sem aviso prévio.'
            },
            {
                title: 'Estapar',
                image: Estapar,
                description: 'Oferecemos estacionamento com acesso coberto e serviço de manobristas*',
                local: 'Térreo',
                funcionamento: '24h* por dia',
                additionalInfo: '*Verifique a tabela de preços com o prestador de serviço.'
            }
        ]
    },
    {
        unidade: 'Ipiranga',
        conveniencias: [
            {
                title: 'Serviço religioso',
                image: CapelaIpiranga,
                description: 'Espaço destinado aos pacientes e seus familiares que buscam encontrar paz e harmonia física e espiritual.',
                local: 'Térreo',
                funcionamento: '24h* por dia'
            },
            {
                title: 'Café e restaurante dona deôla',
                image: DonaRosa,
                description: 'Restaurante e lanchonete com a qualidade Deôla',
                local: '1º andar',
                funcionamento: '24h* por dia',
                additionalInfo: '*A alteração do horário de funcionamento pode ocorrer sem aviso prévio.'
            },
            {
                title: 'Estapar',
                image: Estapar,
                description: 'Oferecemos estacionamento com acesso coberto e serviço de manobristas*',
                local: 'Térreo',
                funcionamento: '24h* por dia',
                additionalInfo: '*Verifique a tabela de preços com o prestador de serviço.'
            }
        ]
    }
]

export default conveniencias
