import React, { useLayoutEffect} from 'react';
import {
	TextField, FormControl, Select, Breadcrumbs, Button
} from '@material-ui/core';
import { grey, red, green } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import HomeIcon from '@material-ui/icons/Home';
import config from '../../helpers/config';
import InputLabel from '@material-ui/core/InputLabel';
import './SaoCamiloEmpresas.style.scss';
import MenuItem from '@material-ui/core/MenuItem';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import imgCadastroFornecedores from '../../images/images/sao-camilo-empresas/hsc_empresas.png'
import swal from 'sweetalert';

const theme = createMuiTheme({
	palette: {
		primary: grey,
	},
});

const buttonTheme = createMuiTheme({
	palette: {
		primary: green,
		secondary: red
    },
});

const SaoCamiloEmpresas = () => {
	let history = useHistory();
	const location = useLocation();

	const { register, handleSubmit, reset } = useForm();

	const btnRein={
		paddingTop: '9px',
	  }
	

	// Scroll to top if path changes
	useLayoutEffect(() => {
        window.scrollTo(0, 0);
  	}, [location.pathname]);

	const onSubmit = (data) => {

		fetch(`${config.host}/send-mail/HSC Empresas`,
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({
					...data,
				})
			})
			.then(res => {
				console.log(res.status)

				if(res.status === 200) {
					swal("Sucesso!", "Sua inscrição foi enviada com sucesso. Agradecemos o seu contato!", "success");
					reset();
				} 
			})
			.catch(err => {
				swal("Erro!", "Sua inscrição não foi enviada.", "error");
				console.log(err)
			})
	}

	const handleClick = (path) => history.push(path);

	return (
		<>
			<Header />
			<MenuRapido />
			<section id="cadastro-fornecedores-section">
				<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-cadastro-fornecedores" separator="›">
					<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
						<HomeIcon id="breadcrumb-home-icon" />
					</a>
					<a
						color="inherit"
						href="/sao-camilo-empresas"
						onClick={() => handleClick("/sao-camilo-empresas")}
						className="breadcrumb-link"
					>
						São Camilo nas Empresas
				</a>
				</Breadcrumbs>
				<div id="form-cadastro-fornecedores-container">
					<center className="cadastro-fornecedores-card-container">
                            <img src={imgCadastroFornecedores} className="img-banner-blog-emp" alt='São Camilo Empresa'></img>
                    </center>
					<div className="cadastro-fornecedores-form">
					<p>A Rede de Hospitais São Camilo de São Paulo acredita no poder do conhecimento para que, juntos, possamos ser uma sociedade mais informada e conectada. Informações de fontes seguras salvam pessoas. É pensando nisso que criamos o São Camilo nas Empresas: um projeto para levar informações de saúde através dos nossos profissionais em empresas, escolas, indústrias entre outros estabelecimentos comerciais.</p>
						<p>Caso você tenha interesse em saber mais sobre o tema, entre em contato conosco através do formulário abaixo.</p>
						<p>Entraremos em contato com você em breve.</p>
						<form id="form-cadastro-fornecedores" onSubmit={handleSubmit(onSubmit)}>
							<ThemeProvider theme={theme}>
							{ /* <span className="form-divisor-fornecedores">Dados da empresa</span> */ }
								<TextField
									autoComplete="new-password"
									required
									label="Empresa Solicitante"			
									className="input-big"
									{...register('EmpresaSolicitante')}
								/>
								<FormControl className="input-small" >
								<TextField
									autoComplete="new-password"
									required
									label="Rua / Avenida"			
									className="input-big"
									{...register('EnderecoCompleto')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Número"
									className="input-big"
									{...register('Numero')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Complemento"
									className="input-big"
									{...register('Complemento')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Bairro"
									className="input-big"
									{...register('Bairro')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Cidade"
									className="input-big"
									{...register('Cidade')}
								/>
								</FormControl>

								<FormControl className="input-small" >
									<InputLabel required id="demo-controlled-open-select-label">
										Estado
									</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										{...register('Estado')}
									>
										<MenuItem value="AC">AC</MenuItem>
										<MenuItem value="AL">AL</MenuItem>
										<MenuItem value="AP">AP</MenuItem>
										<MenuItem value="AM">AM</MenuItem>
										<MenuItem value="BA">BA</MenuItem>
										<MenuItem value="CE">CE</MenuItem>
										<MenuItem value="DF">DF</MenuItem>
										<MenuItem value="ES">ES</MenuItem>
										<MenuItem value="GO">GO</MenuItem>
										<MenuItem value="MA">MA</MenuItem>
										<MenuItem value="MT">MT</MenuItem>
										<MenuItem value="MS">MS</MenuItem>
										<MenuItem value="MG">MG</MenuItem>
										<MenuItem value="PA">PA</MenuItem>
										<MenuItem value="PB">PB</MenuItem>
										<MenuItem value="PR">PR</MenuItem>
										<MenuItem value="PE">PE</MenuItem>
										<MenuItem value="PI">PI</MenuItem>
										<MenuItem value="RJ">RJ</MenuItem>
										<MenuItem value="RN">RN</MenuItem>
										<MenuItem value="RS">RS</MenuItem>
										<MenuItem value="RO">RO</MenuItem>
										<MenuItem value="RR">RR</MenuItem>
										<MenuItem value="SC">SC</MenuItem>
										<MenuItem value="SP">SP</MenuItem>
										<MenuItem value="SE">SE</MenuItem>
										<MenuItem value="TO">TO</MenuItem>

									</Select>
                				</FormControl>
								
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="CEP"
									className="input-big"
									{...register('Cep')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Telefone"
									className="input-big"
									{...register('Telefone')}
								/>
								</FormControl>
								<FormControl className="input-small" >
								<TextField
									autoComplete="new-password"
									required
									label="Ramal"
									className="input-big"
									{...register('Ramal')}
								/>
								</FormControl>
								<TextField
									autoComplete="new-password"
									required
									label="E-mail"			
									className="input-big"
									{...register('Email')}
								/>
								<TextField
									autoComplete="new-password"
									required
									label="Ramo / Atividade"			
									className="input-big"
									{...register('RamoAtividade')}
								/>
								<FormControl className="input-big" >
									<TextField
										required
										label="Possui convênio com algum plano de saúde?"			
										style={btnRein}
										
										{...register('PlanoConvenio')}
									/>
								</FormControl>
								<TextField
									required
									label="Qual?"			
									className="input-big"
									{...register('QualConvenio')}
								/>
								<TextField
									required
									label="Datas e horários sugeridos para o evento"			
									className="input-big"
									style={btnRein}
									{...register('DataHrEvento')}
								/>
								<TextField
									required
									label="Tema do Evento"			
									className="input-big"
									{...register('TemaEvento')}
								/>
								
								<TextField
									required
									label="Profissional/área do São Camilo de interesse"		
									className="input-big"
									style={btnRein}
									{...register('ProfissionalIbcc')}
				
								/>
								<TextField
									required
									label="Objetivo da Participação"			
									className="input-big"
									{...register('ObjParticipacao')}
								/>
								<TextField
									required
									label="Média de público do evento"			
									className="input-big"
									{...register('MediaPubEvento')}
								/>
								<FormControl className="input-small" >
									
									<InputLabel required id="demo-controlled-open-select-label">
										Haverá disponibilização de transporte?
										
									</InputLabel>
									<Select
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										{...register('Transporte')}
										style={btnRein}
									>
										<MenuItem value="Sim">SIM</MenuItem>
										<MenuItem value="Não">NÃO</MenuItem>

									</Select>
                				</FormControl>
								<TextField
									required
									label="Contato do responsável pela solicitação"			
									className="input-big"
									style={btnRein}
									{...register('ContatoResp')}
								/>
								<FormControl className="input-small">
								<TextField
									autoComplete="new-password"
									required
									label="Telefone"
									className="input-big"
									{...register('TelResponsavel')}
								/>
								</FormControl>
								<TextField
									autoComplete="new-password"
									required
									label="E-mail"			
									className="input-big"
									{...register('EmailResp')}
								/>
								<p><strong>Autorização para uso de imagem</strong></p>
								<p><strong>Será permitido registro fotográfico por parte do São Camilo do evento e das pessoas para eventual divulgação em seu site e redes sociais?</strong></p>
								<FormControl className="input-big">
									<Select
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										{...register('RegistroFoto')}
									>
										<MenuItem value="Sim">SIM</MenuItem>
										<MenuItem value="Não">NÃO</MenuItem>

									</Select>
									
                				</FormControl>
								

							</ThemeProvider>
							<ThemeProvider theme={buttonTheme}>
								<Button
									className="div-form-button"
									id="submit-form-button"
									variant="contained"
									color="primary"
									disabled={false}
									// onClick={handleSubmit(onSubmit)}
									component="label">
										Enviar Inscrição
										<input id="submit-form" type="submit" hidden/>
								</Button>
							</ThemeProvider>
						</form>
					</div>
				</div>
			</section>
			{/*
				showAlert ?
					success ?
						<Alert severity="success">
							<AlertTitle>Sucesso</AlertTitle>
						Sua mensagem foi enviada. <strong>Agradecemos o seu contato!</strong>
						</Alert>
						:
						<Alert severity="error">
							<AlertTitle>Erro</AlertTitle>
							<strong>Sua mensagem não foi enviada.</strong> Se o problema persistir favor entrar em contato.
					</Alert>
					:
					null
			}
					<Modal 
						disablePortal
  						disableEnforceFocus
  						disableAutoFocus
						className="modal-loading" open={open}
					>
						<CircularProgress color="inherit" />
			</Modal>*/}
			<Footer />
		</>
	)
}

export default SaoCamiloEmpresas