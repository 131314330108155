import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import './Novidades.style.scss'
import ReactPaginate from 'react-paginate';

import config from '../../helpers/config';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import ArticlePesquisa from '../../components/ArticlePesquisa/ArticlePesquisa.component';

import BannerBlog from '../../images/images/banner/banner_blog.jpg'

import doGet from '../../helpers/request';
  
const sortByDescending = (arr, prop) => {
    return arr.sort((a, b) => toDate(b[prop]) - toDate(a[prop]));
}

function toDate(dateStr) {
    if(dateStr && dateStr.includes("/")) {
        let parts = dateStr.split("/")
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else if(dateStr && dateStr.includes("-")) {
        let parts = dateStr.split("-");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else {
        return new Date();
    }
}

const novidadesInitState = [
    {
        id: "",
        novidade_type: "banner-redirect",
        image: "",
        publish_date: "",
        link: "",
        author: "",
        category: "",
        title: "",
        title_text: "",
        original_text: "",
        createdAt: "",
        updatedAt: ""
    },
]

const initPagination = {
    data: novidadesInitState,
    offset: 0,
    numberPerPage: 5,
    pageCount: 0,
    currentData: []
}

const Novidades = () => {
    let history = useHistory();
    const location = useLocation();
    const [ novidades, setArrayNovidades ] = useState(novidadesInitState);
    const [ stateLoaded, setStateLoaded ] = useState(false);
    const [ pagination, setPagination ] = useState(initPagination);

    useEffect(() => {
        const getAPIData = async () => {
            setArrayNovidades(await doGet(`${config.host}/novidades`));
        }
        getAPIData();
        setTimeout(() => setStateLoaded(true), 800);
    }, [])

    useEffect(() => {
        setPagination((prevState) => ({
            ...prevState,
            pageCount: novidades.filter(el => el.novidade_type === "blog").length / prevState.numberPerPage,
            data: novidades.filter(el => el.novidade_type === "blog"),
            currentData: sortByDescending(novidades, 'publish_date').filter(el => el.novidade_type === "blog").slice(pagination.offset, pagination.offset + pagination.numberPerPage),
        }))
    }, [pagination.numberPerPage, pagination.offset, novidades])
    
    const handlePageClick = event => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage
        setPagination({ ...pagination, offset })
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        history.push(path)
    }

    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="novidades-container">
                <div className="div-novidades-container-bg">
                    <div className="div-novidades-container">
                        <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon"  />
                            </a>
                            <a color="inherit" href="/blog" onClick={() => handleClick("/blog")} className="breadcrumb-link">
                                Blog
                            </a>
                        </Breadcrumbs>
                        <center>
                            <img src={BannerBlog} className="img-banner-blog" alt='Banner Blog'></img>
                        </center>
                        {
                            stateLoaded ?
                                pagination.currentData.filter(novidades =>
                                    (novidades.novidade_type === 'blog')).map((novidade, index) =>
                                        index !== 0 && index % 4 === 0 ?
                                        <>
                                            <ArticlePesquisa dados={novidade} />
                                        </>
                                        :
                                        <>
                                            <ArticlePesquisa dados={novidade} />
                                            <hr />
                                        </>
                                    )
                            : 
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        }
                        <div id="footer-table-page-novidades">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={pagination.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={true}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Novidades