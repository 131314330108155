import React, { useState, useLayoutEffect} from 'react';
import {
	TextField,FormControl, Breadcrumbs, Button,	CircularProgress, Modal
} from '@material-ui/core';
import { grey, red, green } from '@material-ui/core/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import HomeIcon from '@material-ui/icons/Home';
import config from '../../helpers/config';

import './Fornecedores.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import imgCadastroFornecedores from '../../images/images/banner/banner-cadastrofornecedores.jpg'
import imgCadastroFornecedores1 from '../../images/images/banner/banner_gtplan.jpg'

const theme = createMuiTheme({
	palette: {
		primary: grey,
	},
});

const buttonTheme = createMuiTheme({
	palette: {
		primary: green,
		secondary: red
    },
});

const Fornecedores = () => {
	let history = useHistory();
	const location = useLocation();

	const { register, handleSubmit, reset } = useForm();
	const [open, setOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [success, setSuccess] = useState(false);
	const [dateType, setDateType] = useState('text')

	// Scroll to top if path changes
	useLayoutEffect(() => {
        window.scrollTo(0, 0);
  	}, [location.pathname]);

	const onSubmit = (data) => {
		
		setOpen(true);

		fetch(`${config.host}/send-mail/Fornecedores`,
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify({
					...data,
				})
			})
			.then(res => {
				console.log(res.status)

				if(res.status === 200) {
					setOpen(false);
					setSuccess(true);
					setShowAlert(true);
					reset();
				} else {
					setOpen(false);
					setSuccess(false);
					setShowAlert(true);
				}
			})
			.catch(err => {
				setOpen(false);
				setShowAlert(true);
				setSuccess(false);
				console.log(err)
			})
	}

	const handleClick = (path) => history.push(path);

	return (
		<>
			<Header />
			<MenuRapido />
			<section id="cadastro-fornecedores-section">
				<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-cadastro-fornecedores" separator="›">
					<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
						<HomeIcon id="breadcrumb-home-icon" />
					</a>
					<a
						color="inherit"
						href="/cadastro-fornecedores"
						onClick={() => handleClick("/cadastro-fornecedores")}
						className="breadcrumb-link"
					>
						Novos Fornecedores
				</a>
				</Breadcrumbs>
				<div id="form-cadastro-fornecedores-container">
					<center className="cadastro-fornecedores-card-container">
                            <img src={imgCadastroFornecedores} className="img-banner-blog" alt='Novos Fornecedores Banner'></img>
                    </center>
					<div className="cadastro-fornecedores-form">
						<p><h2>Seja um fornecedor ou Prestador de Serviços.</h2></p>
						<p><strong>O Hospital São Camilo procura por novos fornecedores de acordo com suas demandas por serviços e produtos. A seleção, cotação e contratação de fornecedores é feita com base em nossa política de qualificação de fornecedores e prestadores de serviço não médicos e terceiros, respeitando critérios técnicos e outros processos como qualidade, compliance, preço, prazo de entrega e atendimento.</strong></p>
						<p>Se a sua empresa comercializa: Materiais Médicos, Medicamentos, Órteses e Próteses (OPME),  Dietas enterais, Equipamentos cirúrgicos, Gêneros Alimentícios, informática, Material Descartável, Material de escritório, Material gráfico, Rouparia, Tecidos e Enxovais, Uniformes, Epi e entre outros, faça seu cadastro aqui: <a href='https://gtplan.com.br/' target='_blank' rel="noreferrer">https://gtplan.com.br/</a></p>
						<center className="cadastro-fornecedores-card-container">
                            <img src={imgCadastroFornecedores1} className="img-banner-blog" alt='Cadastro fornecedor'></img>
                   		 </center>
							<p>Siga passo a passo e após aprovação, sua empresa participará do processo de compras sempre que houver cotações.</p>
						<p><span className="teste">Se a sua empresa é prestadora de serviços</span> e deseja se tornar um parceiro, favor preencher o formulário abaixo e em breve retornaremos o contato:</p>
						<p>Se você tiver dúvidas, entre em contato conosco no e-mail <a href="mailto:gestaodefornecedores@hospitalsaocamilosp.org.br">gestaodefornecedores@hospitalsaocamilosp.org.br</a></p>
						<form id="form-cadastro-fornecedores" onSubmit={handleSubmit(onSubmit)}>
							<ThemeProvider theme={theme}>
							<span className="form-divisor-fornecedores">Dados da empresa</span>
								<FormControl className="input-small">
								<TextField
									required
									className="input-big"
									label="Razão Social"
									{...register('RazaoSocial')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									required
									label="Nome Fantasia"
									className="input-big"
									{...register('NomeFantasia')}
								/>
								</FormControl>
								<InputMask
									{...register('CNPJ')}
									mask="99.999.999/9999-99"
									maskChar=" "
								>
									{() => <TextField className="input-big" required label="CNPJ" {...register('CNPJ')} />}
								</InputMask>
								<FormControl className="input-small">
								<TextField
									label="Inscrição Estadual"
									className="input-big"
									{...register('InscricaoEstadual')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									label="Inscrição Municipal"
									className="input-big"
									{...register('InscricaoMunicipal')}
								/>
								</FormControl>
								<TextField
									required
									type={dateType}
									onFocus={()=>setDateType("date")}
									label="Data de Fundação"			
									className="input-big"
									{...register('DatadeFundacao')}
								/>
								<TextField
									required
									label="Endereço Completo"			
									className="input-big"
									{...register('EnderecoCompleto')}
								/>
								<TextField
									
									label="Registro da Empresa no CREA"
									className="input-big"
									{...register('CREA_empresa')}
								/>
								<span className="form-divisor-fornecedores">Dados do Responsável Técnico</span>
								<FormControl className="input-small">
								<TextField
									required
									label="Nome Completo"
									className="input-big"
									{...register('ResponsavelTecnico')}
								/>
								</FormControl>
								<FormControl className="input-small">
								<TextField
									required
									label="Nº CREA "
									className="input-big"
									{...register('CREA_ResponsavelTecnico')}
								/>
								</FormControl>
								<span className="form-divisor-fornecedores">Dados para contato</span>
								<TextField
									required
									label="Nome para contato"
									className="input-big"
									{...register('Nome')}
								/>
								<InputMask
									{...register('Tel')}
									mask="(99) 9999-9999"
									maskChar=" "
								>
									{() => <TextField className="input-big" required label="Telefone" {...register('Tel')} />}
								</InputMask>
								<InputMask
									{...register('Cel')}
									mask="(99) 99999-9999"
									maskChar=" "
								>
									{() => <TextField className="input-big" required label="Celular" {...register('Cel')} />}
								</InputMask>
								<TextField
									required
									type='email'
									label="E-mail"
									className="input-big"
									{...register('Email')}
								/>
								<span className="form-divisor-fornecedores">Histórico da Empresa</span>
								<TextField
									required
									label="Insira um breve histórico de sua empresa"
									className="input-big"
									{...register('Historico')}
								/>
								<TextField
									required
									label="Insira (MÍNIMO 3) atividades anteriores realizadas em estabelecimentos de serviços de saúde"
									className="input-big"
									{...register('Experiencias')}
								/>
							</ThemeProvider>
							<ThemeProvider theme={buttonTheme}>
								<Button
									className="div-form-button"
									id="submit-form-button"
									variant="contained"
									color="primary"
									// onClick={handleSubmit(onSubmit)}
									component="label">
										Enviar
										<input id="submit-form" type="submit" hidden/>
								</Button>
							</ThemeProvider>
						</form>
					</div>
				</div>
			</section>
			{
				showAlert ?
					success ?
						<Alert severity="success">
							<AlertTitle>Sucesso</AlertTitle>
						Sua mensagem foi enviada. <strong>Agradecemos o seu contato!</strong>
						</Alert>
						:
						<Alert severity="error">
							<AlertTitle>Erro</AlertTitle>
							<strong>Sua mensagem não foi enviada.</strong> Se o problema persistir favor entrar em contato.
					</Alert>
					:
					null
			}
					<Modal 
						disablePortal
  						disableEnforceFocus
  						disableAutoFocus
						className="modal-loading" open={open}
					>
						<CircularProgress color="inherit" />
					</Modal>
			<Footer />
		</>
	)
}

export default Fornecedores