import React from 'react';
import menuCentralList from '../menuCentralList';

import './MenuCentralTab.style.scss'

const MenuCentralTab = () => {
    return (
        <>
            <div className="containerTabs">
                {menuCentralList.map((tab, i) => (
                    <div className="tab" key={tab.id}>
                        <a className="titulo-tab" href={tab.href} target={tab.target}>
                            <div className="titulo-tab elemento icone">
                                <img className="titulo-tab elemento icone img" src={tab.title_image} alt={tab.title_image_alt} />
                            </div>
                            <div className="titulo-tab elemento titulo">{tab.title}</div>
                        </a>
                    </div>
                ))}
            </div>
        </>
    )
}
export default MenuCentralTab