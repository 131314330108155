import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import './CanalColaborador.style.scss'

import logoHSC from '../../images/logos/logodefault.png';
import BannerMobile from './images/banner3.jpg';
import BannerDesktop from './images/banner2_1500x500.jpg';
import MessageImg from './images/mensagem-img.png';
import IconeDiretoria from './images/icone-diretoria.png';
import ElogioImg from './images/elogio-img.png';
import ClickIcon from './images/click-img.png';

import { SocialMOCK, linksMOCK } from './LPData';

const CanalColaborador = () => {
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    const currentURL = window.location.pathname;
    
    console.log(currentURL);

    if(currentURL === "/canal-do-colaborador") {
        document.title = 'Canal do Colaborador - HSC';
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <section id='header'>
                <a href="/">
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </section>

            {isMobile ? <img src={BannerMobile} className='banner' alt='Banner Canal Colaborador - HSC' /> : <img src={BannerDesktop} className='banner' alt='Banner Canal Colaborador - HSC' />}

            

            <div className='welcome'>
                <hr />
                
                <h3>Seja bem-vindo! Este é um canal direto para facilitar sua comunicação com o RH.</h3>
            </div>

            <section id='mensagem'>
                <div className='message-text-wrapper'>
                    <h4 className='message-text-title'>Quer deixar uma mensagem?</h4>

                    <p className='message-text-content'>Você pode se manifestar com:</p>

                    <ul className='message-text-list'>
                        <li className='message-text-list-item'>Elogios</li>
                        <li className='message-text-list-item'>Sugestões</li>
                        <li className='message-text-list-item'>Dúvidas</li>
                        <li className='message-text-list-item'>Informações</li>
                    </ul>

                    <p className='message-text-content'>As mensagens são enviadas diretamente ao RH e são tratadas individualmente.</p>

                    <a className='message-text-button' href='https://forms.office.com/pages/responsepage.aspx?id=g1xOAA1Vm0qdgwpLCoNv-ZegZFo29ONEv2x424UoHlxUNU9aOUdZVzNYRFE2SVU5MFVBQ1c5NlNXNS4u'> <span style={{fontWeight: 'bold'}}>Clique Aqui</span> e selecione o objetivo de sua mensagem</a>

                    <p className='message-text-footer'>Atenção: Se você deseja realizar uma denúncia, deve utilizar o Canal de Ética e Compliance, que está disponível <a style={{color: '#000', fontWeight: 'bold'}} href="/compliance" target='_blank' rel="noreferrer">clicando aqui.</a></p>
                </div>
                
                <img className='message-img' src={MessageImg} alt="Envie uma mensagem!" />
            </section> 

            <section id='diretoria'>
                <img className='diretoria-img' src={IconeDiretoria} alt="Diretoria" />
                
                <div className='diretoria-text-wrapper'>
                    <h4 className='diretoria-text-title'>Envie sua mensagem para a Diretoria</h4>

                    <p className='diretoria-text-content'>
                        Sua mensagem será encaminhade forma confidencial diretamente ao Diretor da Rede de Hospitais São Camilo de SP.
                    </p>

                    <a className='diretoria-text-button' href='https://forms.office.com/r/3WNgbPip55'> <span style={{fontWeight: 'bold'}}>Clique Aqui</span> e envie a sua mensagem</a>
                </div>
            </section>

            <section id='elogio'>
                <img className='elogio-img' src={ElogioImg} alt="Envie um elogio!" />

                <div className='elogio-text-wrapper'>
                    <h4 className='elogio-text-title'>Elogio é bom e todo mundo gosta!</h4>

                    <p className='elogio-text-content'>Reserve um tempo do seu dia para reconhecer um colega que te inspira ou que faz a diferença no seu dia.</p> 

                    <a className='elogio-text-button' href='/elogios' target='_blank' rel="noreferrer">Quero elogiar!</a>
                </div>
            </section>

            <section id='links-col'>
                <h4 className='links-title'>Links úteis</h4>

                <div className='links-wrapper'>
                    {linksMOCK.map(link => (
                        <a className='link' href={link.href} target='_blank' key={link.id} rel="noreferrer">{link.name}</a>
                    ))}
                </div>

                <img className='links-icon' src={ClickIcon} alt="Envie um elogio!" />
            </section>                

            <section className='footer-cdc'>
                <p>Vamos <span>juntos,</span></p>
                <p>vamos <span>com o coração,</span></p>
                <p>vamos <span>além do esperado</span></p>

                <div className='footer-info-wrapper'>
                    <a href="/" >
                        <img className='logo' src={logoHSC} alt="Logo HSC" />
                    </a>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>

                    <div className='social'>
                        {SocialMOCK.map(link => 
                            <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                                <img className='social-item-img' src={link.Image} alt={link.name} />
                            </a>
                        )}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CanalColaborador;