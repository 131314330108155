import React, {useLayoutEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CardMedia, Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import './EspecialidadesMedicas.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import especialidadesMedicasData from '../EspecialidadeMedica/especialidadesMedicasData.js'

const especialidadesMedicasSorted = especialidadesMedicasData.sort((a, b) => { return a.nome.localeCompare(b.nome) })

const EspecialidadesMedicas = () => {
	let history = useHistory();
  const location = useLocation();

	// Scroll to top if path changes
	useLayoutEffect(() => {
        window.scrollTo(0, 0);
  }, [location.pathname]);

	const handleClick = (path) => history.push(path);

  return (
    <>
    <Header/>
		<MenuRapido/>
		<section id="especialidades-medicas-section">
			<div id="especialidades-medicas-content">
				<div id="especialidades-container-header">
					<Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
						<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
							<HomeIcon id="breadcrumb-home-icon" />
						</a>
						<a color="inherit" href="/especialidades-por-unidades" onClick={() => handleClick("/especialidades-por-unidades")} className="breadcrumb-link" >
							Especialidades Médicas
						</a>
					</Breadcrumbs>
					<TituloPrincipal titulo="Especialidades Médicas" />
				</div>
				<div id="especialidades-container">
					{especialidadesMedicasSorted.map(el => 
							<div 
								className="card-especialidade-medica" 
								key={el.nome}
								onClick={() => handleClick(`/mkt-23042024/especialidades/${el.nomeUrl}`)}
							> 
								<CardMedia 
									className="card-especialidade-medica-image"
									image={el.imagemBanner ? el.imagemBanner : el.imagem}
									alt={el.alt}
								/>
								<span className="card-especialidade-medica-title">
									<p><strong>{el.nome}</strong></p>
								</span>
							</div>
						)
					}
				</div>
			</div>
		</section>
		<Footer/>
    </>
  )
}

export default EspecialidadesMedicas