import linkedin from './images/linkedin.png'
import facebook from './images/facebook.png'
import instagram from './images/instagram.png'
import spotify from './images/spotify.png'
import youtube from './images/youtube.png'
import Elogio1 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-01.jpg'
import Elogio2 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-02.jpg'
import Elogio3 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-03.jpg'
import Elogio4 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-04.jpg'
import Elogio5 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-05.jpg'
import Elogio6 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-06.jpg'
import Elogio7 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-07.jpg'
import Elogio8 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-08.jpg'
import Elogio9 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-09.jpg'
import Elogio10 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-10.jpg'
import Elogio11 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-11.jpg'
import Elogio12 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-12.jpg'
import Elogio13 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-13.jpg'
import Elogio14 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-14.jpg'
import Elogio15 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-15.jpg'
import Elogio16 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-16.jpg'
import Elogio17 from './images/elogios/LinkedIn-Elogiar-Reconhecer-Colega-17.jpg'

export const SocialMOCK = [
    {
        id: '1',
        name: 'Instagram',
        href: 'https://www.instagram.com/hospitalsaocamilosp/?hl=pt-br',
        Image: instagram
    },
    {
        id: '2',
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/hospitalsaocamilosp/?originalSubdomain=br',
        Image: linkedin
    },
    {
        id: '3',
        name: 'Youtube',
        href: 'https://www.youtube.com/user/RedeSaoCamiloSP',
        Image: youtube
    },
    {
        id: '4',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/4Ov84RYyq1XTHqxH8soaPj?si=uoyQg3YTR-m8tezKnZxexg',
        Image: spotify
    },
    {
        id: '5',
        name: 'Facebook',
        href: 'https://www.facebook.com/hospitalsaocamilosp/',
        Image: facebook
    },
]

export const elogiosMock = [
    {
        nome: 'Elogio1',
        img: Elogio1,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-01.jpg'
    },
    {
        nome: 'Elogio2',
        img: Elogio2,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-02.jpg'
    },
    {
        nome: 'Elogio3',
        img: Elogio3,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-03.jpg'
    },
    {
        nome: 'Elogio4',
        img: Elogio4,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-04.jpg'
    },
    {
        nome: 'Elogio5',
        img: Elogio5,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-05.jpg'
    },
    {
        nome: 'Elogio6',
        img: Elogio6,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-06.jpg'
    },
    {
        nome: 'Elogio7',
        img: Elogio7,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-07.jpg'
    },
    {
        nome: 'Elogio8',
        img: Elogio8,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-08.jpg'
    },
    {
        nome: 'Elogio9',
        img: Elogio9,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-09.jpg'
    },
    {
        nome: 'Elogio10',
        img: Elogio10,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-10.jpg'
    },
    {
        nome: 'Elogio11',
        img: Elogio11,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-11.jpg'
    },
    {
        nome: 'Elogio12',
        img: Elogio12,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-12.jpg'
    },
    {
        nome: 'Elogio13',
        img: Elogio13,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-13.jpg'
    },
    {
        nome: 'Elogio14',
        img: Elogio14,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-14.jpg'
    },
    {
        nome: 'Elogio15',
        img: Elogio15,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-15.jpg'
    },
    {
        nome: 'Elogio16',
        img: Elogio16,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-16.jpg'
    },
    {
        nome: 'Elogio17',
        img: Elogio17,
        url: 'https://hospitalsaocamilosp.org.br/arquivos/LinkedIn-Elogiar-Reconhecer-Colega-17.jpg'
    },    
]