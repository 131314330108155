import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './Cookies.style.scss';

export default function Cookies() {
    const [showCookiesModal, setShowCookiesModal] = useState(true);

    useEffect(() => {
        const isFirstAccess = localStorage.getItem('cookies-contract');
        if(isFirstAccess !== null) setShowCookiesModal(false)
    }, [])

    const setPrivacyContractState = (userResponse) => {
        localStorage.setItem('cookies-contract', userResponse)
        setShowCookiesModal(false)
    }

    let history = useHistory();
    const handleClick = path => history.push(path)

    return(
        <>
        {
            showCookiesModal ? 
            <div className="cookies-modal">
                <div className="cookies-p-container">
                    <p className="cookies-modal-p">
                        Cookies e outras tecnologias semelhantes são utilizados para melhorar a sua experiência em nossos serviços, personalizar publicidade e recomendar conteúdo de seu interesse. Ao aceitar a política de privacidade de nossos serviços, você concorda com tal monitoramento.
                        <p className="anchor-underlined-red-left" 
                        onClick={() => handleClick("/duvidas-frequentes/lgpd")}
                        > Saiba mais</p>
                    </p>
                </div>
                <div className="cookies-button-container">
                    <button className="cookies-button" onClick={() => setPrivacyContractState(true)}>Aceitar</button>
                    <button className="cookies-button1" onClick={() => setPrivacyContractState(false)}>Recusar</button>
                </div>
            </div>
           : 
            null
        }
        </>
    )
}