import React, { useLayoutEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import { useHistory, useParams, useLocation } from 'react-router-dom';

import './Institucional.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import parse from 'html-react-parser';

import institucionalData from './institucionalData'

const Institucional = () => {
    let history = useHistory();
    const { title } = useParams();
    const location = useLocation();
    const institucionalFiltered = institucionalData.find(el => el.nomeUrl.toLowerCase() === title);

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        history.push(path);
    }

    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="institucional-container">
                <div className="div-institucional-container-bg">
                    <div className="div-institucional-container">
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <div color="inherit" className="breadcrumb-link">
                                Institucional
                            </div>
                        </Breadcrumbs>
                        <TituloPrincipal titulo={institucionalFiltered.nome} />
                        {/* {
                        institucionalFiltered.nomeUrl !== "linha-do-tempo" ?
                            <div className="carousel-container">
                                <Carousel id="carousel">
                                    {
                                        institucionalFiltered.imagens.map((element) =>
                                            <CardMedia
                                                className="image"
                                                image={element.imagem}
                                                alt={element.alt}
                                            />
                                        )
                                    }
                                </Carousel>
                            </div>
                        :
                        <></>
                        } */}
                        <div className="institucional-description-container">
                            {parse(institucionalFiltered.descricao)}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Institucional