import React from 'react';
import { useMediaQuery } from 'react-responsive';
import MenuCentralTab from './MenuCentralTab/MenuCentralTab.component';

import './MenuCentral.style.scss'

const MenuCentral = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
    return (
        <>
        {
            !isMobile ? 
            <section id="menu-central-tab">
                <MenuCentralTab />
            </section>
            :
            <section id="menu-central-accordion">
                <MenuCentralTab />
            </section>
        }
        </>
    )
  }
export default MenuCentral