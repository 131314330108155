import linkedin from './images/linkedin.png'
import facebook from './images/facebook.png'
import instagram from './images/instagram.png'
import spotify from './images/spotify.png'
import youtube from './images/youtube.png'
import MedicoPacienteConversa from './images/paciente-medico-conversa.png';
import EnfermeiraRaioX from './images/enfermeira-raiox.png';
import EnfermeiraPaciente from './images/paciente-enfermeira.png';
import Enfermeiros from './images/enfermeiros.png';

export const SocialMOCK = [
    {
        id: '1',
        name: 'Instagram',
        href: 'https://www.instagram.com/hospitalsaocamilosp/?hl=pt-br',
        Image: instagram
    },
    {
        id: '2',
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/hospitalsaocamilosp/?originalSubdomain=br',
        Image: linkedin
    },
    {
        id: '3',
        name: 'Youtube',
        href: 'https://www.youtube.com/user/RedeSaoCamiloSP',
        Image: youtube
    },
    {
        id: '4',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/4Ov84RYyq1XTHqxH8soaPj?si=uoyQg3YTR-m8tezKnZxexg',
        Image: spotify
    },
    {
        id: '5',
        name: 'Facebook',
        href: 'https://www.facebook.com/hospitalsaocamilosp/',
        Image: facebook
    },
];

export const Principles = [
    {
        id: 1,
        name: 'Autonomia',
        Image: MedicoPacienteConversa,
        text: `<strong>Autonomia é ter uma escolha respeitada.</strong> <br/> Em Bioética, quando presente uma relação entre a vida e uma necessária intervenção dos homens sobre ela, a pessoa que necessita da intervenção <strong>precisa ser considerada de acordo com suas particularidades.</strong> Precisa ser esclarecida sobre sua situação em saúde; ter ciência sobre o que pode lhe acontecer no futuro por conta dela; conhecer quais são os tratamentos e as alternativas disponíveis para que o que vivencia e, depois destas etapas, ser respeitada com relação à sua opção.`
    },
    {
        id: 2,
        name: 'Beneficiência',
        Image: EnfermeiraRaioX,
        text: `A Beneficiência, como um Princípio da Bioética, determina que todos os atos médicos a serem seguidos <strong>precisam considerar o que for mais benéfico aos seus pacientes.</strong>`
    },
    {
        id: 3,
        name: 'Não-Maleficência',
        Image: EnfermeiraPaciente,
        text: `A Não-Maleficência, compreendida como um doa princípios da Bioética, determina que os médicos estão <strong>proibidos de causar qualquer dano intencional ao seus pacientes.</strong>`
    },
    {
        id: 4,
        name: 'Justiça',
        Image: Enfermeiros,
        text: `Quando presente uma relação entre a vida euma necessária intervenção dos homens sobre ela, deve-se observar que a distribuição dos serviços de saúde a ser feita precisa ocorrer de forma justa, <strong>havendo igualdade de tratamento para todos os indivíduos.</strong>`
    },    
]