import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import logoHSC from '../../images/logos/logodefault.png';
import DownloadIcon from './images/download-icon.png'
import DoencasFevRoxo from '../../images/images/conteudos-especiais/fevereiro-roxo/doencas-fev-roxo.png'
import Alzheimer from '../../images/images/conteudos-especiais/fevereiro-roxo/alzheimer.png'
import Fibromialgia from '../../images/images/conteudos-especiais/fevereiro-roxo/fibromialgia.png'
import ImgDados from '../../images/images/conteudos-especiais/fevereiro-roxo/imagem-dados.png'
import ImgSenhora from '../../images/images/conteudos-especiais/fevereiro-roxo/imagem-senhora.png'
import Lupus from '../../images/images/conteudos-especiais/fevereiro-roxo/lupus.png'
import MaoCoracao from '../../images/images/conteudos-especiais/fevereiro-roxo/mao-coracao.png'

import './FevereiroRoxo.style.scss'
import { SocialMOCK } from './LPData';

const FevereiroRoxo = () => {
    const location = useLocation();

    const downloadPage = () => {
        window.print()
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <div className='header'>
                <div className='header-main'>
                    <a href="/">
                        <img className='logo' src={logoHSC} alt="Logo HSC" />
                    </a>

                    <button className='button-download' onClick={downloadPage}><img src={DownloadIcon} className='download-icon' alt="Download" />DOWNLOAD</button>
                </div>

                <h1 className='title1'>Fevereiro</h1>
                <h1 className='title2'><strong>Roxo:</strong></h1>

                <img className='doencas-img' src={DoencasFevRoxo} alt="Doenças fevereiro roxo" />

                <div className='faixa-branca'>
                    <p className='faixa-branca-texto'>São doenças <strong>crônicas.</strong></p>
                </div>

                <div className='subtitulo-wrapper'>
                    <p className='subtitulo'>Cuide no presente.</p>

                    <div className='subtitulo-destaque'>Seu futuro agradece.</div>
                </div>
               
            </div>

            <div className='dados'>
                <img src={ImgSenhora} alt="Senhora passndo por consulta" className='img-senhora' />

                <img src={ImgDados} alt="Dados Fevereiro Roxo" className='img-dados' />
            </div>

            <div className='texto-explicativo'>
                <div className='textura'>
                        <p className='textura-texto'>
                            Durante o mês de fevereiro, a área da saúde reúne esforços para a divulgação da campanha Fevereiro Roxo com o 
                            objetivo de conscientizar a população sobre a importância do diagnóstico precoce para as doenças de 
                            <strong style={{color: '#6E3269'}}> Alzheimer, Fibromialgia e Lúpus.</strong>
                            <br /><br />
                            É importante conhecer principais sintomas de cada uma das enfermidades, e permanecer alerta, caso necessário, ao identificar qualquer 
                            sinal diferente, procure por um médico especialista.
                        </p>

                        <img src={MaoCoracao} alt="Mão e coração" className='img-maocoracao' />
                </div>

                <div className='mensagem-destaque'>
                    Cuide no presente. Seu futuro agradece.  
                </div>
            </div>

            <div className='doencas-title-container'>
                <h2 className='doencas-title'>Definição e Sintomas</h2>
                <hr style={{border: '0.5px solid #F9B02E', width: '40%', alignSelf: 'center'}}/>
            </div>

            <div className='alzheimer-main'>
                <div className='doencas-nome'>
                    <p style={{margin: '3px 0 0 10px'}}>Alzheimer</p>
                </div>

                <div className='div-img-texto'>
                    <img src={Alzheimer} alt="cérebro" className='doenca-img' />
                    
                    <p className='doenca-texto'>
                        Forma mais comum de demência neurodegenerativa em pessoas de idade. A causa é desconhecida, 
                        mas acredita-se que seja geneticamente determinada. 
                    </p>
                </div>

                <div className='bullets-container'>
                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Falta de memória para acontecimentos recentes;</li>
                            <li>• Repetição da mesma pergunta várias vezes;</li>
                            <li>• Dificuldade para acompanhar conversações ou pensamentos complexos;</li>
                            <li>• Incapacidade de elaborar estratégias para resolver problemas;</li>
                        </ul>
                    </div>

                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Dificuldade para encontrar palavras que exprimam ideias ou sentimentos pessoais</li>
                            <li>• Irritabilidade, desconfiança injustificada, agressividade, passividade, interpretações erradas de estímulos visuais ou auditivos, tendência ao isolamento.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='fibromialgia-main'>
                <div className='doencas-nome'>
                    <p style={{margin: '3px 0 0 10px'}}>Fibromialgia</p>
                </div>

                <div className='div-img-texto'>
                    <img src={Fibromialgia} alt="fibromialgia" className='doenca-img' />
                    
                    <p className='doenca-texto'>
                        Síndrome, de caráter crônico e etiologia desconhecida, caracterizada por dor musculoesquelética que afeta várias áreas do corpo.
                    </p>
                </div>

                <div className='bullets-container'>
                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Dor por mais de três meses em todo ou qualquer parte do corpo;</li>
                            <li>• Presença de pontos dolorosos na musculatura (18 pontos pré-estabelecidos);</li>
                            <li>• Alteração do sono e fadiga;</li>
                        </ul>
                    </div>

                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Quadro de depressão ou ansiedade;</li>
                            <li>• Alterações do hábito intestinal;</li>
                            <li>• Alterações cognitivas, como falta de memória ou concentração.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='lupus-main'>
                <div className='doencas-nome'>
                    <p style={{margin: '3px 0 0 10px'}}>Lúpus</p>
                </div>

                <div className='div-img-texto'>
                    <img src={Lupus} alt="lupus" className='doenca-img' />
                    
                    <p className='doenca-texto'>
                        Doença inflamatória crônica de origem autoimune (o próprio organismo ataca órgãos e tecidos).
                    </p>
                </div>

                <div className='bullets-container'>
                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Cansaço e fadiga constantes;</li>
                            <li>• Febre;</li>
                            <li>• Erupções cutâneas avermelhadas, que podem surgir no rosto em formato de borboleta, abrangendo a região que vai da bochecha dos dois lados da face à ponta do nariz;</li>
                        </ul>
                    </div>

                    <div className='bullets-wrapper'>
                        <ul>
                            <li>• Lesões cutâneas que pioram depois de tomar sol;</li>
                            <li>• Dor nas articulações;</li>
                            <li>• Falta de flexibilidade nos músculos;</li>
                            <li>• Inchaço nos membros do corpo.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='agende-main'>
                <p className='agende-text'>Na Rede de Hospitais São Camilo temos profissionais especializados para diagnosticar, tratar e acompanhar sua saúde, afinal,<strong> nossa missão é cuidar da sua vida e valorizá-la.</strong></p>

                <a className='agende-button' href='https://centralpaciente.hospitalsaocamilosp.org.br/acesso' target='_blank' rel='noreferrer'>Agende seu horário</a>
            </div>

            <div className='footer'>
                <a href="/" >
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: 'center', alignItems: 'center'}}>
                    <p style={{color: "#606060", fontSize: "16px"}}>Diretor Médico: Dr. Fernando Pompeu - CRM 125543</p>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>
                </div>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </div>
        </main>
    )
}

export default FevereiroRoxo;