import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

import './MenuRapido.style.scss'

import pacienteIcon from '../../images/icons/menu-rap-paciente.svg'
import medicoIcon from '../../images/icons/menu-rap-medico.svg'
import doctorIcon from '../../images/icons/doctor.svg'
import doctorPortalIcon from '../../images/icons/doctor-portal.svg'
import visitaIcon from '../../images/icons/building.svg'
import orcamentoIcon from '../../images/icons/accounting.svg'
import pacIcon from '../../images/icons/paciente.svg'
import resultadoExamesIcon from '../../images/icons/icon-resultado-exame-red.svg'
import marqueConsultaIcon from '../../images/icons/icon-marque-sua-consulta-red.svg'

import checkingMessageImage from '../../images/images/checkin-girl-message.svg'
import checkingDocMessageImage from '../../images/images/paciente-man-smartphone.svg'

const MenuRapido = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });
    const [isExpansionDivVisible, setIsExpansionDivVisible] = useState(false)
    const [isPacientesContentSelected, setIsPacientesContentSelected] = useState(false)

    const handleVisibility = (isVisible, isPacienteContent) => {
        if (isVisible === false) {
            setIsExpansionDivVisible(true);
            setIsPacientesContentSelected(isPacienteContent)
        } else if (isPacientesContentSelected && isPacienteContent) {
            setIsExpansionDivVisible(false);
        } else if (!isPacientesContentSelected && !isPacienteContent) {
            setIsExpansionDivVisible(false);
        } else {
            setIsPacientesContentSelected(isPacienteContent)
        }
    }

    return (
        isMobile ? <div id="menu-rapido-sticky">
        <div id="menu-rapido-button-container">
            <div
                className={`menu-rapido-button${isPacientesContentSelected && isExpansionDivVisible ? " selected" : ""} `}
                onClick={() => handleVisibility(isExpansionDivVisible, true)}
            >
                <img src={pacienteIcon} alt="Ícone Paciente - HSC" className="menu-rapido-button-icon" />
                <p>Paciente</p>
            </div>
            <div
                className={`menu-rapido-button${!isPacientesContentSelected && isExpansionDivVisible ? " selected" : ""} `}
                onClick={() => handleVisibility(isExpansionDivVisible, false)}
            >
                <img src={medicoIcon} alt="Ícone Médico - HSC" className="menu-rapido-button-icon" />
                <p>Médico</p>
            </div>
        </div>
            <Collapse id="effect-collapse-absolute" direction="left" in={isExpansionDivVisible} mountOnEnter unmountOnExit>
                <div className="menu-rapido-button-expantion">
                    {
                        isPacientesContentSelected ?
                            <div className="expantion-container">
                                <div className="expantion-text-container">
                                    <p>Otimize o seu tempo utilizando as opções abaixo para uma rápida navegação.</p>
                                    <div className="anchor-underlined-red-container">
                                        <a className="anchor-underlined-red" target="_blank" rel="noreferrer" href="https://portalpaciente.hospitalsaocamilosp.org.br/">
                                            <p>
                                                <img className="anchor-img-red" src={pacIcon} alt="Portal do Paciente" />
                                                Portal do Paciente
                                            </p>
                                        </a>
                                        <a className="anchor-underlined-red" href="/resultados-exames">
                                                <p>
                                                    <img className="anchor-img-red" src={resultadoExamesIcon} alt="Resultados de Exames" />
                                                    Resultados de Exames
                                                </p>
                                            </a>
                                            <a className="anchor-underlined-red" href="/agendamento-online">
                                                <p>
                                                    <img className="anchor-img-red" src={marqueConsultaIcon} alt="Marque sua Consulta" />
                                                    Marque sua Consulta
                                                </p>
                                            </a>
                                        <a className="anchor-underlined-red" href="/medicos">
                                            <p>
                                                <img className="anchor-img-red" src={doctorIcon} alt="Encontre seu Médico" />
                                                Encontre seu Médico
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="expantion-image-container">
                                    <img src={checkingMessageImage} alt="Mulher desenhada arremessando um avião de papel - Checkin Menu Rápido" />
                                </div>
                            </div>
                            : //medicos selected
                            <div className="expantion-container">
                                <div className="expantion-text-container">
                                    <p>Otimize o seu tempo utilizando as opções abaixo para uma rápida navegação.</p>
                                    <div className="anchor-underlined-red-container">
                                        <a className="anchor-underlined-red" target="_blank" rel="noreferrer" href="http://portalmedico.hospitalsaocamilosp.org.br/">
                                            <p>
                                                <img className="anchor-img-red" src={doctorPortalIcon} alt="Enviar Mensagem - HSC Paciente" />
                                                Portal Médico
                                            </p>
                                        </a>
                                        <a className="anchor-underlined-red" href="/visita-tecnica">
                                            <p>
                                                <img className="anchor-img-red" src={visitaIcon} alt="Enviar Mensagem - HSC Paciente" />
                                                Visita Técnica
                                            </p>
                                        </a>
                                        <a className="anchor-underlined-red" href="/orcamento">
                                            <p>
                                                <img className="anchor-img-red" src={orcamentoIcon} alt="Enviar Mensagem - HSC Paciente" />
                                                Orçamentos
                                            </p>
                                        </a>
                                        <a className="anchor-underlined-red" href="/institucional/residencia-medica">
                                            <p>
                                                <img className="anchor-img-red" src={doctorPortalIcon} alt="Link Residência Médica" />
                                                Residência Médica
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="expantion-image-container">
                                    <img src={checkingDocMessageImage} alt="Mulher desenhada arremessando um avião de papel - Checkin Menu Rápido" />
                                </div>
                            </div>
                    }
                </div>
            </Collapse>   
    </div> : null     
    )
}

export default MenuRapido