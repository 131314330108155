import React, { useState } from 'react';
import { useHistory } from 'react-router';
import logo from '../../images/logos/logodefault.png';
import { Grow } from '@material-ui/core';
import FAQ from '../../images/icons/duvidas-frequentes.svg'

import './NavbarDesktop.style.scss';

import navbarOptions from '../NavbarMobile/navbarOptions';

export default function NavbarItemMobile() {
  const [ expansionDivVisionInstitucional, setExpansionDivVisionInstitucional ] = useState(false);
  const [ expansionDivVisionUnidades, setExpansionDivVisionUnidades ] = useState(false);
  const [ expansionDivVisionDuvidas, setExpansionDivVisionDuvidas ] = useState(false);
  const [ expansionDivVisionEspecialidades, setExpansionDivVisionEspecialidades ] = useState(false);
  const [ expansionDivVisionPortalPaciente, setExpansionDivVisionPortalPaciente ] = useState(false);
  const [ expansionDivVisionPortalMedico, setExpansionDivVisionPortalMedico ] = useState(false);
  
  let history = useHistory();

	function mouseOverHandle(element, menuOver, isMouseEnter) {
		if(!isMouseEnter) {
			setExpansionDivVisionInstitucional(false)
			setExpansionDivVisionUnidades(false)
			setExpansionDivVisionDuvidas(false)
			setExpansionDivVisionEspecialidades(false)
			setExpansionDivVisionPortalPaciente(false)
			setExpansionDivVisionPortalMedico(false)
			return
		}
		
		if(element.childs.length > 0) {
			if(menuOver === "Unidades" && !expansionDivVisionUnidades) {
				setExpansionDivVisionUnidades(true)
				setExpansionDivVisionDuvidas(false)
				setExpansionDivVisionInstitucional(false)
				setExpansionDivVisionEspecialidades(false)
				setExpansionDivVisionPortalPaciente(false)
				setExpansionDivVisionPortalMedico(false)
				return
			}
			if(menuOver === "Dúvidas Frequentes" && !expansionDivVisionDuvidas) {
				setExpansionDivVisionDuvidas(true)
				setExpansionDivVisionUnidades(false)
				setExpansionDivVisionInstitucional(false)
				setExpansionDivVisionEspecialidades(false)
				setExpansionDivVisionPortalPaciente(false)
				setExpansionDivVisionPortalMedico(false)
				return
			}
			if(menuOver === "Institucional" && !expansionDivVisionInstitucional) {
				setExpansionDivVisionInstitucional(true)
				setExpansionDivVisionDuvidas(false)
				setExpansionDivVisionUnidades(false)
				setExpansionDivVisionEspecialidades(false)
				setExpansionDivVisionPortalPaciente(false)
				setExpansionDivVisionPortalMedico(false)
				return
			}
			if(menuOver === "Especialidades" && !expansionDivVisionEspecialidades) {
				setExpansionDivVisionEspecialidades(true)
				setExpansionDivVisionInstitucional(false)
				setExpansionDivVisionDuvidas(false)
				setExpansionDivVisionUnidades(false)
				setExpansionDivVisionPortalPaciente(false)
				setExpansionDivVisionPortalMedico(false)
				return
			}
			if(menuOver === "Paciente" && !expansionDivVisionPortalPaciente) {
				setExpansionDivVisionPortalPaciente(true)
				setExpansionDivVisionInstitucional(false)
				setExpansionDivVisionDuvidas(false)
				setExpansionDivVisionUnidades(false)
				setExpansionDivVisionEspecialidades(false)
				setExpansionDivVisionPortalMedico(false)
				return
			}
			if(menuOver === "Médico" && !expansionDivVisionPortalMedico) {
				setExpansionDivVisionPortalMedico(true)
				setExpansionDivVisionPortalPaciente(false)
				setExpansionDivVisionInstitucional(false)
				setExpansionDivVisionDuvidas(false)
				setExpansionDivVisionUnidades(false)
				setExpansionDivVisionEspecialidades(false)
				return
			}
		}
	}
  
  function handleElementClicked(element, menuClicked) {
		setExpansionDivVisionInstitucional(false)
		setExpansionDivVisionUnidades(false)
		setExpansionDivVisionDuvidas(false)
		setExpansionDivVisionEspecialidades(false)
		setExpansionDivVisionPortalPaciente(false)
		setExpansionDivVisionPortalMedico(false)
    handleClick(element.href);
  }

  function handleClick(path) {
    history.push(path)
  }

  return (
    <nav>
      <div id="header-navbar-container-desktop">
      	<div id="logo-container-desktop">
      	  <img id="logo-desktop" 
      	    src={logo} 
      	    alt="Hospital São Camilo Logomarca registrada"
      	    onClick={() => handleClick("/")}
      	  />
      	</div>
      	<div id="navbar-items-container-desktop" >
      	{
      	  navbarOptions.map((el) => 
      	    <div key={el.menuTitle + el.href} className={`${el.highlightRed ? 'highlight-red navbar-item-desktop' : 'navbar-item-desktop'} ${el.highlightGrey ? 'highlight-grey navbar-item-desktop' : 'navbar-item-desktop'}`} onMouseEnter={() => mouseOverHandle(el, el.menuTitle, true)} onMouseLeave={() => mouseOverHandle("el", "false", false)}> 
      	    {
      	      el.href !== '' ?
      	        <a href={el.href}>
      	          <p>{el.menuTitle}</p>
      	        </a>
      	      :
				el.menuTitle === 'Dúvidas Frequentes' ? <img src={FAQ} alt='FAQ' className='img-faq' /> :
      	        <p 
					onClick={() => handleElementClicked(el)}
					onMouseEnter={() => mouseOverHandle(el, el.menuTitle, true)}
				>
					{el.menuTitle}			
				</p>
      	    }
						<Grow direction="down" mountOnEnter unmountOnExit
							in={el.menuTitle === "Unidades" ? 
								expansionDivVisionUnidades 
								: el.menuTitle === "Dúvidas Frequentes" ? 
										expansionDivVisionDuvidas
										: el.menuTitle === "Institucional" ?
											expansionDivVisionInstitucional
												: el.menuTitle === "Especialidades" ?
												expansionDivVisionEspecialidades
													: el.menuTitle === "Paciente" ?
													expansionDivVisionPortalPaciente
														: el.menuTitle === "Médico" ?
														expansionDivVisionPortalMedico
												:
												null
								} 
						>
							<div className="expantion-navbar-item-desktop" onMouseLeave={() => mouseOverHandle("el", "false", false)}>
								{
									el.childs.map((element) => 
										element.href!=='' ?
											<a href={element.href} target={element.target}>
													<p style={{fontWeight: `${element.hihglight ? 700 : 400}`}}>{element.menuTitle}</p>
											</a>
									:
											<p>{element.menuTitle}</p>
									)
								}
							</div>
						</Grow>
      		</div>
        )}
      	</div>
      </div>
    </nav>
  )
}