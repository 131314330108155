import React, { useLayoutEffect } from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import { useHistory, useLocation } from 'react-router-dom';
import './UnidadesTriagem.style.scss'
import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';
import imgMarcacao from '../../images/images/marcacao/banner_site.jpg'

const UnidadesTriagem = () => {
    let history = useHistory();
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        path.includes('http') ? window.location.href = path : history.push(path);
    }
    return (
        <main>
            <Header />
           { /*<ModalAgendamento /> */}
            <MenuRapido />
            <section id="unidadesTriagem">
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                    <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                        <HomeIcon id="breadcrumb-home-icon" />
                    </a>
                    <a color="inherit" href="/agendamento-online" onClick={() => handleClick("/agendamento-online")} className="breadcrumb-link">
                    Marcação de consultas
                    </a>
                </Breadcrumbs>
                <TituloPrincipal titulo='Marcação de Consultas' />
                <div className="unidadeTriagem-description-container">
                    <Typography>
                        O Hospital São Camilo disponibiliza através da Central do Paciente um serviço de auto atendimento 
                        em marcação de consultas, atendendo com médicos através de consultas presenciais em nossas unidades.
                        <br/><br/>
                        <strong>Caso você tenha interesse em fazer suas marcações por telefone, ligue para a nossa Central: <a href="tel:+551131726800">(11) 3172-6800</a></strong>
                    </Typography>
                    <br/>
                    <Typography>
                            Para agendamento de consultas e exames é obrigatório informar o número do cartão do convênio e CPF.
                    </Typography>
                </div>
                <div className="cardsTriagemCol-group">
                <div className="cardsTriagem-group">
                    <div className="unidadeTriagem-description-container">
                        <a href='https://centralpaciente.hospitalsaocamilosp.org.br/' target='_blank' rel='noreferrer' >
                            <img src={imgMarcacao} alt="Banner-Marcação" className='img-banner-marcacao'/>  
                        </a>                      
                    </div>                   
                </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default UnidadesTriagem