import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

import './App.css';

import Home from './pages/Home/Home.page';
import InstitucionalPage from './pages/Institucional/Institucional.page';
import UnidadesPage from './pages/Unidades/Unidades.page';
import UnidadesTriagemPage from './pages/UnidadesTriagem/UnidadesTriagem.page';
import NovidadesPage from './pages/Novidades/Novidades.page';
import NovidadesArticle from './pages/NovidadeArticle/NovidadesArticle.page';
import OrcamentoPage from './pages/Orcamento/Orcamento.page';
import FaleConoscoPage from './pages/FaleConosco/FaleConosco.page';
import SaoCamiloEmpPage from './pages/SaoCamiloEmpresas/SaoCamiloEmpresas.page';
import VisitaTecnicaPage from './pages/VisitaTecnica/VisitaTecnica.page';
import EspecialidadesPage from './pages/Especialidades/Especialidades.page';
import EspecialidadesMedicasPage from './pages/EspecialidadesMedicas/EspecialidadesMedicas.page';
import EspecialidadeMedicaPage from './pages/EspecialidadeMedica/EspecialidadeMedica.page';
import ProntoSocorro from './pages/ProntoSocorro/ProntoSocorro.page';
import Compliance from './pages/CanalEtica/Compliance.page';
import Fornecedor from './pages/NovosFornecedores/Fornecedores.page';
import DuvidasFrequentesPage from './pages/DuvidasFrequentes/DuvidasFrequentes.page';
import PlanosDeSaudePage from './pages/PlanosDeSaude/Cobertura.page';
import ElegibilidadeExamePage from './pages/ElegibilidadeExame/Elegibilidade.page';
import MedicosPage from './pages/Medicos/Medicos.page';
import SearchPage from './pages/SearchResults/SearchResults.page';
import ImprensaPage from './pages/Imprensa/Imprensa.page';
import MapaSitePage from './pages/MapaSite/MapaSite.page';
import ListaNovidades from './pages/ListaNovidades/ListaNovidades.page';
import GestaoNovidade from './pages/GestaoNovidade/GestaoNovidade.page';
import LoginPage from './pages/Login/Login.page';
import Servicos from './pages/Servicos/Servicos.page';
import Servico from './pages/Servico/Servico.page';
import ResultadoDeExames from './pages/ResultadoDeExames/ResultadoDeExames.page';
import Eventos from './pages/Eventos/Eventos.page';
// import OutubroRosa from './pages/OutubroRosa/OutubroRosa.page';
import Conveniencias from './pages/Conveniencias/Conveniencias.page';
import CanalColaborador from './pages/CanalColaborador/CanalColaborador.page';
import CanalBioEtica from './pages/CanalBioEtica/CanalBioEtica.page';
import ConteudosEspeciais from './pages/ConteudosEspeciais/ConteudosEspeciais.page.jsx';
import FevereiroRoxo from './pages/FevereiroRoxo/FevereiroRoxo.page.jsx';
import OutubroRosa from './pages/OutubroRosa/OutubroRosa.page.jsx';
import SAC from './pages/SAC/SAC.page.jsx';
import Elogios from './pages/Elogios/Elogios.page.jsx';
import MarcoAzulMarinho from './pages/MarcoAzulMarinho/MarcoAzulMarinho.page.jsx';
import AbrilAzul from './pages/AbrilAzul/AbrilAzul.page.jsx';
import IframeMedicos from './pages/Iframe/Medicos/IframeMedicos.page.jsx';
import IframeCobertura from './pages/Iframe/PlanosDeSaude/IframeCobertura.page.jsx';
import IframeAbrilAzul from './pages/Iframe/AbrilAzul/IframeAbrilAzul.page.jsx';


const history = createHistory()

const App = () => {
  return (
    <Router history={history}>
      <div className="App">
        <Switch>
          <Route exact path="/visita-tecnica" component={VisitaTecnicaPage} />
          <Route exact path="/unidades/centro-medico" component={() => { window.location.href = "/unidades/santana"; return null; }} />
          <Route exact path="/unidades/:unidade" component={UnidadesPage} />
          <Route exact path="/unidades/" component={Home} />
          <Route exact path="/agendamento-online/" component={UnidadesTriagemPage} />
          <Route exact path="/resultados-exames/" component={ResultadoDeExames} />
          <Route exact path="/unidades" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/trabalhe-conosco/cadastro" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/trabalhe-conosco" component={() => { window.location.href = "https://trabalheconosco.vagas.com.br/hospital-sao-camilo-sp"; return null; }} />
          <Route exact path="/sua-saude-agradece/voce-sabia-que-higienizar-o-celular-tambem-e-uma-medida-preventiva-contra-o-novo-coronavirus" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/voce-sabe-o-que-e-a-doenca-da-valvula-do-caracao" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/voce-sabe-a-diferenca-entre-alergia-e-intolerancia-alimentar-(2)" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/voce-fica-irritado-quando-esta-com-fome" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/voce-conhece-os-sintomas-de-apendicite" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/voc%C3%AA-sabe-o-que-%C3%A9-arritmia-card%C3%ADaca" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/vantagens-e-desvantagens-de-ser-m%C3%A3e-aos-20-30-40-e-50-anos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/tudo-o-que-voc%C3%AA-precisa-saber-antes-de-come%C3%A7ar-uma-dieta-vegetariana" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/tres-lanches-saudaveis-para-levar-ao-trabalho" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/tendinite-ou-bursite-voc%C3%AA-sabe-a-diferen%C3%A7a" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/semana-da-enfermagem-2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/se-descolorir-%C3%A9-s%C3%B3-colorir" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/saude-da-mulher-por-que-realizar-exames-periodicos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/saiba-como-prevenir-infec%C3%A7%C3%B5es-causadas-por-bact%C3%A9rias" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/s%C3%ADndrome-do-cora%C3%A7%C3%A3o-partido" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/PublishingImages/se-descolorir-%C3%A9-s%C3%B3-colorir/se_descolorir_e_so_colorir.pdf" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/PublishingImages/se-descolorir-%c3%a9-s%c3%b3-colorir/se_descolorir_e_so_colorir.pdf" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/PublishingImages/cartilha-estamos-em-casa/Cartilha%20Estamos%20em%20Casa_v5.pdf" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/programa-gerar-apoio-e-bem-estar-para-a-colaboradora-gestante-e-lactante" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/principais-doen%C3%A7as-que-afetam-nossa-sa%C3%BAde-bucal" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/pedra-na-vesicula-e-mais-comum-em-mulheres-40+" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/o-que-voc%C3%AA-precisa-saber-sobre-a-mamografia" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/o-que-%C3%A9-afta" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/o-impacto-do-covid-19-em-doen%C3%A7as-cardiovasculares" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/o-conselho-federal-de-medicina-recomenda-a-realizacao-de-cirurgias-bariatricas-e-metabolicas-durante-a-pandemia" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/novo-coronavirus-quando-devo-usar-mascara-de-protecao" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/novo-coronavirus-cuidados-essenciais-para-pacientes-oncologicos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/n%C3%B3dulos-benignos-de-mama-assustam-mas-n%C3%A3o-tenha-medo" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/mitos-e-verdades-sobre-refluxos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/mitos-e-verdades-sobre-gripes-e-resfriados" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/minha-amiga-infectologista-3-duvidas-sobre-a-vacinacao-contra-o-sarampo" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/minha-amiga-alergologista-cuidados-com-a-limpeza-da-casa-para-evitar-doencas-respiratorias" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/melhorar-a-postura-%C3%A9-a-melhor-maneira-de-prevenir-dores-na-coluna" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/medico-fisiatra-quando-consultar" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/manuten%C3%A7%C3%A3o-do-peso-previne-doen%C3%A7as-graves-em-mulheres" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/labio-leporino-como-a-correcao-cirurgica-e-realizada" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/infografico-coronavirus" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/infografico-cancer-de-prostata" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/hospital-sao-camilo-ipiranga-aumenta-capacidade-de-atendimento" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/hipotireoidismo-pode-causar-obesidade" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/hidroterapia-o-que-e-poss%C3%ADvel-tratar-com-piscina-terapeutica" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/h%C3%A9rnia-de-disco" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/filhos-com-dificuldade-de-aprendizagem-pode-ser-dilexia" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/fibromialgia" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/exercicios-fisicos-para-pessoas-obesas-ou-com-sobrepeso" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/estilos-de-vida-inadequados-podem-ser-gatilhos-de-cancer-em-idosos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/enxaqueca-quando-procurar-um-m%C3%A9dico" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/entrevista-bem-estar-dr-jean-gorinchteyn" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/entenda-a-diferen%C3%A7a-entre-dengue-zika-e-chikungunya" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/dor-de-cabeca-quando-procurar-um-medico" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/Documents/info_oleos.pdf" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/desvendando-os-mist%C3%A9rios-da-microcefalia" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/covid-19-realizacao-de-testes-no-sao-camilo" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/conjuntivite-alergica-e-mais-comum-na-primavera" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/conheca-a-trajetoria-de-sao-camilo-de-lellis1" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/como-se-preparar-para-uma-consulta-m%C3%A9dica" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/como-o-isolamento-social-pode-afetar-o-sono" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/como-o-estresse-prejudica-o-cora%C3%A7%C3%A3o" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/como-evitar-a-intoxica%C3%A7%C3%A3o-alimentar-no-ver%C3%A3o" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/como-adaptar-o-sono-nas-viagens-de-f%C3%A9rias" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/comida-traz-felicidade" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/cirurgia-bariatrica-qual-a-importancia-do-psicologo" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/cartilha-estamos-em-casa" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/cancer-de-prostata" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/cancer-de-ovario" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/c%C3%A2ncer-de-tireoide-%C3%A9-mais-comum-em-mulheres" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/c%C3%A2ncer-de-pele-diferen%C3%A7a-entre-carcinoma-e-melanoma" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/bem-me-quero-outubro-rosa" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/aten%C3%A7%C3%A3o-com-as-crian%C3%A7as-nas-f%C3%A9rias" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/as-diferen%C3%A7as-entre-os-%C3%B3leos-de-cozinha-e-seus-benef%C3%ADcios-para-a-sa%C3%BAde" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/as-complicacoes-respiratorias-da-covid-19" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/artrose-e-uma-das-principais-doencas-da-terceira-idade" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/artroplastia-de-quadril_cirurgia-de-protese-de-quadril" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/ansiedade-n%C3%A3o-%C3%A9-frescura" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/andragogia-o-aprendiz-no-centro-do-processo-de-educacao" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/alimentos-que-podem-substituir-o-leite-(c%C3%A1lcio-e-nutrientes)" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/a-import%C3%A2ncia-das-vacinas-para-os-idosos" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/8-duvidas-sobre-doacao-de-medula-ossea" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/5-dicas-para-exercitar-o-c%C3%A9rebro-hoje" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/4-praticas-para-dormir-melhor" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/4-de-fevereiro-%E2%80%93-dia-mundial-de-combate-ao-cancer-%E2%80%93-cancer-de-pulmao-acomete-apenas-fumantes" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/3-receitas-detox-para-se-recuperar-de-exageros-alimentares-na-ceia-de-natal" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/2-de-abril-dia-mundial-de-conscientizacao-do-autismo" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/27-de-novembro-dia-internacional-de-combate-ao-cancer" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/19-de-maio-dia-internacional-de-combate-%C3%A0-hepatite" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=9&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=9&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=9&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=9&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=9&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=8&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=8&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=8&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=8&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=8&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=7&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=7&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=7&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=7&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=7&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=6&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=6&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=6&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=6&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=6&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=5&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=5&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=5&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=5&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=5&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=4&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=4&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=4&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=4&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=4&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2021" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=3&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2021" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=2&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=12&Ano=2015" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=11&Ano=2015" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=10&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=10&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=10&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=10&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=10&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2021" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2020" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2019" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2018" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2017" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Historico&Mes=1&Ano=2016" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Categoria&Nome=Sa%C3%BAde%20do%20Homem" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Categoria&Nome=Sa%C3%BAde%20da%20Mulher" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Categoria&Nome=Especialidades" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/?ver=Categoria&Nome=Alimenta%C3%A7%C3%A3o" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/sua-saude-agradece/" component={() => { window.location.href = "/blog"; return null; }} />
          <Route exact path="/SiteAssets/banner_golpe-2020.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/terapia-intensiva" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/radiologia-intervencionista" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/pronto-socorro/infantil" component={() => { window.location.href = "/pronto-socorro"; return null; }} />
          <Route exact path="/pronto-socorro/adulto" component={() => { window.location.href = "/pronto-socorro"; return null; }} />
          <Route exact path="/servicos/procedimento-cirurgico-particular" component={() => { window.location.href = "/orcamento"; return null; }} />
          <Route exact path="/servicos/internacao" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/hemodinamica" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/terapia-ocupacional" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/servico-social" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/psicossocial" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/psicologia" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/nutricao" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/fonoaudiologia" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/fisioterapia" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/equipe-multidisciplinar/enfermagem" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/guia_paciente-santana.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/guia_paciente-pompeia.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/guia_paciente-ipiranga.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/Cardeneta%20de%20Saude%20do%20Adolescente_final.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/Cardeneta%20de%20Saude%20da%20Pessoa%20Idosa_final.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/Documents/Cardeneta%20de%20Saude%20da%20Pessoa%20Adulta_final.pdf" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/consultas-medicas/planos-saude" component={() => { window.location.href = "/planos-de-saude"; return null; }} />
          <Route exact path="/servicos/consultas-medicas/medicos-credenciados" component={() => { window.location.href = "/medicos"; return null; }} />
          <Route exact path="/servicos/consultas-medicas/especialidades" component={() => { window.location.href = "/especialidades"; return null; }} />
          <Route exact path="/servicos/consultas-medicas" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/check-up" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/centro-de-saude-da-mulher" component={() => { window.location.href = "/especialidades/ginecologia"; return null; }} />
          <Route exact path="/servicos/centro-de-referencia-em-tratamento-de-feridas-e-cuidados-com-a-pele" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/centro-de-prevencao-e-promocao-a-saude" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/centro-cirurgico" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/servicos/banco-de-sangue" component={() => { window.location.href = "/duvidas-frequentes/banco-de-sangue"; return null; }} />
          <Route exact path="/pronto-socorro" component={ProntoSocorro} />
          <Route exact path="/servicos" component={Servicos} />
          <Route exact path="/servicos/:servico" component={Servico} />
          <Route exact path="/compliance" component={Compliance} />
          <Route exact path="/etica" component={() => { window.location.href = "/compliance"; return null; }} />
          <Route exact path="/cadastro-fornecedores/" component={Fornecedor} />
          
          <Route exact path="/portal-das-operadoras" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/planos-de-saude" component={PlanosDeSaudePage} />
          <Route exact path="/pesquisa/:query" component={SearchPage} />
          <Route exact path="/Paginas/inforgrafico-tmo.aspx" component={() => { window.location.href = "/especialidades/transplante-medula-ossea"; return null; }} />
          <Route exact path="/orcamento" component={OrcamentoPage} />
          <Route exact path="/blog/:title" component={NovidadesArticle} />
          <Route exact path="/blog" component={NovidadesPage} />
          <Route exact path="/medicos" component={MedicosPage} />
          {/* <Route exact path="/medicina-diagnostica/resultados-de-exames" component={() => { window.location.href = "https://portalpaciente.hospitalsaocamilosp.org.br/"; return null; }} /> */}
          <Route exact path="/medicina-diagnostica/resultados-de-exames" component={Home} />
          <Route exact path="/medicina-diagnostica/agendar-exames" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/medicina-diagnostica" component={() => { window.location.href = "https://saocamilo.centraldemarcacao.com.br/"; return null; }} />
          <Route exact path="/mapa-do-site" component={MapaSitePage} />
          <Route exact path="/mapa-do-site" component={() => { window.location.href = "/mapa-do-site"; return null; }} />
          <Route exact path="/institucional/:title" component={InstitucionalPage} />
          <Route exact path="/imprensa/tratamento-neuromodulatorio-traz-qualidade-de-vida-a-pacientes-com-epilepsia-refrataria" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/saude-mental-deve-estar-entre-as-prioridades-de-2021-alertam-especialistas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/retomada-de-servicos-eletivos-reforca-cuidados-com-a-saude-do-idoso" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/por-que-o-coronavirus-e-mais-perigoso-para-os-idosos-1" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/os-niveis-de-colesterol-e-suas-influencias-para-o-agravamento-da-covid-19" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/novo-protocolo-de-insuficiencia-cardiaca-do-sao-camilo-qualifica-tratamentos-e-reduz-riscos-de-agravamento" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/nota-de-esclarecimento-sobre-golpe" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/nao-existe-formula-magica-combate-a-obesidade-requer-atencao-multidisciplinar" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-promove-1-workshop-cirurgias-mais-seguras" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-ilumina-suas-fachadas-em-apoio-ao-outubro-rosa" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-ilumina-suas-fachadas-em-apoio-ao-dezembro-laranja" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-entrega-novos-leitos-para-tratamento-oncologico-em-sp" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-comemora-marco-de-2-mil-recuperados-de-covid-19" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-alerta-para-importancia-de-exames-periodicos-a-saude-da-mulher" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-adere-a-campanha-setembro-amarelo-iluminando-suas-fachadas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/hospital-sao-camilo-adere-a-campanha-de-combate-a-hepatites-virais-e-ilumina-suas-fachadas-de-amarelo" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/especialista-recomenda-7-habitos-diarios-de-combate-ao-estresse" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/em-apoio-ao-novembro-azul-hospital-sao-camilo-ilumina-suas-fachadas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/em-apoio-ao-mes-da-amamentacao-hospital-sao-camilo-ilumina-suas-fachadas-de-dourado" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/covid-19-vacinacao-trara-normalidade-imediata-a-rotina-das-pessoas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/como-atuam-as-manifestacoes-neurologicas-em-pacientes-com-covid-19" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/cancer-de-prostata-prevencao-ainda-e-o-melhor-caminho" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/cancer-de-mama-como-afastar-esse-mal" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/avancos-na-medicina-ampliam-qualidade-de-vida-de-pacientes-com-mal-de-parkinson" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/aumento-de-doencas-ocupacionais-preocupa-especialistas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/aumento-de-casos-de-estresse-entre-criancas-preocupa-especialistas" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/atividade-fisica-melhora-condicionamento-cardiorrespiratorio-e-auxilia-pessoas-com-asma" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/a-saude-nao-pode-esperar" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/altas-temperaturas-aumentam-riscos-de-doencas-vasculares-e-circulatorias" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/a-importancia-da-vacinacao-mitos-e-verdades" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/a-importancia-da-otimizacao-perioperatoria-no-pos-covid-19" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/imprensa/:title" component={NovidadesArticle} />
          <Route exact path="/imprensa" component={ImprensaPage} />
          <Route exact path="/imprensa" component={() => { window.location.href = "/imprensa"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/visitas-tecnicas" component={() => { window.location.href = "/visita-tecnica"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/responsabilidade-social" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/processo-de-qualidade-assistencial" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/politica-de-qualidade" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/missao-visao-e-valores" component={() => { window.location.href = "/institucional/missao-visao-valores"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/memorial-sao-camilo" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/likepelavida" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/humanizacao" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/hospitais-sao-camilo/historia-e-perfil" component={() => { window.location.href = "/institucional/nossa-historia"; return null; }} />
          <Route exact path="/hospitais-sao-camilo" component={() => { window.location.href = "/institucional/nossa-historia"; return null; }} />
          <Route exact path="/formulario-para-orçamento" component={() => { window.location.href = "/orcamento"; return null; }} />
          <Route exact path="/fale-conosco" component={FaleConoscoPage} />
          <Route exact path="/sao-camilo-empresas" component={SaoCamiloEmpPage} />
          <Route exact path="/especialidades-por-unidades" component={EspecialidadesPage} />
          <Route exact path="/especialidades/transplante-de-medula-ossea/contato-e-equipe-medica" component={() => { window.location.href = "/especialidades/transplante-medula-ossea"; return null; }} />
          <Route exact path="/especialidades/transplante-de-medula-ossea" component={() => { window.location.href = "/especialidades/transplante-medula-ossea"; return null; }} />
          <Route exact path="/especialidades/outras-especialidades" component={() => { window.location.href = "/especialidades"; return null; }} />
          <Route exact path="/especialidades/ortopedia/medicina-esportiva" component={() => { window.location.href = "/especialidades/ortopedia"; return null; }} />
          <Route exact path="/especialidades/gastroenterologia/endoscopia-digestiva-alta-e-baixa" component={() => { window.location.href = "/especialidades/gastroenterologia"; return null; }} />
          <Route exact path="/especialidades/gastroenterologia/cirurgia-bariatrica" component={() => { window.location.href = "/especialidades/gastroenterologia"; return null; }} />
          <Route exact path="/especialidades/cirurgias" component={() => { window.location.href = "/especialidades"; return null; }} />
          <Route exact path="/especialidades/cardiologia/procedimento-endovasculares" component={() => { window.location.href = "/especialidades/cardiologia"; return null; }} />
          <Route exact path="/mkt-23042024/especialidades/:especialidade" component={EspecialidadeMedicaPage} />
          <Route exact path="/mkt-23042024/especialidades/" component={EspecialidadesMedicasPage} />
          <Route exact path="/especialidades/:especialidade" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/especialidades/" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/english" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/elegibilidade-de-exames" component={ElegibilidadeExamePage} />
          <Route exact path="/duvidas-frequentes/:duvida" component={DuvidasFrequentesPage} />
          <Route exact path="/coronavirus" component={() => { window.location.href = "/duvidas-frequentes/coronavirus"; return null; }} />
          <Route exact path="/contato-dpo" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/sobre-o-centro-de-simulacao-e-pesquisa/localizacao-e-contatos" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/sobre-o-centro-de-simulacao-e-pesquisa/links-uteis" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/sobre-o-centro-de-simulacao-e-pesquisa/estrutura" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/sobre-o-centro-de-simulacao-e-pesquisa" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/nucleos/publicacoes" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/nucleos/pesquisas" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/nucleos/eventos" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/links-uteis/normas-para-publicacao.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/documents/comunicacao/NORMAS-I-jornada-de-gerontologia-e-geriatria.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/documents/comunicacao/NORMAS-2-congresso-internacional-de-simulacao-realistica.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2020-02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2020_08.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2020_07.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2020_04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2019-12.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2019_08.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2019_06.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2019_04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2019_02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018-12.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018_10.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018_08.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018_06.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018_04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2018_02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2017-08.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2017-06.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2017-04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2017-02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2017_12.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2016-06.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2016-04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2016-02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2016_10.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-12.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-10.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-08.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-06.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-04.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/boletim-cientifico_2015-02.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/1-BOLETIM-IEP-SP-ABRIL.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/Documents/comunicacao/1-BOLETIM-FEVEREIRO-2013.pdf" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/comunicacao/reunioes-cientificas" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/comunicacao/noticias-e-novidades" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/comunicacao/documentacao-digital" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/comunicacao/boletim-cient%C3%ADfico" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa/agenda-de-eventos" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/centro-de-simulacao-pesquisa" component={() => { window.location.href = "https://saocamilo-sp.br/"; return null; }} />
          <Route exact path="/aviso-coronavirus-laboratorio-apoio" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/acessibilidade" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/unidades/pompeia/sala-de-vacinacao" component={() => { window.location.href = "/servicos/sala-de-vacinacao"; return null; }} />
          <Route exact path="/outubro-rosa" component={() => { window.location.href = "/"; return null; }} />
          <Route exact path="/canal-do-colaborador" component={CanalColaborador} />
          <Route exact path="/elogios" component={Elogios} />
          <Route exact path="/canal-de-bioetica" component={CanalBioEtica} />
          <Route exact path="/conveniencias" component={Conveniencias} />
          <Route exact path="/sac" component={SAC} />
          <Route exact path="/duvidas-frequentes/sac" component={() => { window.location.href = "/sac"; return null; }} />
          <Route exact path="/admin/lista-novidades" component={ListaNovidades} />
          <Route exact path="/admin/lista-novidades/gestao" component={GestaoNovidade} />
          <Route exact path="/admin/lista-novidades/gestao/:id" component={GestaoNovidade} />
          <Route exact path="/admin/login" component={LoginPage} />
          <Route exact path="/eventos" component={Eventos} />
          <Route exact path="/conteudos-especiais" component={ConteudosEspeciais} />
          <Route exact path="/conteudos-especiais/fevereiro-roxo" component={FevereiroRoxo} />
          <Route exact path="/conteudos-especiais/outubro-rosa" component={OutubroRosa} />
          <Route exact path="/conteudos-especiais/marco-azul-marinho" component={MarcoAzulMarinho} />
          <Route exact path="/conteudos-especiais/abril-azul" component={AbrilAzul} />
          <Route exact path="/iframe/medicos" component={IframeMedicos} />
          <Route exact path="/iframe/planos" component={IframeCobertura} />
          <Route exact path="/iframe/lp-abril-azul" component={IframeAbrilAzul} />
          <Route exact path="/" component={Home} />
          <Route component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;