import imgServicosProntoSocorro from '../../images/images/servicos/pronto-socorro/banner-compliance.webp'

const complianceData = [
    {
        nome: 'Compliance',
        nomeUrl: 'compliance',
        descricao: `<p><img style="width: 100%" src=${imgServicosProntoSocorro}></p>A Rede de Hospitais São Camilo de São Paulo, sempre observando os preceitos cristãos, cuida de forma cativa para aplicar os deveres éticos na assistência à saúde, em seu sentido mais abrangente.&nbsp;<br /> &nbsp;<br /> O <strong>Compliance</strong> faz parte do <strong>Programa de Integridade</strong> da instituição que zela pela conformidade de condutas, princípios e valores camilianos.<br /> &nbsp;<br /> Objetivo: receber relatos ou denúncias de situações relacionadas com a ética, transparência ou demais condutas que não estejam de acordo com nossas políticas, procedimentos e governança.<br /> &nbsp;
        <br><p><a href='https://www.canaldedenuncias.com.br/entidadescamilianas/' target='_blank' rel="noreferrer"><strong>Canal de Denúncias</strong></a></p><p><p>Clique e acesse o <strong><i><a href="https://hospitalsaocamilosp.org.br/arquivos/codigo-conduta-etica.pdf" target='_blank' rel="noreferrer">Código de Conduta Ética das Entidades Camilianas</a></strong></i>. Ele estabelece diretrizes claras para que colaboradores e associados mantenham relações éticas e honestas com todos os públicos de interesse, incluindo clientes, colaboradores, parceiros de negócio, pacientes, alunos, assistidos, prestadores de serviço, comunidade em geral, governo, entre outros. <br /> &nbsp;<br /> O Código de Conduta Ética representa o compromisso de todos os colaboradores e associados das Entidades Camilianas em agir com integridade, respeito, transparência e em conformidade com a legislação brasileira em todas as nossas atividades e interações.</p>
        </p>
        `,
    },
]

export default complianceData