import { apiToken } from './config';

const axios = require('axios');

async function doGet(url) {
    const { data } = await axios.get(url, { 
        headers: {
            hsc_key: apiToken 
        }
    });
    return data;
}

export default doGet;