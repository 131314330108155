import React, { useLayoutEffect } from 'react';
import { CardMedia, Breadcrumbs, Table, TableCell, Paper, TableHead, TableRow, TableBody, TableContainer } from '@material-ui/core';
import { withStyles, makeStyles, } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import parse from 'html-react-parser';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import './Servico.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';
import servicos from './ServicosData.js'

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#C3000A",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    table: {
      minWidth: 300,
    },
  });

const Servico = () => {
    let history = useHistory();
    const location = useLocation();
    const { servico } = useParams();
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
    const servicoFiltered = servicos.find(el => el.nomeUrl.toLowerCase() === servico);
    const classes = useStyles();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => history.push(path);

    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="especialidademedica-container">
                <div className="div-especialidademedica-container-bg">
                    <div className="div-especialidademedica-container">
                        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <a color="inherit" href="/servicos" onClick={() => handleClick("/servicos")} className="breadcrumb-link" >
                                Serviços
                            </a>
                        </Breadcrumbs>
                        <TituloPrincipal titulo={servicoFiltered.nome} />
                        <div className="especialidademedica-card-container">
                            <CardMedia className="especialidademedica-card-container-image" image={servicoFiltered.imagem} alt={servicoFiltered.alt} />
                        </div>
                        <div className="especialidademedica-tab-container">
                            <Tabs defaultActiveKey={servicoFiltered.apresentacao ? "descricao" : "unidades"} id="tab-especialidade">
                                {servicoFiltered.apresentacao ? 
                                 <Tab eventKey="descricao" title="Descrição" tabClassName="especialidademedica-tab-titulo">
                                 <div className="especialidademedica-tab-content">
                                     {parse(servicoFiltered.apresentacao)}
                                 </div>
                                 </Tab> : null}
                                {servicoFiltered.unidades ? 
                                    <Tab eventKey="unidades" title="Unidades" tabClassName="especialidademedica-tab-titulo" >
                                        <div className="especialidademedica-tab-content">
                                            {isMobile ?
                                                <ul id="card-list-mobile"> 
                                                {
                                                    servicoFiltered.unidades
                                                    .map((row, index) => (
                                                        <li 
                                                        key={row.nome}
                                                        className={`row-card-mobile${index % 2 === 0 ? "" : "-odd"}`}
                                                        >
                                                        <p className="title-card-mobile"><strong>{row.subEspecialidade}</strong></p>
                                                        <div className="inline-content-card-mobile">
                                                            <p>
                                                            Ipiranga: {row.ipiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                                                            </p>
                                                            <p>
                                                            Pompeia: {row.pompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                                                            </p>
                                                            <p>
                                                            Santana: {row.santana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                                                            </p>
                                                        </div>
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                                :
                                                <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell id="table-cell-3"><b>Especialidade</b></StyledTableCell>
                                                        <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                                                        <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                                                        <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
                                                    </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {servicoFiltered.unidades.map((row) => (
                                                        <StyledTableRow key={row.subEspecialidade}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {row.subEspecialidade}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">{row.ipiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.pompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.santana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                            }
                                        </div>
                                    </Tab> : null
                                }
                                
                                {servicoFiltered.equipe ? 
                                    <Tab eventKey="equipe" title="Equipe" tabClassName="especialidademedica-tab-titulo">
                                        <div className="especialidademedica-tab-content">
                                        {isMobile ?
                                                <ul id="card-list-mobile"> 
                                                {
                                                    servicoFiltered.equipe?.map((row, index) => (
                                                        <li key={row.nome} className={`row-card-mobile${index % 2 === 0 ? "" : "-odd"}`}>
                                                            {row.nome}
                                                            <br />
                                                            <strong>CRM: </strong>{row.crm}
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                                :
                                                <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell id="table-cell-3"><b>Médico(a)</b></StyledTableCell>
                                                        <StyledTableCell align="center"><b>CRM</b></StyledTableCell>
                                                    </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {servicoFiltered.equipe?.map((row) => (
                                                        <StyledTableRow key={row.nome}>
                                                            <StyledTableCell component="th" scope="row">{row.nome}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.crm}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                            }
                                            <br />
                                            Não encontrou seu médico? Faça uma busca mais ampla <a href="/medicos">clicando aqui</a>.
                                        </div>
                                    </Tab> : null
                                }
                            
                                <br></br>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Servico