import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumbs, CircularProgress } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search';
import parse from 'html-react-parser';
import ReactPaginate from 'react-paginate';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import './SearchResults.style.scss';

import credentials from '../../credentials/googleSearch.json';

const initStateListItem = [{
	title: '',
	link: '',
	htmlFormattedUrl: "",
	htmlSnippet: ""
}]

const initPagination = {
  data: [],
  offset: 0,
  numberPerPage: 10,
  pageCount: 0,
  currentData: []
}

const SearchPage = () => {
	let { query } = useParams();
	let history = useHistory();

	const [ queryToSearch, setQueryToSearch ] = useState(query);
	const [ listSearched, setListSearched ] = useState(initStateListItem);
	const [ totalResults, setTotalResults ] = useState(0)
	const [ isLoading, setIsLoading ] = useState(false);
	const [ pagination, setPagination ] = useState(initPagination);
	const [ error, setError ] = useState (false)
	
	const handleClick = path => history.push(path);
	const handleChange = event => setQueryToSearch(event.target.value);
	
	const handleSearch = async (startIndex) => {
		handleClick(`/pesquisa/${queryToSearch}`)
		setTotalResults(0);
		setListSearched(initStateListItem)
		setIsLoading(true);
		
		fetch(`https://www.googleapis.com/customsearch/v1?key=${credentials.apiKeyGoogleSearch}&cx=${credentials.customSearchEngineID}&q=${queryToSearch}&num=10&start=${startIndex ? startIndex : 1}`)
			.then(response => response.json())
			.then(response => {
				if(response.items) {
					setListSearched([])
					setListSearched([ ...response.items ])
					setTotalResults(response.searchInformation.totalResults);
					setIsLoading(false);

				} else {
					setIsLoading(false);
				}
			})
			.catch(err => {
				setIsLoading(false);
				setError("Ocorreu algum erro na busca. Tente novamente e caso o problema persista entre em contato conosco." + err)
			})
	}

	const firstSearch = () => { 
		setQueryToSearch(queryToSearch);
		handleSearch(1);
	};

	useEffect(() => { 
		firstSearch() 
	}, []) 

	useEffect(() => {
    	setPagination((prevState) => ({
			...prevState,
			pageCount: totalResults / prevState.numberPerPage,
			data: listSearched,
			currentData: listSearched
		}))
	}, [pagination.numberPerPage, listSearched, totalResults])

	const handlePageClick = event => {
    	const selected = event.selected + 1; //pagination component zero index based
			if(selected !== 1) { 
				handleSearch(selected * pagination.numberPerPage - 9)
			} else {
				handleSearch(1)
			}
  	}
	 
	const handleKeypress = e => {
		//it triggers by pressing the enter key
		if (e.charCode === 13) {
			handleSearch(1);
	  	}
	};

  return (
    <>
			<Header />
			<MenuRapido />
			<section id="pesquisa-section">
				<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-pesquisa" separator="›">
					<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
						<HomeIcon id="breadcrumb-home-icon" />
					</a>
					<a
						color="inherit"
						href="/pesquisa"
						onClick={() => handleClick("/pesquisa")}
						className="breadcrumb-link"
					>
						Busca no site
				</a>
				</Breadcrumbs>
				<div id="pesquisa-page-container">
					<TituloPrincipal titulo="Busca no Site" />
					<div id="pesquisa-page-header">
						<input 
							type="text" 
							value={queryToSearch} 
							onChange={handleChange}
							onKeyPress={handleKeypress}
							placeholder="Pesquise aqui" 
							id="search-input-page" 
							maxLength="255"
						/>
						<SearchIcon 
							id="search-icon-page" 
							onClick={() => firstSearch()}
						/>
					</div>
					<div id="pesquisa-page-content-list">
						<p>{error ? error : null}</p>
						{
							isLoading ?
								<CircularProgress color="primary" />
							: null
						}
						{
							!isLoading && listSearched ?
								pagination.currentData.map(el => 
									<div className="card-link-pesquisa" key={el.link}>
										<a href={el.link} target="_blank" rel="noreferrer">
											<h3 className="title-pesquisa-item">
												{el.title}
											</h3>
										</a>
										<a href={el.link} target="_blank" rel="noreferrer">
											<span className="link-pesquisa-item">
												{parse(el.htmlFormattedUrl.replace(/(?:\r\n|\r|\n|<br>)/g, ''))/*remove all line breakers*/} 
											</span>
										</a>
										<p className="snippet-pesquisa-item">
											{parse(el.htmlSnippet.replace(/(?:\r\n|\r|\n|<br>)/g, ''))/*remove all line breakers*/}
										</p>
									</div>
								)
							: 
								<div> 
									<p>Nenhum resultado encontrado.</p>
								</div>
						}
						<ReactPaginate
							previousLabel={'<'}
							nextLabel={'>'}
							breakLabel={'...'}
							pageCount={pagination.pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={'pagination'}
							activeClassName={'pages-index'}
							className={'pages-index'}
            			/>
					</div>
				</div>
			</section>
			<Footer />
		</>
  )
}

export default SearchPage;