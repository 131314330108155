import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Slide } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../images/logos/logodefault.png';
import seta from '../../images/icons/seta-menu-mobile.svg';

import './Navbar.style.scss';

import navbarOptionsMobile from './navbarOptionsMobile.js';

export default function Navbar() {
    const [ expandDiv, setExpandDiv ] = useState(false)
    const [ elementClicked, setElementClicked ] = useState(false)
    const [ elementClickedHasChild, setElementClickedHasChild ] = useState(false)

    let history = useHistory();

    const handleClick = (path) => {
        setExpandDiv(false)
        setElementClicked(false)
        setElementClickedHasChild(false)
        history.push(path);
    }

    const selectItem = (item, isChildElement, isToRenderBack) => {
        let itemFind
        
        if(isToRenderBack) {
            setElementClickedHasChild(false);
            setElementClicked(false);
            return;
        }

        if(!isChildElement) {
            itemFind = navbarOptionsMobile.find(el => el.menuTitle === item);
            setElementClicked(itemFind);
            
            if(itemFind.childs.length > 0) {
                setElementClickedHasChild(true);
            } else {
                setExpandDiv(false)
                setElementClicked(false)
                handleClick(elementClicked.href);
            }
        } else {
            itemFind = elementClicked.childs.find(el => el.menuTitle === item);
            setElementClicked(itemFind);
            setExpandDiv(false);
            handleClick(itemFind.href);
            setElementClicked(false);
        }
    }

    return (
        <nav>
            <div id="header-navbar-container">
                <div id="logo-container">
                    <img id="logo" 
                        src={logo} 
                        alt="Hospital São Camilo Logomarca registrada"
                        onClick={() => handleClick("/")}
                    />
                </div>
                <div id="menu-container">
                    <MenuIcon id="menu-navbar-mobile" alt="Navbar menu - Sandwitch" onClick={() => setExpandDiv(!expandDiv)}/>
                </div>
                <Slide direction="down" in={expandDiv} mountOnEnter unmountOnExit>
                    <div id="expansion-div">
                        {
                            !elementClickedHasChild && !elementClicked ? 
                            navbarOptionsMobile.map((el) =>
                                    el.href !== ''  ?
                                        <a href={el.href} target={el.target} key={el.menuTitle}>
                                            <div className={`${el.highlightRed ? 'highlight-red-mobile navbar-item' : 'navbar-item'} ${el.highlightGrey ? 'highlight-grey-mobile navbar-item' : 'navbar-item'}`} onClick={() => selectItem(el.menuTitle, false, false)}>
                                                <p>{el.menuTitle}</p>
                                            </div>
                                        </a>
                                    :
                                        <div key={el.menuTitle} className={`${el.highlightRed ? 'highlight-red-mobile navbar-item' : 'navbar-item'} ${el.highlightGrey ? 'highlight-grey-mobile navbar-item' : 'navbar-item'}`} onClick={() => selectItem(el.menuTitle, false, false)}>
                                            <p>{el.menuTitle}</p>
                                        </div>
                                )
                            :
                            <>
                            <div className="navbar-item-selected" onClick={() => selectItem(elementClicked.menuTitle, false, true)}> 
                                <p><img className="navbar-item-selected-arrow" src={seta} alt="seta-menu-mobile" />{elementClicked.menuTitle.toUpperCase()}</p>
                            </div>
                            {
                                elementClicked.childs.map(el => {
                                    return (
                                        <a href={el.href} target={el.target}>
                                            <div className="navbar-item" onClick={() => selectItem(el.menuTitle, true, false)}> 
                                                <p style={{fontWeight: `${el.hihglight ? 700 : 400}`}}>{el.menuTitle}</p>
                                            </div>
                                        </a>
                                    )
                                })
                            }
                            </>
                        }
                    </div>
                </Slide>
            </div>
        </nav>
    )
}