import React from 'react';
import { useMediaQuery } from 'react-responsive';

import NavbarMobile from '../NavbarMobile/Navbar.component.jsx';
import NavbarDesktop from '../NavbarDesktop/NavbarDesktop.component.jsx';

import './Header.style.scss'

const Header = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

    return (
        <header>
            {
            isMobile ?
                <NavbarMobile />
            : 
                <NavbarDesktop />
            }
        </header>
    )
}

export default Header