import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import config from '../../helpers/config';

import './NovidadesBootstrap.style.scss'

import doGet from '../../helpers/request';

const novidadesInitState = [
{
    id: "",
    novidade_type: "banner-redirect",
    image: "",
    publish_date: "",
    link: "",
    author: "",
    category: "",
    title: "",
    title_text: "",
    original_text: "",
    createdAt: "",
    updatedAt: ""
},
]

const NovidadesBootstrap = () => {
    const [ arrayNovidades, setArrayNovidades ] = useState(novidadesInitState);
    const [ stateLoaded, setStateLoaded ] = useState(false)
    const history = useHistory();

    useEffect(() => {
        const getAPIData = async () => {
            setArrayNovidades(await doGet(`${config.host}/novidades/?carousel=true`));
        }
        getAPIData();
        setStateLoaded(true);
    }, [])

    const handleClick = (path) => {
        history.push(path) //TODO
    }

    return (
        <section id="container-novidades">
            <div className="container-novidades-bg">
                <div className="container-novidades-carousel">
                    <h2 className="h2-gray-center" style={{cursor: "pointer"}}  onClick={() => handleClick('/blog')}>Conteúdos</h2>
                    <div className="semi-border-bottom-h2-red"></div>
                    <article id="novidades">
                        <Container className="p-3">
                            <Carousel>
                                {
                                    stateLoaded ?
                                    arrayNovidades
                                        .map((item, i) =>
                                        <Carousel.Item key={item.id}>
                                            {
                                                item.novidade_type !== null ?
                                                    item.novidade_type === 'blog' || 
                                                    item.novidade_type === 'imprensa' || 
                                                    item.novidade_type === 'banner-redirect' ? // se o tipo for blog ou banner-redirect, deve exibir uma imagem clicável e com link
                                                        item.link.includes("https://") || 
                                                        item.link.includes("http://") || 
                                                        item.link.includes("www.") ?
                                                            <a href={item.link} target="_blank" rel="noreferrer" key={item.id}>
                                                                <img 
                                                                    className="d-block w-100" 
                                                                    src={item.image} 
                                                                    style={{
                                                                        cursor: "pointer", 
                                                                        borderRadius: "1vw"
                                                                    }} 
                                                                    alt={item.title}
                                                                />
                                                            </a>            
                                                            :
                                                            <img 
                                                                className="d-block w-100" 
                                                                src={item.image} 
                                                                style={{
                                                                    cursor: "pointer", 
                                                                    borderRadius: "1vw"
                                                                }} 
                                                                onClick={() => handleClick(item.link)} 
                                                                alt={item.title}
                                                                key={item.id}
                                                            />
                                                        :                                                                                 // se o tipo for banner, deve exibir apenas uma imagem 
                                                        <img 
                                                            className="d-block w-100" 
                                                            key={item.id}
                                                            src={item.image} 
                                                            style={{borderRadius: "1vw"}} 
                                                            alt={item.title} 
                                                        />
                                                    :
                                                    ''
                                            }
                                            <Carousel.Caption>
                                                {
                                                (item.novidade_type != null) ? // se o tipo não for nulo
                                                    (item.novidade_type === 'blog' || item.novidade_type === 'imprensa') ? // se o tipo for blog, deve exibir uma imagem clicável, com link e o texto Clique para ler mais.
                                                        // <div className="div-preview-text" style={{cursor: 'pointer'}} onClick={() => handleClick(item.link)}> {item.title_text} - <strong>Clique para ler mais.</strong></div>
                                                        <div className="div-preview-text" style={{cursor: 'pointer'}} onClick={() => handleClick(item.link)}> <strong>Clique para ler mais.</strong></div>
                                                    :
                                                    (item.novidade_type === 'banner-redirect') ? // se o tipo for banner-redirect, deve exibir uma imagem clicável e com link
                                                        // <div className="div-preview-text" style={{cursor: 'pointer'}} onClick={() => handleClick(item.link)}> {item.title_text}</div>
                                                        <div className="div-preview-text" style={{cursor: 'pointer'}} onClick={() => handleClick(item.link)}> {item.title_text}</div>
                                                    :    // se o tipo for banner, não deve exibir texto algum 
                                                        ''
                                                    :   // se o tipo for nulo, o caption é branco.
                                                        ''
                                                }
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ) : ''
                                }
                            </Carousel>
                        </Container>
                        {/* <button className="button-white-border-red" onClick={() => handleClick("/novidades")}><strong>Leia Mais</strong></button> */}
                    </article>
                </div>
            </div>
        </section>
    )
}

export default NovidadesBootstrap