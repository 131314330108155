import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import FormLP from '../../components/FormLP/FormLP.component.jsx';

import logoHSCWhite from './images/logo-hsc-white.png';
import logoHSC from '../../images/logos/logodefault.png';
import DownloadIcon from './images/download-icon.png'
import BannerTitle from '../../images/images/conteudos-especiais/marco-azul-marinho/banner-title.png';
import BannerText1 from '../../images/images/conteudos-especiais/marco-azul-marinho/banner-img-1.png';
import BannerText2 from '../../images/images/conteudos-especiais/marco-azul-marinho/banner-img-2.png';
import Dados from '../../images/images/conteudos-especiais/marco-azul-marinho/dados-img.png';
import DorBarriga from '../../images/images/conteudos-especiais/marco-azul-marinho/dor-na-barriga.png';
import Atomo from '../../images/images/conteudos-especiais/marco-azul-marinho/atomo.png';
import Estomago from '../../images/images/conteudos-especiais/marco-azul-marinho/estomago.png';
import MaoCigarro from '../../images/images/conteudos-especiais/marco-azul-marinho/mao-cigarro.png';
import MaoGarrafa from '../../images/images/conteudos-especiais/marco-azul-marinho/mao-garrafa.png';

import './MarcoAzulMarinho.style.scss'
import { SocialMOCK } from './LPData';

const MarcoAzulMarinho = () => {
    const location = useLocation();

    const downloadPage = () => {
        window.print()
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <div className='header-mar'>
                <div className='header-main'>
                    <a href="/">
                        <img className='logo' src={logoHSCWhite} alt="Logo HSC" />
                    </a>

                    <button className='button-download' onClick={downloadPage}><img src={DownloadIcon} className='download-icon' alt="Download" />DOWNLOAD</button>
                </div>

                <div className='banner-content'>
                    <img className='banner-content-img1' src={BannerText1} alt="Banner1" />

                    <div className='banner-content-texts'>
                        <img className='banner-title-img' src={BannerTitle} alt="Março Azul Marinho" />

                        <img className='banner-content-img2' src={BannerText2} alt="Banner2" />
                    </div>
                </div>                
            </div>

            <div className='dados-mar'>
                <img src={Dados} alt="Dados" className='img-dados' />

                <img src={Estomago} alt="Estomago" className='img-estomago' />
            </div>

            <div className='texto-explicativo'>
                <div className='textura'>
                        <p className='textura-texto'>
                        <strong>Durante o mês de março, a Rede de Hospitais São Camilo de São Paulo adere à campanha de combate ao câncer colorretal dentro do Março Azul Marinho.</strong> O objetivo é sensibilizar a população sobre a importância do diagnóstico precoce da doença, considerada um dos tipos de câncer mais passíveis de prevenção. 
                        <br /><br />
                        O câncer colorretal ou do intestino grosso é o terceiro tipo mais frequente no Brasil, se origina com o surgimento de pólipos no órgão que, ao longo do tempo, sofrem alterações progressivas em suas células. Sua principal forma de prevenção é o rastreamento por exames, especialmente a colonoscopia que investiga e aponta a retirada de pólipos quando existentes, antes que se degenerem em câncer. 
                        </p>
                </div>

                <div className='mensagem-destaque-mar'>
                    Confira os principais sintomas, fatores de risco e a
                    prevenção para essa neoplasia e fique atento. 
                </div>
            </div>

            <div className='sintomas-title-container-mar'>
                <h2 className='sintomas-title'>Sintomas</h2>
                <hr className='hr-mar'/>

                <div className='sintomas-conteudo'>
                    <img className='sintomas-img' src={DorBarriga} alt="Dores abdominais" />

                    <div className='sintomas-bullets-main'>
                        <ul>
                            <li>• Diarreia ou constipação;</li> 
                            <li>• Sensação de que o intestino não é completamente esvaziado;</li> 
                            <li>• Presença de sangue nas fezes;</li> 
                            <li>• Dor abdominal tipo cólica;</li> 
                            <li>• Sensação de inchaço abdominal;</li> 
                            <li>• Cansaço e fadiga;</li> 
                            <li>• Perda de peso sem motivo aparente.</li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div className='risk-title-container-mar'>
                <h2 className='risk-title'>Fatores de risco</h2>
                <hr className='hr-mar-risk'/>

                <div className='risk-conteudo'>
                    <div className='risk-bullets-main'>
                        <ul>
                            <li>• Sedentarismo;</li> 
                            <li>• Sobrepeso;</li> 
                            <li>• Alimentação pobre em fibras e rica em carnes vermelhas e ultraprocessados;</li> 
                            <li>• Exposição à radiação;</li> 
                            <li>• Tabagismo;</li> 
                            <li>• Alcoolismo.</li> 
                        </ul>
                    </div>

                    <div className='risk-img-container'>
                        <div className='risk-img2-container'>
                            <img className='risk-img' src={Atomo} alt="Atomo" />

                            <img className='risk-img' src={MaoGarrafa} alt="Bebida Alcoolica" />
                        </div>

                        <img className='risk-img' src={MaoCigarro} alt="Cigarro" />
                    </div>
                </div>
            </div>

            <div className='prevention-title-container-mar'>
                <h2 className='prevention-title'>Prevenção</h2>
                <hr className='hr-mar-prevention'/>

                <div className='prevention-bullets-main'>
                    <div>
                        <ul>
                            <li>• Busque manter o peso corporal adequado;</li>  
                            <li>• Pratique atividades físicas com <br /> regularidade: pelo menos 150 minutos semanais de exercícios moderados;</li>  
                            <li>• Mantenha uma alimentação saudável, composta, principalmente, por alimentos in natura e minimamente processados;</li> 
                            <li>• Evite o consumo de carnes processadas (salsicha, mortadela, linguiça, presunto, bacon, peito de peru, salame);</li> 
                        </ul>
                    </div>
                   
                    <div>
                        <ul>
                            <li>• Limite o consumo de carnes vermelhas (procure não ingerir mais do que 500 gramas por semana).</li> 
                            <li>• Não fume e evite o tabagismo passivo (afastar-se de pessoas que estejam fumando);</li> 
                            <li>• Modere a ingestão de bebidas alcoólicas.</li> 
                        </ul>
                    </div>
                </div>
            </div>

            
            <div className='agende-main-mar'>
                <p className='agende-text-mar'>Na Rede de Hospitais São Camilo temos profissionais especializados para diagnosticar, tratar e acompanhar sua saúde, afinal,<strong> nossa missão é cuidar da sua vida e valorizá-la.</strong></p>

                <a className='agende-button-mar' href='https://centralpaciente.hospitalsaocamilosp.org.br/acesso' target='_blank' rel='noreferrer'>Agende seu horário</a>
            </div>

            {/* <div className='form-wrapper'>
                <div className='form'>
                    <h3 className='form-title'>Quero receber informações e conteúdos do Hospital São Camilo</h3>

                    <div>
                        <FormLP />
                    </div> 
                </div>
            </div> */}
            

            <div className='footer'>
                <a href="/" >
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: 'center', alignItems: 'center'}}>
                    <p style={{color: "#606060", fontSize: "16px"}}>Diretor Médico: Dr. Fernando Pompeu - CRM 125543</p>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>
                </div>


                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </div>
        </main>
    )
}

export default MarcoAzulMarinho;