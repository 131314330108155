module.exports = [
    // { 
    //     menuTitle: 'Portal do Paciente', 
    //     href: '',
    //     highlightRed: true,
    //     target: '_self',
    //     childs: [
    //         {
    //             menuTitle: 'Portal do Paciente', 
    //             href: 'https://portalpaciente.hospitalsaocamilosp.org.br/',
    //             target: '_blank'
    //         },
    //         {
    //             menuTitle: 'Encontre seu médico', 
    //             href: '/medicos',
    //             target: '_self'
    //         },
    //     ]
    // },
    // { 
    //     menuTitle: 'Portal do Médico', 
    //     href: '',
    //     highlightGrey: true,
    //     target: '_self',
    //     childs: [
    //         {
    //             menuTitle: 'Portal do Médico', 
    //             href: 'http://portalmedico.hospitalsaocamilosp.org.br/',
    //             target: '_blank'
    //         },
    //         {
    //             menuTitle: 'Orçamentos', 
    //             href: '/orcamento',
    //             target: '_self'
    //         },
    //         {
    //             menuTitle: 'Visita Técnica', 
    //             href: '/visita-tecnica',
    //             target: '_self'
    //         },
    //     ]
    // },
    { 
        menuTitle: 'Institucional', 
        href: '', 
        target: '_self',
        childs: [
            {
                menuTitle: 'Nossa História', 
                href: '/institucional/nossa-historia',
                target: '_self',
            },
            {
                menuTitle: 'SAC',  
                href: '/sac',
                target: '_self',
            },
            {
                menuTitle: 'Blog', 
                href: '/blog',
                target: '_self',
            },
            {
                menuTitle: 'Eventos', 
                href: '/eventos',
                target: '_self',
            },
            {
                menuTitle: 'Missão, Visão e Valores', 
                href: '/institucional/missao-visao-valores',
                target: '_self',
            },
            {
                menuTitle: 'Linha do Tempo', 
                href: '/institucional/linha-do-tempo',
                target: '_self',
            },
            {
                menuTitle: 'Residência Médica', 
                href: '/institucional/residencia-medica',
                target: '_self',
            },
            {
                menuTitle: 'Conveniências', 
                href: '/conveniencias',
                target: '_self',
            },
            {
                menuTitle: 'Conteúdos Especiais',  
                href: '/conteudos-especiais',
                target: '_self',
            },
            {
                menuTitle: 'Política de Privacidade', 
                href: '/duvidas-frequentes/lgpd',
                target: '_self',
            },
            {
                menuTitle: 'São Camilo de Lellis', 
                href: '/institucional/sao-camilo-de-lellis',
                target: '_self',
            },
            {
                menuTitle: 'Carta de Princípios Camilianos', 
                href: 'https://hospitalsaocamilosp.org.br/arquivos/carta_de_principios_camilianos.pdf',
                target: '_blank',
            },
            // {
            //     menuTitle: 'Outubro Rosa', 
            //     href: '/outubro-rosa',
            //     target: '_self',
            //     hihglight: true,
            // },
            
        ]
    },
    { 
        menuTitle: 'Unidades', 
        href: '', 
        target: '_self',
        childs: [
            {
                menuTitle: 'Pompeia', 
                href: '/unidades/pompeia',
                target: '_self'
            },
            {
                menuTitle: 'Santana', 
                href: '/unidades/santana',
                target: '_self'
            },
            {
                menuTitle: 'Ipiranga', 
                href: '/unidades/ipiranga',
                target: '_self'
            },
            {
                menuTitle: 'Granja Viana', 
                href: 'https://granjaviana.hospitalsaocamilosp.org.br',
                target: '_blank',
            },
            // {
            //     menuTitle: 'Núcleo de Pesquisa e Ensino', 
            //     href: '/unidades/nucleo-de-pesquisa',
            //     target: '_self',
            // },
        ]
    },
    { 
        menuTitle: 'Pronto-Socorro', 
        href: '/pronto-socorro',
        target: '_self',
        childs: []
    },
    { 
        menuTitle: 'Especialidades', 
        href: '',
        target: '_self',
        childs: [
            // {
            //     menuTitle: 'Especialidades Médicas', 
            //     href: '/especialidades',
            //     target: '_self'
            // },
            {
                menuTitle: 'Encontre seu médico', 
                href: '/medicos',
                target: '_self'
            },
            {
                menuTitle: 'Especialidades por Unidades', 
                href: '/especialidades-por-unidades',
                target: '_self'
            },
        ]
    },
    { 
        menuTitle: 'Marque sua Consulta', 
        href: '/agendamento-online', 
        target: '_blank', 
        childs: []
    },
    { 
        menuTitle: 'Resultados de Exames', 
        href: '/resultados-exames', 
        target: '_blank', 
        childs: []
    },
    { 
        menuTitle: 'Convênios', 
        href: '/planos-de-saude', 
        target: '_self', 
        childs: []
    },
    { 
        menuTitle: 'Trabalhe Conosco', 
        href: 'https://trabalheconosco.vagas.com.br/hospital-sao-camilo-sp', 
        target: '_blank', 
        childs: []
    },
    {
        menuTitle: 'Dúvidas Frequentes', 
        href: '', 
        target: '_self', 
        childs: [
            {
                menuTitle: 'Achados e Perdidos', 
                href: '/duvidas-frequentes/achados-e-perdidos',
                target: '_self'
            },
            {
                menuTitle: 'Alimentação', 
                href: '/duvidas-frequentes/alimentacao',
                target: '_self'
            },
            {
                menuTitle: 'Coronavírus', 
                href: '/duvidas-frequentes/coronavirus',
                target: '_self'
            },
            {
                menuTitle: 'Banco de Sangue', 
                href: '/duvidas-frequentes/banco-de-sangue',
                target: '_self'
            },
            {
                menuTitle: 'Estacionamento', 
                href: '/duvidas-frequentes/estacionamento',
                target: '_self'
            },
            {
                menuTitle: 'Horário de Visitas', 
                href: '/duvidas-frequentes/horario-de-visitas',
                target: '_self'
            },
            {
                menuTitle: 'Horário de Atendimento', 
                href: '/duvidas-frequentes/horario-de-atendimento',
                target: '_self'
            },
            {
                menuTitle: 'SAC', 
                href: '/duvidas-frequentes/sac',
                target: '_self'
            },
            {
                menuTitle: 'Serviços para Acompanhantes', 
                href: '/duvidas-frequentes/servicos-acompanhantes',
                target: '_self'
            },
            {
                menuTitle: 'LGPD', 
                href: '/duvidas-frequentes/lgpd',
                target: '_self'
            },
        ]
    },
    // { 
    //     menuTitle: 'Portal do Paciente', 
    //     href: '',
    //     highlightRed: true,
    //     target: '_self',
    //     childs: [
    //         {
    //             menuTitle: 'Portal do Paciente', 
    //             href: 'https://portalpaciente.hospitalsaocamilosp.org.br/',
    //             target: '_blank'
    //         },
    //         {
    //             menuTitle: 'Encontre seu médico', 
    //             href: '/medicos',
    //             target: '_self'
    //         },
    //     ]
    // },
    // { 
    //     menuTitle: 'Portal do Médico', 
    //     href: '',
    //     highlightGrey: true,
    //     target: '_self',
    //     childs: [
    //         {
    //             menuTitle: 'Portal do Médico', 
    //             href: 'http://portalmedico.hospitalsaocamilosp.org.br/',
    //             target: '_blank'
    //         },
    //         {
    //             menuTitle: 'Orçamentos', 
    //             href: '/orcamento',
    //             target: '_self'
    //         },
    //         {
    //             menuTitle: 'Visita Técnica', 
    //             href: '/visita-tecnica',
    //             target: '_self'
    //         },
    //     ]
    // },
]