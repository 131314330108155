import React from 'react';
import { useHistory } from "react-router-dom";
import { CardMedia } from '@material-ui/core'
import './Unidades.style.scss'

import unidadesData from '../../pages/Unidades/unidadesData.js'

const EspecialidadesMedicasCards = () => {
    let history = useHistory();

    const handleClick = (path) => {
        history.push(path)
    }

    return (
        <section id="unidades">
            <h2 className="h2-gray-center">Unidades</h2>
            <div className="semi-border-bottom-h2-red"></div>
            <div className="cards-group">
                <div className="unidade-card-container">
                    <CardMedia
                        className="unidade-card"
                        id="santana-capa"
                        onClick={() => handleClick("/unidades/santana")}
                        image={unidadesData.filter(unidades => (unidades.nome === 'Santana')).map(elemento => elemento.imagens[0].imagem)}
                        alt={unidadesData.filter(unidades => (unidades.nome === 'Santana')).map(elemento => elemento.imagens[0].alt)}
                    />
                    <div className="unidade-card-bg" onClick={() => handleClick("/unidades/santana")}>
                        <div className="unidade-card-title">Unidade Santana</div>
                    </div>
                </div>
                <div className="unidade-card-container">
                    <CardMedia
                        className="unidade-card"
                        id="ipiranga-capa"
                        onClick={() => handleClick("/unidades/ipiranga")}
                        image={unidadesData.filter(unidades => (unidades.nome === 'Ipiranga')).map(elemento => elemento.imagens[0].imagem)}
                        alt={unidadesData.filter(unidades => (unidades.nome === 'Ipiranga')).map(elemento => elemento.imagens[0].alt)}
                    />
                    <div className="unidade-card-bg" onClick={() => handleClick("/unidades/ipiranga")}>
                        <div className="unidade-card-title">Unidade Ipiranga</div>
                    </div>
                </div>
                <div className="unidade-card-container">
                    <CardMedia
                        className="unidade-card"
                        id="pompeia-capa"
                        onClick={() => handleClick("/unidades/pompeia")}
                        image={unidadesData.filter(unidades => (unidades.nome === 'Pompeia')).map(elemento => elemento.imagens[0].imagem)}
                        alt={unidadesData.filter(unidades => (unidades.nome === 'Pompeia')).map(elemento => elemento.imagens[0].alt)}
                    />
                    <div className="unidade-card-bg" onClick={() => handleClick("/unidades/pompeia")}>
                        <div className="unidade-card-title">Unidade Pompeia</div>
                    </div>
                </div>
                {/* <div className="unidade-card-container">
                    <a href="https://granjaviana.hospitalsaocamilosp.org.br" target='_blank' rel="noreferrer">
                        <CardMedia
                            className="unidade-card"
                            id="granja-capa"
                            image={unidadesData.filter(unidades => (unidades.nome === 'Granja Viana')).map(elemento => elemento.imagens[0].imagem)}
                            alt={unidadesData.filter(unidades => (unidades.nome === 'Granja Viana')).map(elemento => elemento.imagens[0].alt)}
                        />
                        <div className="unidade-card-bg">
                            <div className="unidade-card-title">Unidade Granja Viana</div>
                        </div>
                    </a>
                </div> */}
            </div>
        </section>
    )
}

export default EspecialidadesMedicasCards