import linkedin from './images/linkedin.png'
import facebook from './images/facebook.png'
import instagram from './images/instagram.png'
import spotify from './images/spotify.png'
import youtube from './images/youtube.png'

import katiaBook from './images/katia-silene-book.png';
import katiaTitle from './images/katia-silene-title.png';
import carolinaBook from './images/carolina-alves-book.png';
import carolinaTitle from './images/carolina-alves-title.png';
import marcelaBook from './images/marcela-mate-book.png';
import marcelaTitle from './images/marcela-mate-title.png';
import priscilaBook from './images/priscila-almeida-book.png';
import priscilaTitle from './images/priscila-almeida-title.png';
import marileneBook from './images/marilene-vieira-book.png';
import marileneTitle from './images/marilene-vieira-title.png';
import mariaBook from './images/maria-vasconcelos-book.png';
import mariaTitle from './images/maria-vasconcelos-title.png';

export const SocialMOCK = [
    {
        id: '1',
        name: 'Instagram',
        href: 'https://www.instagram.com/hospitalsaocamilosp/?hl=pt-br',
        Image: instagram
    },
    {
        id: '2',
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/hospitalsaocamilosp/?originalSubdomain=br',
        Image: linkedin
    },
    {
        id: '3',
        name: 'Youtube',
        href: 'https://www.youtube.com/user/RedeSaoCamiloSP',
        Image: youtube
    },
    {
        id: '4',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/4Ov84RYyq1XTHqxH8soaPj?si=uoyQg3YTR-m8tezKnZxexg',
        Image: spotify
    },
    {
        id: '5',
        name: 'Facebook',
        href: 'https://www.facebook.com/hospitalsaocamilosp/',
        Image: facebook
    },
]

export const PatientHistory = [
    {
        id: '1',
        name: 'katia-silene',
        imageBook: katiaBook,
        imageTitle: katiaTitle, 
        textPreview: '“Em junho de 2022, cheguei em casa após mais um dia de trabalho no Palácio Tangará e senti um mal-estar repentino, desmaiei. Como sempre tive anemia, meu marido Nonato e meu filho Paulo Henrique desconfiaram que se tratava de algo relacionado à doença. Por indicação de um amigo, fui à ginecologista na Unidade Pompeia do Hospital São Camilo realizar um check - up, e foi durante os exames que encontraram um nódulo em minha mama.',
        TextFull: `“Em junho de 2022, cheguei em casa após mais um dia de trabalho no Palácio Tangará e senti um mal-estar repentino, desmaiei. Como sempre tive anemia, meu marido Nonato e meu filho Paulo Henrique desconfiaram que se tratava de algo relacionado à doença. Por indicação de um amigo, fui à ginecologista na Unidade Pompeia do Hospital São Camilo realizar um check - up, e foi durante os exames que encontraram um nódulo em minha mama. Em consulta com a Dra. Vanessa Donatelli, mastologista da instituição, fui informada de que precisaria de uma cirurgia, com muita paciência e calma, ela me explicou cada etapa, eu sequer imaginava a jornada que começaria a trilhar. O momento mais difícil, foi quando o oncologista, Dr. João Navarro me informou sobre a possível queda do cabelo durante o tratamento, sempre fui muito vaidosa e tinha acabado de assumir meu cabelo cacheado. Enfrentei quimioterapia e radioterapia, ainda estou em acompanhamento, mas não tenho medo e sim planos. Antes de descobrir o câncer de mama, enquanto estava na Igreja, recebi uma mensagem de Deus dizendo que iria passar por um procedimento, que Ele já tinha marcado dia, hora e lugar. É muito forte, mas me lembro disso todos os dias, então, sei que é passageiro. Me libertei de mágoas, limpei rancores e fui muito bem atendida, a minha fé me sustenta.” <br/> <br/> <strong>Agradecimentos</strong> <br/> À minha família, em especial, ao meu marido Nonato que me acompanhou durante todo o tratamento, mesmo em suas folgas, incansavelmente e ao meu filho, Paulo Henrique que me ajudou financeiramente, apesar da pouca idade, com apenas 17 anos. Minha mãe que sempre colaborou, minha irmã Cássia que me presenteou com uma peruca o que me possibilitou lidar com a vergonha da careca, à Rose Cruz que raspou minha cabeça e todos os irmãos do meu marido e amigo pela ajuda e carinho.`,
    },
    {
        id: '2',
        name: 'carolina-alves',
        imageBook: carolinaBook,
        imageTitle: carolinaTitle, 
        textPreview: '“Em 2020 enfrentei uma dor profunda e transformadora, sofri um aborto quanto estava entre 10 e 11 semanas de gestação, após esse episódio, notei que uma das mamas estava diferente da outra. Passei por dois médicos que me disseram que aquilo era resultado da experiência que tinha vivido, foi quando no início de 2021, decidi me consultar com um mastologista, na Unidade Pompeia da Rede de Hospitais São Camilo, e conheci o Dr. Alexandri Genias.',
        TextFull: `“Em 2020 enfrentei uma dor profunda e transformadora, sofri um aborto quanto estava entre 10 e 11 semanas de gestação, após esse episódio, notei que uma das mamas estava diferente da outra. Passei por dois médicos que me disseram que aquilo era resultado da experiência que tinha vivido, foi quando no início de 2021, decidi me consultar com um mastologista, na Unidade Pompeia da Rede de Hospitais São Camilo, e conheci o Dr. Alexandri Genias. Ao apalpar minha mama, reparei em sua feição que havia algo de errado. Realizei os exames e a ressonância constatou o câncer de mama, já com 10 centímetros, abri o resultado no trabalho, enquanto lecionava na educação infantil. Chorei muito, um choque inicial, mas depois recobrei minhas forças para compartilhar a notícia com minha família. Com o Dr. João Navarro, oncologista, planejamos as etapas do tratamento e cumpri minhas sessões de quimioterapia e radioterapia, não sofri com a queda dos cabelos, mas com o cansaço físico. O apoio da minha família e os médicos maravilhosos me ajudaram a lidar com o diagnóstico e a me reaproximar da minha fé e confiar em Deus.” <br/> <br/> <strong>Agradecimentos</strong> <br/> Gostaria de agradecer à Deus, que cuidou de mim durante todo o processo. À minha família, meu esposo, que nunca me abandonou, meu filho, minha mãe e minha irmã, que estiveram ao meu lado. A equipe de oncologia do hospital São Camilo que me tratou e acolheu da melhor maneira possível.`,
    },
    {
        id: '3',
        name: 'marcela-mate',
        imageBook: marcelaBook,
        imageTitle: marcelaTitle, 
        textPreview: '“Em agosto de 2021, estava assistindo à televisão em uma noite com meu marido, quando senti um caroço em minha mama, pedi pra ele apalpar, observamos algo diferente. Como profissional da saúde, já tinha ido ao ginecologista naquele ano e pelos exames físicos estava tudo bem, não me preocupei em realizar o check –up, portanto, engavetei os pedidos. Por precaução, após aquele episódio, decidi fazer todos os exames anteriormente solicitados.',
        TextFull: `“Em agosto de 2021, estava assistindo à televisão em uma noite com meu marido, quando senti um caroço em minha mama, pedi pra ele apalpar, observamos algo diferente. Como profissional da saúde, já tinha ido ao ginecologista naquele ano e pelos exames físicos estava tudo bem, não me preocupei em realizar o check –up, portanto, engavetei os pedidos. Por precaução, após aquele episódio, decidi fazer todos os exames anteriormente solicitados. Eu tinha planos de comprar uma casa, estava recém-casada, começando a construir minha família e com meu filho amado que demanda um cuidado extra por ser autista, entretanto, como sempre fui uma mulher saudável, acreditava ser algo corriqueiro. A vida me apresentou sua imprevisibilidade quando abri o resultado e tive a confirmação: carcinoma maligno. Naquele momento parecia estar em órbita e iniciava ali a fase mais desafiadora que já enfrentei, o período em que pude comprovar toda a minha força. Comecei a me tratar com a Dra. Jaqueline Yamamoto, oncologista da Unidade Santana do São Camilo, foram várias sessões de quimioterapia, uma cirurgia de mastectomia total e radioterapia, que me levaram a refletir e tratar a doença com naturalidade apesar de todos as reações adversas e dos desafios enfrentados. Me emocionei muito quando recebi a notícia de que estava em remissão, tudo que não consegui chorar durante todo o processo veio à tona, foram 3 folhinhas que tiraram um peso do meu coração, informando que o tratamento tinha sido 100% eficaz. Eu nunca deixei de acreditar, mas ali eu tive a confirmação da minha fé e para coroar esse momento, eu retornei ao meu trabalho no início deste ano”
        <br/> <br/> <strong>Agradecimentos</strong> <br/>
        Em primeiro lugar agradeço à Deus, em todos os momentos difíceis foi ele que me sustentou, era no meu quarto sozinha, com meu travesseiro que muitas vezes chorei, sem entender o porquê de todo esse processo. Meu filho e meu marido que estiveram sempre ao meu lado, me apoiando. nunca me abandonaram. E posso dizer também que sou grata a mim por não desistir, ao meu otimismo, minha vontade que tudo acabasse logo, me fez forte.`,
    },
    {
        id: '4',
        name: 'priscila-almeida',
        imageBook: priscilaBook,
        imageTitle: priscilaTitle, 
        textPreview: '“Sempre fui uma pessoa bastante objetiva e prática. Em 2017, enquanto cursava o último período da faculdade, realizei alguns exames de rotina, quando recebi o primeiro diagnóstico que transformaria a minha maneira de encarar o mundo e lidar com a vida: um câncer de mama. Desde o diagnóstico, durante o tratamento, em todas as etapas necessárias para lidar com a doença, nunca deixei me abater, mantive meu otimismo e minhas atividades rotineiras.',
        TextFull: `“Sempre fui uma pessoa bastante objetiva e prática. Em 2017, enquanto cursava o último período da faculdade, realizei alguns exames de rotina, quando recebi o primeiro diagnóstico que transformaria a minha maneira de encarar o mundo e lidar com a vida: um câncer de mama. Desde o diagnóstico, durante o tratamento, em todas as etapas necessárias para lidar com a doença, nunca deixei me abater, mantive meu otimismo e minhas atividades rotineiras. No momento em que acreditei estar em remissão do câncer, um novo diagnóstico, que me colocou novamente no papel de paciente oncológico. Os médicos Dr. Pedro Henrique Zavarize de Moraes, oncologista, e Dr. Matheus Novaes da Silva Abreu, cirurgião oncológico, são atualmente meus companheiros de jornada, além da minha família, amigos e Deus. Mantenho a cabeça ocupada e não deixo de fazer nada daquilo que gosto, como andar de bicicleta e passear, pois, sei que a vida é aqui e agora.”
        <br/> <br/> <strong>Agradecimentos</strong> <br/>
        “Agradeço aos meus familiares e amigos que sempre estiveram comigo e estão, enviando mensagens, perguntando e oferecendo carinho. Ao pessoal do trabalho, por todo suporte, aos médicos e equipe de enfermagem, pelo cuidado ao longo de todo tratamento.”`,
    },
    {
        id: '5',
        name: 'marilene-vieira',
        imageBook: marileneBook,
        imageTitle: marileneTitle, 
        textPreview: '“Sempre coloquei todas as minhas questões de vida aos cuidados de Deus, tenho muita fé e não foi diferente quando recebi a notícia de que estava com câncer de mama. Como sou cadeirante, para me preservar, durante toda a pandemia evitei sair e circular, quando o cenário parecia melhor, em 2022, durante um check – up, um clínico geral identificou que havia uma alteração na minha mamografia e me aconselhou a procurar por um mastologista.',
        TextFull: `“Sempre coloquei todas as minhas questões de vida aos cuidados de Deus, tenho muita fé e não foi diferente quando recebi a notícia de que estava com câncer de mama. Como sou cadeirante, para me preservar, durante toda a pandemia evitei sair e circular, quando o cenário parecia melhor, em 2022, durante um check – up, um clínico geral identificou que havia uma alteração na minha mamografia e me aconselhou a procurar por um mastologista. Foi quando conheci a Dra. Carolina Mardegam, mastologista da Unidade Ipiranga do Hospital São Camilo de SP, que me informou sobre o diagnóstico, a necessidade de tratamento e a cirurgia. Durante as sessões de quimioterapia, passei muito mal, tive momentos muito desafiadores, como a realização dos exames de ressonância, a punção e a queda de cabelo, mas nunca desacreditei da minha cura. Tudo na vida passa, é reconfortante saber disso e ter sempre ao lado o carinho e o amor do meu marido, da minha filha e os cuidados essenciais da equipe médica.”
        <br/> <br/> <strong>Agradecimentos</strong> <br/>
        Agradeço à Deus, que me deu forças o tempo todo, desde o momento do diagnóstico até hoje, porque sempre tive muita fé na minha cura, sempre deixei minha saúde em suas mãos. E também ao meu esposo Nivaldo, minha filha Maria Julia, que sempre estiveram ao meu lado e a minha família.`,
    },
    {
        id: '6',
        name: 'maria-vasconcelos',
        imageBook: mariaBook,
        imageTitle: mariaTitle, 
        textPreview: '“No ano de 1984, quando minha filha estava com dois anos de idade, fiz uma cirurgia para remover dois cistos benignos na mama esquerda. A partir daquela data passei a fazer regularmente meus exames preventivos e nunca mais tive problemas. Minha saúde sempre foi muito boa. Em dezembro de 2022, durante o banho, notei um nódulo na mesma mama e procurei um mastologista que solicitou alguns exames. Os laudos indicavam "achados benignos, classificação BI-RADS: 2". Fiquei tranquila, embora o nódulo continuasse ali.',
        TextFull: `“No ano de 1984, quando minha filha estava com dois anos de idade, fiz uma cirurgia para remover dois cistos benignos na mama esquerda. A partir daquela data passei a fazer regularmente meus exames preventivos e nunca mais tive problemas. Minha saúde sempre foi muito boa. Em dezembro de 2022, durante o banho, notei um nódulo na mesma mama e procurei um mastologista que solicitou alguns exames. Os laudos indicavam "achados benignos, classificação BI-RADS: 2". Fiquei tranquila, embora o nódulo continuasse ali. Em abril de 2023 procurei uma nova mastologista, a Dra. Carolina Paula Krisam Matielli da Unidade Ipiranga do Hospital São Camilo de SP, um anjo que cruzou meu caminho. Ela fez o exame palpatório e pediu uma biopsia. Como eu tinha uma viagem agendada para o final de abril, fui fazer a biopsia no dia 08 de maio. No dia 22 peguei o resultado e li: "carcinoma mamário invasivo ". Meu mundo caiu, a única coisa que pensava era que queria ver meu neto de cinco anos crescer. Em prantos liguei para uma amiga e nem sei como consegui dirigir até a casa dela. Não tinha coragem de contar para minha filha, liguei para o meu genro e pedi a ele para dar a notícia.
        Sou espírita, faço trabalho voluntário e isso me ajudou muito. Ainda na casa da minha amiga, consegui me acalmar e decidi que enfrentaria tudo sem lamentar e sem me revoltar. Entendi que esse desafio seria um grande aprendizado e que através dele poderia me tornar uma pessoa melhor, além de ajudar outras pessoas na mesma situação. Por esse motivo estou participando dessa campanha de orientação e prevenção ao câncer de mama. Pedi a meus familiares e amigos para não lamentarem nem sentirem pena, apenas para me enviar vibrações positivas. Fiz a cirurgia com a Dra. Carolina no dia 15 de junho de 2023. O tumor foi completamente removido e a mama preservada. Estou fazendo quimioterapia e na sequência farei radioterapia. O cabelo caiu e esse foi o momento mais difícil, mas ele nasce de novo.
        Depois farei o acompanhamento necessário até receber alta total.”
        <br/> <br/> <strong>Agradecimentos</strong> <br/>
        Em primeiro lugar a Deus, pela oportunidade de aprender e evoluir como ser humano. À Dra. Carolina Matielli, pela extraordinária pessoa que é, profissional competente e humanizada, ao oncologista Dr. Thiago Assunção e a toda equipe do IBCC e da Oncologia do Hospital São Camilo Ipiranga.
        Agradecimento mais que especial à minha família, minha filha Carol, Fernando meu genro, Paco meu neto, Luiz meu ex -marido, pelo carinho com que me acolheram. Aos meus irmãos, cunhadas, sobrinhos, alunos, ex - alunos, colegas de trabalho, amigos de vida, pelas inúmeras mensagens de carinho e apoio que tenho recebido. À Dra. Fátima Vasconcelos, acupunturista, bióloga esteta, pelas terapias alternativas que me proporcionam bem-estar. Às amigas Elvira, Edna e Elza, que nunca me abandonam. Ao Núcleo Assistencial Espírita Paz e Amor em Jesus, pelo conforto espiritual. À querida Ray Rosa, do Studio Ray, que cortou meu cabelo e me acolheu com carinho materno.`,
    },
]

export const campaignLocations = [
    {
        id: 1,
        location: 'Shopping Bourbon',
        date: 'de 02 a 06/10',
        hour: '10h às 17h',
        dateExtra: '09/10',
        hourExtra: '14h às 17h'
    },
    {
        id: 2,
        location: 'Shopping Santa Cruz',
        date: 'de 16 a 20/10 \n| 23 a 27/10 \n| 30 a 31/10',
        hour: '10h às 17h',
    },
    {
        id: 3,
        location: 'Shopping Tucuruvi',
        date: 'de 23 a 28/10',
        hour: '10h às 17h',
    },
    {
        id: 4,
        location: 'Estação Sé do Metrô',
        date: 'de 04/10 a 31/10',
    },
]