import BannerFevereiro from '../../images/images/conteudos-especiais/banner-fevereiro-roxo.jpeg'
import BannerOutubro from '../OutubroRosa/images/banner-outubro-rosa-ce.jpg'
import BannerMarco from '../../images/images/conteudos-especiais/banner-marco-azul-marinho.jpg'
import BannerAbril from '../../images/images/conteudos-especiais/banner-abril-azul.jpg'


let conteudos = [
    {
        nome: 'Fevereiro Roxo',
        nomeUrl: 'fevereiro-roxo',
        imagemBanner: BannerFevereiro,
        alt: 'Banner Fevereiro Roxo',
    },
    {
        nome: 'Outubro Rosa',
        nomeUrl: 'outubro-rosa',
        imagemBanner: BannerOutubro,
        alt: 'Banner Outubro Rosa',
    },
    {
        nome: 'Março Azul Marinho',
        nomeUrl: 'marco-azul-marinho',
        imagemBanner: BannerMarco,
        alt: 'Banner Março Azul Marinho',
    },
    {
        nome: 'Abril Azul',
        nomeUrl: 'abril-azul',
        imagemBanner: BannerAbril,
        alt: 'Banner Abril Azul',
    },
]

export default conteudos;