import React, { useState, useEffect } from 'react';
import { Breadcrumbs, 
	Table, TableCell, 
	Paper, TableHead, 
	TableRow, TableBody, 
	TableContainer, TextField, ThemeProvider,
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { red, grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import './Medicos.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

import enviroments from './enviroments.js';

const axios = require('axios');

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C3000A",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
});

const theme = createMuiTheme({
	palette: {
		primary: grey,
		secondary: red
	},
});

const Medicos = () => {
	let history = useHistory();
	const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const [nomeFiltered, setNomeFiltered] = useState('');
  const [medicos, setMedicos] = useState([]);
  const [paginas, setPaginas] = useState({});
  const [showRowTable] = useState(true);

  async function getMedicos() {
    const { data } = await axios.get(enviroments.prd, { 
      headers: {
        Authorization: `Bearer ${enviroments.token}`
      },
    });

    const { medicos, paginas } = data;

    setMedicos(medicos);
    setPaginas(paginas);
  }

  useEffect(() => {
    getMedicos();
  }, []);

  const handleChangeFilter = async (event) => {
    const selected =  event.target.value;

    setNomeFiltered(selected);

    const { data } = await axios.get(enviroments.prd, {
      headers: {
        Authorization: `Bearer ${enviroments.token}`
      },
      params: {
        nome: selected.toUpperCase(),
      },
    });

    const { medicos, paginas } = data;
    setMedicos(medicos);
    setPaginas(paginas);
  };

  const handlePageClick = async (event) => {
    const selected =  event.selected;

    const { data } = await axios.get(enviroments.prd, {
      headers: {
        Authorization: `Bearer ${enviroments.token}`
      },
      params: {
        pagina: selected + 1,
      },
    });

    const { medicos, paginas } = data;

    setMedicos(medicos);
    setPaginas(paginas);
  };

  const handleFilteredPageClick = async (event) => {
    const selected =  event.selected;

    const { data } = await axios.get(enviroments.prd, {
      headers: {
        Authorization: `Bearer ${enviroments.token}`
      },
      params: {
        pagina: selected + 1,
        nome: nomeFiltered.toUpperCase(),
      },
    });

    const { medicos, paginas } = data;

    setMedicos(medicos);
    setPaginas(paginas);
  };

  const handleClick = path => history.push(path);

  return (
   <>
   <Header />
   <MenuRapido />
   <section id="medicos-page-section"> 
	 	<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-medicos">			      
      <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">				      
        <HomeIcon id="breadcrumb-home-icon" />			      
      </a>
      <a
        color="inherit"
        href="/medicos"
        onClick={() => handleClick("/medicos")}
        className="breadcrumb-link"
      >
        Médicos(as)
      </a>
    </Breadcrumbs>
		<div id="medicos-content">
      <h2 className="h2-gray-center">Médicos(as)</h2>
      <div id="medicos-list-container">
				<div id="inputs-filter">
					<ThemeProvider theme={theme}>
						<TextField 
							id="input"
							placeholder="Nome do Médico(a)" 
							onChange={handleChangeFilter}
						/>
					</ThemeProvider>
				</div>
        {isMobile ?
          <ul id="card-list-mobile"> 
            {
              medicos
                .map((row, index) => (
                  <li 
                    key={row.ds_codigo_prof}
                    value={row.ds_codigo_prof}
                    className={`row-card-mobile${index % 2 === 0 ? "" : "-odd"}`}
                  >
                    <p className="title-card-mobile"><strong>{row.nm_pessoa_fisica}</strong></p>
                    <p className="subtitle-card-mobile"><i>{row.ds_especialidade} | CRM: {row.ds_codigo_prof}</i></p>
                    <div className="inline-content-card-mobile">
                      <p>
                        Ipiranga: {row.ip === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Pompeia: {row.po === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Santana: {row.st === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                      <p>
                        Centro Médico: {row.cm === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}
                      </p>
                    </div>
                  </li>
                ))
            }
            <div id="footer-table-page">
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={paginas.total}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={nomeFiltered.length <= 0 ? handlePageClick : handleFilteredPageClick}
                containerClassName={'pagination'}
                activeClassName={showRowTable}
              />
            </div>
          </ul>
        :
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell id="table-cell-3"><b>Médico(a)</b></StyledTableCell>
                <StyledTableCell align="center"><b>CRM</b></StyledTableCell>
                <StyledTableCell align="center"><b>Especialidade</b></StyledTableCell>
                <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
                <StyledTableCell align="center"><b>Centro Médico</b></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {	
                medicos
                  .map((row) => (
                  <StyledTableRow value={row.nm_pessoa_fisica} key={row.ds_codigo_prof}>
                    <StyledTableCell component="th" scope="row">
                      {row.nm_pessoa_fisica}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.ds_codigo_prof}</StyledTableCell>
                    <StyledTableCell align="center">{row.ds_especialidade}</StyledTableCell>
                    <StyledTableCell align="center">{row.ip === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.po === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.st === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{row.cm === 1 ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                  </StyledTableRow>
                ))
				      }
            </TableBody>
          </Table>
            <div id="footer-table-page">
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próxima'}
                breakLabel={'...'}
                pageCount={paginas.total}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={nomeFiltered.length <= 0 ? handlePageClick : handleFilteredPageClick}
                containerClassName={'pagination'}
                activeClassName={showRowTable}
              />
            </div>
        </TableContainer>
      }
			</div>
		</div>
   </section>
	 <Footer />
   </>
  )
};

export default Medicos