import React, { useLayoutEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import {
  CardMedia,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Breadcrumbs,
  Button,
  Link
  
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { ExpandMore } from "@material-ui/icons";
import Modal from "react-bootstrap/Modal";
import { useHistory, useParams, useLocation } from "react-router-dom";
import UnidadesComponent from "../../components/Unidades/Unidades.component";
import "./Unidades.style.scss";
import Header from "../../components/Header/Header.component";
import Footer from "../../components/Footer/Footer.component";
import MenuRapido from "../../components/MenuRapido/MenuRapido.component";
import TituloPrincipal from "../../components/TituloPrincipal/TituloPrincipal.component";
import parse from "html-react-parser";
import unidadesData from "./unidadesData";
import TourIcon from '../../images/icons/360.svg'
import TelIcob from '../../images/icons/icon_tel.png'
import ModalGranjaViana from "../../components/ModalGranjaViana/ModalGranjaViana.component";
import imgUnidadeGranja2 from "../../images/images/unidades/granja/cuidados-paliativos.webp";
import imgUnidadeGranja4 from "../../images/images/unidades/granja/reabilitacao-fisica.webp";
import imgUnidadeGranja5 from "../../images/images/unidades/granja/horta-urbana.webp";
import imgBotaoEbook from '../../images/images/unidades/granja/e_book.webp'
import imgSalaVacina from '../../images/images/unidades/pompeia/sala-vacinacao-img1.webp'
import ReactGA from "react-ga4";

const Unidades = () => {
  let history = useHistory();
  const { unidade } = useParams();
  const location = useLocation();
  const unidadeFiltered = unidadesData.find((el) => el.nomeUrl === unidade);
  const [openModal, setOpenModal] = useState(false);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleClick = (path) => {
    history.push(path);
  };

  const handleShowModal = () => {
    setOpenModal(true);
    ReactGA.event({
      category: 'Btn_Formulario_Granja',
      action: 'Clique',
      label: 'Btn_Redireciona_Form_Granja',
    })
  };

  const btnTour={
    color: 'white', background: '#C30009', textTransform: "none", fontSize:"18px"
  }

  const btnPali={
    color: '#b745a0', background: '#FFFFFF', textTransform: "none", fontSize:"18px",
    fontWeight: "bold"
  }

  const btnSalaVacina={
    color: '#FFFFFF', background: '#C30009', textTransform: "none", fontSize:"18px",
    fontWeight: "bold"
  }

  const btnRein={
    color: '#f9b109', background: '#FFFFFF', textTransform: "none", fontSize:"18px",
    fontWeight: "bold"
  }

  return (
    <>
    {
      unidadeFiltered.nomeUrl ==='granja-viana' ? window.location.replace('https://granjaviana.hospitalsaocamilosp.org.br/') :
      <main>
        <Header />
        <div className="modalHome">
          <Modal show={openModal} onHide={() => setOpenModal(false)}>
            <ModalGranjaViana />
          </Modal>
        </div>
        <MenuRapido />
        {unidadeFiltered ? (
          <section className="unidade-container">
            <div className="div-unidade-container-bg">
              <div className="div-unidade-container">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="breadcrumb-container"
                  separator="›"
                >
                  <a
                    color="inherit"
                    href="/"
                    onClick={() => handleClick("/")}
                    className="breadcrumb-link"
                  >
                    <HomeIcon id="breadcrumb-home-icon" />
                  </a>
                  <a
                    color="inherit"
                    href="/unidades"
                    onClick={() => handleClick("/unidades")}
                    className="breadcrumb-link"
                  >
                    Unidades
                  </a>
                </Breadcrumbs>
                <TituloPrincipal
                  titulo={`Unidade ${
                    unidadeFiltered.nome.charAt(0).toUpperCase() +
                    unidadeFiltered.nome.slice(1)
                  }`}
                />
                <div className="carousel-container">
                  <Carousel id="carousel">
                    {unidadeFiltered.imagens.map((element) => (
                      <CardMedia
                        className="image"
                        image={element.imagem}
                        alt={element.alt}
                      />
                    ))}
                  </Carousel>
                </div>
                <div className="unidade-description-container">
                  <Typography>
                    {parse(unidadeFiltered.descricao)}
                    {unidadeFiltered.nomeUrl === "granja-viana" ? (
                      <div>
                      
                      <ul className="btns-container">
                      <li className="btn-li">
                            <Button 
                            style={btnTour} 
                            className="folder-btn" 
                            onClick={handleShowModal}
                          >
                            <img src={TelIcob} style={{paddingRight: "5px", width:"40px"}} alt="Entre em contato"/>
                            Entre em contato
                            </Button>
                        </li>
                        <li className="btn-li">
                          <Button
                            style={btnTour} 
                            className="folder-btn" 
                            variant="contained" 
                            href="https://www.tourbrasil360.com/hospital/sao-camilo/granja-viana/" 
                            target="_blank"
                            onClick= {ReactGA.event({
                              category: 'Tour_Unidade',
                              action: 'Clique',
                              label: 'Cliques em Tour pela unidade',
                            })}>
                            <img src={TourIcon} style={{paddingRight: "5px", width:"40px"}} alt="Tour virtual"/>
                            Tour virtual pela Unidade
                          </Button>
                        </li>
                      </ul>

                      <p><b>Conhe&ccedil;a nossas linhas de cuidado e instala&ccedil;&otilde;es:</b></p> <p>Nossa equipe est&aacute; plenamente capacitada a gerir todos os cuidados requeridos e necess&aacute;rios para cada paciente. Isso come&ccedil;a com uma avalia&ccedil;&atilde;o abrangente das necessidades de assist&ecirc;ncia espec&iacute;ficas do indiv&iacute;duo, incluindo os campos f&iacute;sico, ps&iacute;quico e social.</p><p>Acesse abaixo os materiais sobre as nossas linhas de cuidados, al&eacute;m do e-book com todas as informa&ccedil;&otilde;es sobre a Unidade Granja Viana.</p>
                      
                      </div>
                        ):(<></>)}
                  </Typography>
                </div>
                <div className="accordion-container">
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel2a-header"
                      >
                        <Typography>Cuidados Paliativos e Longa Permanência</Typography>
                        {ReactGA.event({
                          category: 'Acord_Cuid_Paliativos',
                          action: 'Clique',
                          label: 'Acordeao_Cuid_Paliativos',
                        })}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          justifyContent: "flex-start",
                          background: "#B745A0",
                          color: "white",
                        }}
                      >
                        <Typography>
                          {/*{parse(unidadeFiltered.paliativo)}, */}
                          <div id="posiciona1">
                            <p>
                            Cuidados paliativos são um conjunto de ações voltadas para proporcionar bem-estar e dignidade
                            ao indivíduo na fase final da vida. O foco é o alívio do sofrimento físico, psicológico e espiritual,
                            sempre respeitando os desejos e as escolhas do residente. Proporcionar o máximo de consciência
                            e autonomia ao indivíduo significa garantir o exercício de direitos que são inalienáveis a qualquer
                            ser humano.
                            </p>
                            <p>
                            Longa Permanência é um serviço para pacientes que precisam de cuidados de enfermagem 24h
                            por dia em um ambiente totalmente adaptado, seguro e confortável e com equipe especializada
                            sempre disponível para acolher qualquer necessidade.
                            </p>
                            <Button
                                style={btnPali} 
                                className="folder-btn1" 
                                variant="contained" 
                                href="https://hospitalsaocamilosp.org.br/arquivos/folder-cuidados-paliativos-e-longa-permanência.pdf" 
                                target="_blank"
                                >
                                Saiba mais
                            </Button>
                            {/*<p
                              className="unidadesSaibaMais"
                              onClick={handleShowModalPaliativos}
                            >
                              Saiba mais &#62;
                          </p>*/}
                          </div>
                          <div id="posiciona">
                            {
                              <img
                                src={imgUnidadeGranja2}
                                alt="Unidade Granja Viana"
                              />
                            }
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel3a-header"
                      >
                        <Typography>Reabilitação Física</Typography>
                        {ReactGA.event({
                          category: 'Acord_Reab_Fisica',
                          action: 'Clique',
                          label: 'Acordeao_Reab_Fisica',
                        })}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          justifyContent: "flex-start",
                          background: "#F9B109",
                          color: "white",
                        }}
                      >
                        <Typography>
                          {/*{parse(unidadeFiltered.fisica)}, */}
                          <div id="posiciona1">
                            <p>
                              A Reabilitação Física tem os seus principais enfoques no diagnóstico e manejo das incapacidades,
                              bem como na prevenção da instalação de novas deficiências, voltando-se não apenas à
                              doençaa e suas causas como também aos seus efeitos e consequências.
                            </p>
                            <p>
                              A reabilitação possibilita que o paciente atinja o máximo de seu potencial funcional, psicossocial
                              e vocacional para retomar da melhor forma possível a sua rotina familiar, social e profissional. O
                              objetivo é proporcionar o máximo possível de independência, bem-estar e qualidade de vida.
                            </p>
                            <Button
                                style={btnRein} 
                                className="folder-btn1" 
                                variant="contained" 
                                href="https://hospitalsaocamilosp.org.br/arquivos/folder-reabilitação-fisica.pdf" 
                                target="_blank"
                                >
                                Saiba mais
                            </Button>
                          </div>
                          <div id="posiciona">
                            {
                              <img
                                src={imgUnidadeGranja4}
                                alt="Unidade Granja Viana"
                              />
                            }
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    // <Accordion className="accordion">
                    //   <AccordionSummary
                    //     expandIcon={<ExpandMore />}
                    //     aria-controls="panel1a-content"
                    //     id="panel1a-header"
                    //   >
                    //     <Typography>Estrutura, instalações e serviços</Typography>
                    //     {ReactGA.event({
                    //       category: 'Acord_Estrutura',
                    //       action: 'Clique',
                    //       label: 'Acordeao_Estrutura',
                    //     })}
                    //   </AccordionSummary>
                    //   <AccordionDetails style={{ justifyContent: "flex-start" }}>
                    //     <Typography>
                    //       {parse(unidadeFiltered.estrutura)}
                    //       {/*
                    //         <img
                    //           src={imgUnidadeGranja1}
                    //           alt="Unidade Granja Viana"
                    //         />
                    //       */
                    //         <Carousel1>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src= {imgUnidGranjaPrincipal}
                    //               alt="First slide"
                    //             />
                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>UNIDADE</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja2}
                    //               alt="Second slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>RECEPÇÃO</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja3}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>CAPELA</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja1}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>LEITO</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja7}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>JARDIM</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja6}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>ACADEMIA</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja4}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>GINÁSIO</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //           <Carousel1.Item>
                    //             <img
                    //               className="d-block w-100"
                    //               src={imgUnidGranja5}
                    //               alt="Third slide"
                    //             />

                    //             <Carousel1.Caption>
                    //               <div className="div-preview-text"> <strong>HORTA URBANA</strong></div>
                    //             </Carousel1.Caption>
                    //           </Carousel1.Item>
                    //         </Carousel1>
                    //       }
                          
                    //     </Typography>
                    //   </AccordionDetails>
                    // </Accordion>
                    <></>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Horta Urbana</Typography>
                        {ReactGA.event({
                          category: 'Acord_Horta_Urbana',
                          action: 'Clique',
                          label: 'Acordeao_Horta_Urbana',
                        })}
                      </AccordionSummary>
                      <AccordionDetails style={{ justifyContent: "flex-start" }}>
                        <Typography>
                          {/*parse(unidadeFiltered.horta)*/}
                          <div id="posiciona2">
                            <p>
                              Temos a primeira Horta Urbana Hospitalar do Brasil.
                              Numa &aacute;rea de 16 mil metros quadrados a Horta
                              &eacute; destinada ao cultivo de diversos produtos
                              sem agrot&oacute;xicos, seguindo o modelo da
                              agroecologia. S&atilde;o 30 esp&eacute;cies de PANC
                              (Plantas Aliment&iacute;cias N&atilde;o
                              Convencionais), al&eacute;m de mais de 85 variedades
                              de verduras, ervas arom&aacute;ticas, legumes e
                              frutas. O cuidado com a qualidade de vida e o
                              bem-estar integra uma s&eacute;rie de
                              a&ccedil;&otilde;es de preven&ccedil;&atilde;o e
                              promo&ccedil;&atilde;o &agrave; sa&uacute;de, tendo
                              como pilar o est&iacute;mulo &agrave;
                              alimenta&ccedil;&atilde;o saud&aacute;vel. Sendo
                              assim, todas as verduras s&atilde;o produzidas na
                              horta e a alimenta&ccedil;&atilde;o n&atilde;o
                              possui tempero artificial, o que proporciona uma
                              dieta mais equilibrada. Os alimentos s&atilde;o
                              usados integralmente, incluindo cascas e talos, com
                              o objetivo de aproveitar o m&aacute;ximo de cada
                              ingrediente para promover a sa&uacute;de de forma
                              ampla. As a&ccedil;&otilde;es n&atilde;o apenas
                              viabilizam a oferta de alimentos mais
                              saud&aacute;veis a todos, mas tamb&eacute;m
                              contribuem com o meio ambiente, atraindo a
                              aten&ccedil;&atilde;o do Greenpeace.
                            </p>
                          </div>
                          <div id="posiciona">
                            {
                              <img
                                src={imgUnidadeGranja5}
                                alt="Unidade Granja Viana"
                              />
                            }
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Localização</Typography>
                        {ReactGA.event({
                          category: 'Acord_Localizacao',
                          action: 'Clique',
                          label: 'Acordeao_Localizacao',
                        })}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div id="localizacao-container">
                          <Typography>
                            {parse(unidadeFiltered.localizacao)}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "granja-viana" ? (
                    
                    <Typography>
                      <br></br>
                        {parse(unidadeFiltered.complemento)}
                        
                        <Button 
                            style={btnTour} 
                            className="folder-btn2" 
                            onClick={handleShowModal}
                          >
                            Agende uma visita
                          </Button>
                          <div id="posiciona_btn">
                            {
                              <Link href="https://www.hospitalsaocamilosp.org.br/arquivos/e-book_granja.pdf" target="_blank">
                                <img
                                  
                                  src={imgBotaoEbook}
                                  alt="Unidade Granja Viana"

                                />
                              </Link>
                            }
                        </div>
                    </Typography>
                    
                    




                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "pompeia" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel6a-header"
                      >
                        <Typography>Sala de Vacinação</Typography>
                        {ReactGA.event({
                          category: 'Acord_Cuid_Paliativos',
                          action: 'Clique',
                          label: 'Acordeao_Cuid_Paliativos',
                        })}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          justifyContent: "flex-start",
                          background: "white",
                          color: "#000",
                        }}
                      >
                        <Typography>
                          <div id="posiciona2">
                            <p style={{color: "#696969"}}>
                            Reforçando a missão institucional de cuidar da vida, a Rede de Hospitais São Camilo implementou, 
                            na Unidade Pompeia, um espaço exclusivo para oferecer o serviço de vacinação, que contempla todas 
                            as faixas etárias, de crianças até adultos.
                            </p>
                            <Button
                                style={btnSalaVacina} 
                                className="folder-btn1" 
                                variant="contained" 
                                onClick={() => handleClick("/unidades/pompeia/sala-de-vacinacao")}
                                >
                                Saiba mais
                            </Button>
                          </div>
                          <div id="posiciona">
                            {
                              <img
                                src={imgSalaVacina}
                                alt="Unidade Pompeia"
                              />
                            }
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "jacana" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Localização</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div id="localizacao-container">
                          <Typography>
                            {parse(unidadeFiltered.localizacao)}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "pompeia" ||
                  unidadeFiltered.nomeUrl === "santana" ||
                  unidadeFiltered.nomeUrl === "ipiranga" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Estrutura</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ justifyContent: "flex-start" }}>
                        <Typography>
                          {parse(unidadeFiltered.estrutura)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                  {unidadeFiltered.nomeUrl === "pompeia" ||
                  unidadeFiltered.nomeUrl === "santana" ||
                  unidadeFiltered.nomeUrl === "ipiranga" ? (
                    <Accordion className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Localização</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div id="localizacao-container">
                          <Typography>
                            {parse(unidadeFiltered.localizacao)}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <></>
                  )}

                
                </div>
              </div>
            </div>
          </section>
        ) : (
          <UnidadesComponent />
        )}
        <Footer />
      </main>
    }
    </>
  );
};

export default Unidades;
