import React from "react";
import Modal from "react-bootstrap/Modal";
import FormGranja from "../../components/FormGranja/FormGranja.component";

import "./ModalGranjaViana.style.scss";

const ModalGranjaViana = () => {
  return (
    <>
      {/* <Modal.Header closeButton style={{backgroundColor: '#c3000a'}}> */}
      {/* <Modal.Title>Comunicado</Modal.Title> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ backgroundColor: "#c3000a" }}>
        <FormGranja />
        {/*COMPONENTE FORMGRANJA*/}
      </Modal.Body>
    </>
  );
}

export default ModalGranjaViana;
