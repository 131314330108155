import React, { useEffect } from 'react';

import './Home.style.scss'

import Header from '../../components/Header/Header.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import Footer from '../../components/Footer/Footer.component';

import Banner from '../../components/BannerPrincipal/Banner.component';
import MenuCentral from '../../components/MenuCentral/MenuCentral.component';
import NovidadesBootstrap from '../../components/NovidadesBootstrap/NovidadesBootstrap.component';
// import ModalHome from '../../components/ModalHome/ModalHome.component'
import UnidadesComponent from '../../components/Unidades/Unidades.component';
import CookiesComponent from '../../components/Cookies/Cookies.component';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <main>
            {/* <ModalHome /> */}
            <Header />
            <MenuRapido />
            <Banner />
            <MenuCentral />
            <CookiesComponent />
            <NovidadesBootstrap />
            <UnidadesComponent />
            <Footer />
        </main>
    )
}

export default Home