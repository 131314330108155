import React, { useLayoutEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import { useHistory, useLocation } from 'react-router-dom';

import './ProntoSocorro.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';

import parse from 'html-react-parser';

import prontoSocorroData from './ProntoSocorroData.js'

const ProntoSocorro = () => {
    let history = useHistory();
    const location = useLocation();
    const prontoSocorroFiltered = prontoSocorroData.find(el => el.nomeUrl.toLowerCase() === 'pronto-socorro');

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        history.push(path);
    }
    
    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="servicos-container">
                <div className="div-servicos-container-bg">
                    <div className="div-servicos-container">
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <div color="inherit" className="breadcrumb-link">
                                Pronto Socorro
                            </div>
                        </Breadcrumbs>
                        <TituloPrincipal titulo={prontoSocorroFiltered.nome} />
                        <div className="servicos-description-container">
                            {parse(prontoSocorroFiltered.descricao)}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default ProntoSocorro