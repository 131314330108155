import React, { useEffect, useState } from 'react';

import SearchComponent from '../Search/Search.component';

import './Banner.style.scss'
import imagens from './imagesRandom.js';

function randomBackgroundImage() {
    return imagens[Math.floor(Math.random() * 6)]
}

const Banner = () => {
    const [imagemBanner, setImagemBanner] = useState(false)

    useEffect(() => {
        setImagemBanner(randomBackgroundImage());
    }, [imagemBanner])

    const divBackStyle = {
        backgroundImage: `url(${imagemBanner.imgblur})`
    };

    return (
        <section id="banner-container" style={divBackStyle}>
            <SearchComponent />
            <img className="banner-image" id="home-banner" src={imagemBanner.img} alt={imagemBanner.alt }  /> 
        </section>
    )
}

export default Banner