import React, { useLayoutEffect } from 'react';
import { CardMedia, Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import { useHistory, useParams, useLocation } from 'react-router-dom';

import './DuvidasFrequentes.style.scss'

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';
import parse from 'html-react-parser';
import duvidasFrequentesData from './duvidasFrequentesData.js'
import SAC from '../SAC/SAC.page.jsx';

const DuvidasFrequentes = () => {
    let history = useHistory();
    const { duvida } = useParams();
    const location = useLocation();
    const duvidasFrequentesFiltered = duvidasFrequentesData.find(el => el.nomeUrl.toLowerCase() === duvida);

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const goToSAC = () => {
        window.location.href = '/sac';
    }

    const handleClick = (path) => {
        history.push(path);
    }
    console.log(duvidasFrequentesFiltered)
    return (
        duvidasFrequentesFiltered.nomeUrl === "sac" ?
         goToSAC()
          :
        <main>
            <Header />
            <MenuRapido />
            <section className="duvidas-container">
                <div className="div-duvidas-container-bg">
                    <div className="div-duvidas-container">
                        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›" >
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon" />
                            </a>
                            <a color="inherit" href="/duvidas-frequentes" onClick={() => handleClick("/duvidas-frequentes")} className="breadcrumb-link" >
                                Dúvidas Frequentes
                            </a>
                        </Breadcrumbs>
                        <TituloPrincipal titulo={duvidasFrequentesFiltered.nome} />
                        <div className="duvidas-card-container">
                            {
                                duvidasFrequentesFiltered.imagem !== null ?
                                    <CardMedia className="duvidas-card-container-image" image={duvidasFrequentesFiltered.imagem} alt={duvidasFrequentesFiltered.alt} />
                                    :
                                    ''
                            }
                        </div>
                        <div className="duvidas-tab-container">
                            {parse(duvidasFrequentesFiltered.conteudo)}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default DuvidasFrequentes