import React, { useLayoutEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import { useHistory, useLocation } from 'react-router-dom';
import './ResultadoDeExames.style.scss'
import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import TituloPrincipal from '../../components/TituloPrincipal/TituloPrincipal.component';
import bannerMatrix from '../../images/images/resultado-exames/banner-matrix.jpg'
import bannerPortalPaciente from '../../images/images/resultado-exames/banner-portal-paciente.jpg'

const ResultadoDeExames = () => {
    let history = useHistory();
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        path.includes('http') ? window.location.href = path : history.push(path);
    }
    return (
        <main>
            <Header />
           { /*<ModalAgendamento /> */}
            <MenuRapido />
            <section id="unidadesTriagem">
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-container" separator="›">
                    <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                        <HomeIcon id="breadcrumb-home-icon" />
                    </a>
                    <a color="inherit" href="/resultados-exames" onClick={() => handleClick("/resultados-exames")} className="breadcrumb-link">
                        Resultados de Exames
                    </a>
                </Breadcrumbs>
                <TituloPrincipal titulo='Resultados de Exames' />

                <div className='content-shift'>
                    <p>Olá!</p>

                    <p>Temos um novo sistema para consultar resultados de exames feitos <strong>a partir de
                    10/07/2024.</strong></p>
                    
                    <a rel="noreferrer" href='https://saocamilo.shiftcloud.com.br/shift/lis/saocamilo/elis/s01.iu.web.Login.cls' target='_blank' className='button-shift'>Clique aqui para acessar os resultados a partir de 10/07/2024</a>

                    <p>Agora se você realizou exames em <strong>datas anteriores ao dia 10/07/2024</strong>,
                    permanece o mesmo link para acessar os resultados nesta página</p>
                </div>
               
                <div className="cardsTriagemCol-group">
                <div className="cardsTriagem-group">
                    <div className="unidadeTriagem-description-container">
                        <a href='https://matrixnet.hospitalsaocamilosp.org.br:8081/matrixnet/wfrmLogin.aspx' target='_blank' rel='noreferrer' >
                            <img src={bannerMatrix} alt="Banner-Matrix" className='img-banner-marcacao'/>  
                        </a>                      
                    </div>

                    <div className="unidadeTriagem-description-container">
                        <a href='https://portalpaciente.hospitalsaocamilosp.org.br/' target='_blank' rel='noreferrer' >
                            <img src={bannerPortalPaciente} alt="Banner-Portal" className='img-banner-marcacao'/>  
                        </a>                      
                    </div>
                </div>
                    <p className='unidadeTriagem-card-title'>
                        <a  target='_blank' rel='noreferrer' href="https://www.hospitalsaocamilosp.org.br/arquivos/tabela_exames_hsc.pdf">
                        Consulte aqui
                        </a> a lista de exames que <strong>não</strong> estão disponíveis no Portal do Paciente.
                    </p>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default ResultadoDeExames