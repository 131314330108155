import React, { useState, useEffect } from 'react';
import { Breadcrumbs, 
	Table, TableCell, 
	Paper, TableHead, 
	TableRow, TableBody, 
	TableContainer, 
	FormControl, InputLabel,
 Select, MenuItem } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from 'react-responsive';
import Modal from "react-bootstrap/Modal";
import enviroments from './enviroments.js';
import BannerMediservice from '../../images/images/planos/banner-agendamento-mediservice.jpg'

import './Cobertura.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C3000A",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: "15px 10px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const axios = require('axios');

const initialStatePlano = {
  "Sigla": "",
	"PlanoSaudeId": "",
	"Convenio": "",
	"Nome": "",
	"InternacaoUrgenciaPompeia": "",
	"InternacaoUrgenciaSantana": "",
	"InternacaoUrgenciaIpiranga": "",
	"InternacaoUrgenciaCentroMedico": "",
	"InternacaoEletivaPompeia": "",
	"InternacaoEletivaSantana": "",
	"InternacaoEletivaIpiranga": "",
	"InternacaoEletivaCentroMedico": "",
	"PSAdultoPompeia": "",
	"PSAdultoSantana": "",
	"PSAdultoIpiranga": "",
	"PSAdultoCentroMedico": "",
	"PSInfantilPompeia": "",
	"PSInfantilSantana": "",
	"PSInfantilIpiranga": "",
	"PSInfantilCentroMedico": "",
	"ExamesPompeia": "",
	"ExamesSantana": "",
	"ExamesIpiranga": "",
	"ExamesCentroMedico": "",
	"ConsultasPompeia": "",
	"ConsultasSantana": "",
	"ConsultasIpiranga": "",
	"ConsultasCentroMedico": "",
	"QuimioterapiaAmbulatorialPompeia": "",
	"QuimioterapiaAmbulatorialSantana": "",
	"QuimioterapiaAmbulatorialIpiranga": "",
	"QuimioterapiaAmbulatorialCentroMedico": "",
	"ColetaExamesLaboratoriaisPompeia": "",
	"ColetaExamesLaboratoriaisSantana": "",
	"ColetaExamesLaboratoriaisIpiranga": "",
	"ColetaExamesLaboratoriaisCentroMedico": "",
	"Observacao": "",
	"DataAtualizacao": "2020-01-23 14:33:02.547"
	}

const Cobertura = () => {
  let history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const [ planos, setPlanos ] = useState([]);
  const [ modalAviso, setModalAviso ] = useState(true);

	const [ showTable, setShowTable ] = useState(false);
  const planosDistinct = planos.map(item => item.Sigla).filter((value, index, self) => self.indexOf(value) === index).sort();
  const [ convenio, setConvenio ] = useState('');
	const [ openConvenio, setOpenConvenio ] = useState(false);
  const [ plano, setPlano ] = useState('');
	const [ openPlano, setOpenPlano ] = useState(false);
	const [ planoSelected, setPlanoSelected ] = useState (initialStatePlano);
  const [ showModal, setShowModal ] = useState(false);

  const handleClick = path => history.push(path);
	const cleanPlanoSelected = () => setPlanoSelected(initialStatePlano);
  const handleConvenioSelected = event => {
		setShowTable(false);
		setConvenio(event.target.value);
    setShowModal(false)
    setPlano('')
	}
	const handlePlanoSelected = event => {
		setShowTable(false);
		setPlano(event.target.value);
	}
	
	const handleSearchClick = () => {
    if(plano === '') {
      setShowModal(true) 
    } else {
      cleanPlanoSelected();
      setPlanoSelected(planos.find(el => el.Sigla === convenio && el.Nome === plano));
      setShowTable(true);
    }		
	}  

  const handleClose = () => {
    setShowModal(false);
  }

  useEffect(() => {
    async function getPlanos() {
      try {
        const { data } = await axios.get(enviroments.prd, { 
          headers: {
              token: enviroments.token 
          }
        });
        setPlanos(data);
      } catch (error) {
         console.log(error)
        }   
    }

    getPlanos();
  }, []);

  function fecharModal() {
    setModalAviso(!modalAviso);
  }

  console.log(planoSelected)

  return (
    <>
     { modalAviso ?
      <div class="feedback-message">
        <div class="message-container">
            <img src={BannerMediservice} class="image" alt="error" />
            <button onClick={() => fecharModal()}>FECHAR</button>
        </div>  
      </div>
      : null
      } 
    <Header />
    <MenuRapido />
    <section id="cobertura-page-section"> 
    <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-cobertura">			      
      <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">				      
        <HomeIcon id="breadcrumb-home-icon" />			      
      </a>
      <a
        color="inherit"
        href="/planos-de-saude"
        onClick={() => handleClick("/planos-de-saude")}
        className="breadcrumb-link"
      >
        Planos de Saúde
      </a>
    </Breadcrumbs>
    {planos.length <= 0 ? <div class="loader-container"> <span class="loader"></span></div> :
    
    <div id="Cobertura-content">
      
      <div id="planos-list-container">
      <h2 className="h2-gray-center">Cobertura Planos de Saúde</h2>
        <p>A Rede de Hospitais São Camilo de São Paulo atende uma ampla lista de planos de saúde. Consulte a relação e verifique a cobertura oferecida. Cada Operadora de Saúde possui um serviço diferenciado, portanto, verifique em qual unidade o seu plano é aceito e se o mesmo possui cobertura para o serviço desejado.</p>
        <div id="planos-list-combo-container">
          <FormControl className="select-dropdown input-small-2">
            <InputLabel required id="demo-controlled-open-select-label">Convênio</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openConvenio}
              onClose={() => setOpenConvenio(false)}
              onOpen={() => setOpenConvenio(true)}
              value={convenio}
              onChange={handleConvenioSelected}
              defaultValue=""
            >
              {
                planosDistinct.sort().map((el, index)  => 
                  <MenuItem key={el + index} value={el}>{el}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          <FormControl className="select-dropdown input-small-2">
            <InputLabel required id="demo-controlled-open-select-label">Plano</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openPlano}
              onClose={() => setOpenPlano(false)}
              onOpen={() => setOpenPlano(true)}
              value={plano}
              onChange={handlePlanoSelected}
              defaultValue=""
            >
              {
                planos.filter(el => el.Sigla === convenio).sort((a, b) => { return a.Nome.localeCompare(b.Nome)}).map(el => 
                  <MenuItem key={`${el.Nome + el.PlanoSaudeId + el.DataAtualizacao}`} value={el.Nome}>{el.Nome}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>IMPORTANTE!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Selecione um plano para prosseguir.</p>            
            </Modal.Body>
          </Modal>
          <SearchIcon id="search-icon" onClick={handleSearchClick} />
        <></></div>
      {
        planoSelected.PlanoSaudeId === "2D84A79C-FF07-4FA1-94DC-C9039B2210D5" ?
        (
          <div>
            <p style={{fontWeight: 'bold'}}>OBS: O ATENDIMENTO SOMENTE DEVERÁ SER PRESTADO AOS PACIENTES QUE CONSTAR NA CARTEIRINHA A LETRA Q (INDICADA NO VERSO DO CARTÃO).</p>
          </div>
        ) : null
      }
      {
        showTable ? 
          isMobile ?
            <ul id="card-list-mobile"> 
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile`}
              >
                <p className="title-card-mobile"><strong>Internação Urgência</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.InternacaoUrgenciaIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.InternacaoUrgenciaPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.InternacaoUrgenciaSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.InternacaoUrgenciaCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile-odd`}
              >
                <p className="title-card-mobile"><strong>Internação Eletiva</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.InternacaoEletivaIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.InternacaoEletivaPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.InternacaoEletivaSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.InternacaoEletivaCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile`}
              >
                <p className="title-card-mobile"><strong>Pronto Socorro Adulto</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.PSAdultoIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.PSAdultoPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.PSAdultoSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.PSAdultoCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile-odd`}
              >
                <p className="title-card-mobile"><strong>Pronto Socorro Infantil</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.PSInfantilIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.PSInfantilPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.PSInfantilSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.PSInfantilCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile`}
              >
                <p className="title-card-mobile"><strong>Exames de Imagem</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.ExamesIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.ExamesPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.ExamesSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.ExamesCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile-odd`}
              >
                <p className="title-card-mobile"><strong>Consultas</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.ConsultasIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.ConsultasPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.ConsultasSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.ConsultasCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile`}
              >
                <p className="title-card-mobile"><strong>Quimioterapia</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.QuimioterapiaAmbulatorialIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.QuimioterapiaAmbulatorialPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.QuimioterapiaAmbulatorialSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.QuimioterapiaAmbulatorialCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <li 
                key={planoSelected.nome}
                className={`row-card-mobile-odd`}
              >
                <p className="title-card-mobile"><strong>Exames laboratoriais</strong></p>
                <div className="inline-content-card-mobile">
                  <p>Ipiranga: {planoSelected.ColetaExamesLaboratoriaisIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Pompeia: {planoSelected.ColetaExamesLaboratoriaisPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  <p>Santana: {planoSelected.ColetaExamesLaboratoriaisSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p>
                  {/* <p>Centro Médico: {planoSelected.ColetaExamesLaboratoriaisCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</p> */}
                </div>
              </li>
              <h8 className="h2-gray-center">Para informações quanto a cobertura, entrar em contato com nossa Central de Agendamento - (11) 3172-6800</h8>
            </ul>
            :
            <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell id="table-cell-3"><b>Especialidade</b></StyledTableCell>
                    <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                    <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                    <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
                     {/* <StyledTableCell align="center"><b>Centro Médico</b></StyledTableCell> */}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Internação Urgência
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Internação Eletiva
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoEletivaIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoEletivaPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.InternacaoEletivaSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.InternacaoEletivaCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Pronto Socorro Adulto
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSAdultoIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSAdultoPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSAdultoSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.PSAdultoCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Pronto Socorro Infantil
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSInfantilIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSInfantilPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.PSInfantilSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.PSInfantilCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                    Exames de Imagem
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ExamesIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ExamesPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ExamesSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.ExamesCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Consultas
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ConsultasIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ConsultasPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ConsultasSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.ConsultasCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                      Quimioterapia
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                     {/* <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                  <StyledTableRow key={planoSelected.Nome}>
                    <StyledTableCell component="th" scope="row">
                    Exames laboratoriais
                    </StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisIpiranga ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisPompeia ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisSantana ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell>
                    {/* <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisCentroMedico ? <CheckIcon id="check-green-icon"/> : <ClearIcon id="decline-red-icon"/>}</StyledTableCell> */}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <h8 className="h2-gray-center">Para informações quanto a cobertura, entrar em contato com nossa Central de Agendamento - (11) 3172-6800</h8>
            </>
      :''
      }
      </div>
    </div>		
    }
    </section>
    
    <Footer/>
    </>
  )
}

export default Cobertura