import React from 'react'
import { useHistory } from 'react-router';

import './ArticlePesquisa.style.scss'

const ArticlePesquisa = props => {
    let history = useHistory();

    const dados = props.dados;
    
    const handleClick = (path) => {
        history.push(path)
    }
   
    return (
        <div className="article-pesquisa-container">
            <a href={dados.link}>
                <img className='blog-img' src={dados.image} alt={dados.title_text} />
            </a>
            <div className='blog-content'>
                <div className="article-pesquisa-title" onClick={() => handleClick(`${dados.link}`)}>
                    <h4 className="h4-gray-left">{dados.title_text}</h4>
                </div>
                <p className="article-pesquisa-preview">
                    <h6 className="h6-gray-left"><strong>Publicado em:</strong> {dados.publish_date}</h6>
                </p>   
                <a className="article-pesquisa-link" href={dados.link}>
                    Acesse a publicação
                </a>
                <p className="article-pesquisa-preview">
                    {dados.preview_text}
                </p>
            </div>
        </div>
    )
}

export default ArticlePesquisa;