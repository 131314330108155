/*import imagem1 from '../../images/images/banner/banner-alvo.webp';*/
import imagem1 from '../../images/images/banner/hsc-1.webp';
import imagem2 from '../../images/images/banner/hsc-2.webp';
import imagem3 from '../../images/images/banner/hsc-3.webp';
import imagem4 from '../../images/images/banner/hsc-4.webp';
import imagem5 from '../../images/images/banner/hsc-5.webp';
import imagem6 from '../../images/images/banner/hsc-6.webp';
/*import imagemblur1 from '../../images/images/banner/banner-alvo-blur.webp';*/
import imagemblur1 from '../../images/images/banner/banner-geriatria-blur.webp';
import imagemblur2 from '../../images/images/banner/banner-pedriatria-blur.webp';
import imagemblur3 from '../../images/images/banner/banner-servicos-para-acompanhantes-blur.webp';
import imagemblur4 from '../../images/images/banner/banner-covid-blur.webp';

const imagens = [
    { img: imagem1, alt: 'Hospital São Camilo - Médico atendendo', imgblur: imagemblur1 },
    { img: imagem2, alt: 'Hospital São Camilo - Geriatria', imgblur: imagemblur2 },
    { img: imagem3, alt: 'Hospital São Camilo - Triagem', imgblur: imagemblur3 },
    { img: imagem4, alt: 'Hospital São Camilo - Recuperação', imgblur: imagemblur4 },
    { img: imagem5, alt: 'Hospital São Camilo - Cirurgia', imgblur: imagemblur1 },
    { img: imagem6, alt: 'Hospital São Camilo - Alimentação', imgblur: imagemblur3 },
]

export default imagens