import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';

import './CanalBioEtica.style.scss'

import logoHSC from '../../images/logos/logodefault.png';
import BannerMobile from './images/banner-mobile.jpeg';
import BannerDesktop from './images/banner-desktop.jpeg';
import PacienteMedico from './images/paciente-medico.png';

import { Principles, SocialMOCK } from './LPData';

const CanalBioEtica = () => {
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const currentURL = window.location.pathname;
    
    console.log(currentURL);

    if(currentURL === "/canal-do-colaborador") {
        document.title = 'Canal do Colaborador - HSC';
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <main className='main'>
            <section id='header'>
                <a href="/">
                    <img className='logo' src={logoHSC} alt="Logo HSC" />
                </a>

                <div className='social'>
                    {SocialMOCK.map(link => 
                        <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                            <img className='social-item-img' src={link.Image} alt={link.name} />
                        </a>
                    )}
                </div>
            </section>

            {isMobile ? 
                <div className='banner-mobile-container'>
                    <img src={BannerMobile} className='banner-mobile' alt='Banner Canal Colaborador - HSC' />

                    <div className='banner-mobile-text'>
                        <h1 className='banner-mobile-title'>Canal de Bioética</h1>
                        <p className='banner-mobile-subtitle red-highlight'>Rede de Hospitais São Camilo de São Paulo</p>
                    </div>
                </div> : 
                <div className='banner-desktop-container'>
                    <img src={BannerDesktop} className='banner-desktop' alt='Banner Canal Colaborador - HSC' />

                    <div className='banner-desktop-text'>
                        <h1 className='banner-desktop-title'>Canal de Bioética</h1>
                        <p className='banner-desktop-subtitle red-highlight'>Rede de Hospitais São Camilo de São Paulo</p>
                    </div>
                </div>
            }

            

            <section className='about'>
               <img src={PacienteMedico} alt="Médico cuidando do Paciente" className='about-img'/>

               <div className='about-text-wrappers'>
                    <p className='about-first-text'>Você sabe o que é</p>

                    <h3 className='about-title red-highlight'>Bioética?</h3>

                    <p className='about-highlight-text'>Bioética pode ser compreendida como a ética a ser adotada na condução da vida.</p>

                    <p className='about-sub-text'>
                        Por essa razão, é uma ciência que na relação entre a vida e a necessária intervenção sobre ela, tem como alguns de seus objetivos: 
                        indicação dos limites das intervenções, explicação de suas finalidades, percepção dos valores evolvidos e promoção da conscientização sobre
                        as possíveis escolhas.
                    </p>
               </div>
            </section>

            <section className='principles'>
                <h4 className='principles-title'>Você conhece os 4 princípios da Bioética?</h4>

                <p className='principles-subtitle'>
                    Ao adotar a ética para conduzir a vida, devem ser observados 04 princípios quando presente uma relação entre a vida e uma necessária intervenção dos homens sobre ela, 
                    sendo eles: <strong className='red-highlight'>autonomia, beneficência, não-maleficência e justiça.</strong>
                </p>
                
                <div className='principles-container'>
                    {Principles.map(item => 
                            <div key={item.id} className='principles-wrapper'>
                                <img className='principles-img' src={item.Image} alt={item.name} />

                                <h4 className='principles-name'>{item.name}</h4>

                                <p className='principles-text'>{parse(item.text)}</p>
                            </div>
                        )
                    }
                </div>
            </section>    

            <section className='contact'>
                <h3 className='contact-title red-highlight'>Comitê de Bioética da Rede de Hospitais São Camilo de São Paulo</h3>

                <div className='contact-text-button-container'>
                    <div className='contact-text-container'>
                        <p className='contact-text-main'>Entre em contato</p>

                        <a href="mailto:delimasbioeticos@hospitalsaocamilosp.org.br">delimasbioeticos@hospitalsaocamilosp.org.br</a>

                        <p className='contact-member'><strong>Presidente:</strong> Amanda S. Domingos</p>

                        <p className='contact-member'><strong>Vice-presidente:</strong> Natali Verdi</p>
                    </div>

                    <a href="https://forms.office.com/pages/responsepage.aspx?id=g1xOAA1Vm0qdgwpLCoNv-X1h_EJpKL5Cu_yVbNM_QlBUNk9RMlBJV08wR0YwRTFaT0FVRTdXSFpBNC4u" target='_blank' className='contact-button' rel="noreferrer">Clique aqui para registrar uma ocorrência</a>
                </div>
            </section>

            <section className='footer'>
                <div className='footer-info-wrapper'>
                    <a href="/" >
                        <img className='logo' src={logoHSC} alt="Logo HSC" />
                    </a>

                    <p className='copyright'>
                        © Copyright 2024 | Sociedade Beneficente São Camilo
                    </p>

                    <div className='social'>
                        {SocialMOCK.map(link => 
                            <a className='social-item' href={link.href} target='_blank'  key={link.name} rel="noreferrer">
                                <img className='social-item-img' src={link.Image} alt={link.name} />
                            </a>
                        )}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CanalBioEtica;