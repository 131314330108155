import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	TextField, Breadcrumbs,
	FormControl, FormLabel,
	RadioGroup, FormControlLabel,
	Radio, Fab, CircularProgress,
	Modal
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { grey, red } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import HomeIcon from '@material-ui/icons/Home'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import InputMask from "react-input-mask";

import config from '../../helpers/config';

import './Orcamento.style.scss';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';


const initialStateOpmes = {
	fornecedor: '',
	modelo: '',
	quantidade: '',
	descricaoMaterial: ''
}

const theme = createMuiTheme({
	palette: {
		primary: grey,
		secondary: red
	},
});

const Orcamento = () => {
	let history = useHistory();
	const { register, handleSubmit, reset } = useForm();
	const [codTuss, setCodTuss] = useState('');
	const [nomeProcedimento, setNomeProcedimento] = useState('');
	const [cobertura, setCobertura] = useState('');
	const [procedimentos, setProcedimentos] = useState('');
	const [opmesItem, setOpmesItem] = useState(initialStateOpmes);
	const [opmesList, setOpmesList] = useState([]);
	//Radio Buttons
	const [unidade, setUnidade] = useState(null);
	const [tipoAnestesia, setTipoAnestesia] = useState(null);
	const [tipoAcomodacao, setTipoAcomodacao] = useState(null);
	const [tipoAcomodacaoQtd, setTipoAcomodacaoQtd] = useState(null);
	const [hemoterapia, setHemoterapia] = useState(null);
	const [hemoterapiaQtd, setHemoterapiaQtd] = useState(null);
	const [anatomiaPatologica, setAnatomiaPatologica] = useState(null);
	const [anatomiaPatologicaTipo, setAnatomiaPatologicaTipo] = useState(null);
	const [congelacao, setCongelacao] = useState(null);
	const [congelacaoQtd, setCongelacaoQtd] = useState(null);
	//Loading
	const [open, setOpen] = useState(false);
	//Alert
	const [showAlert, setShowAlert] = useState(false);
	const [success, setSuccess] = useState(false);
	//Error Control
	const [errTuss, setErrTuss] = useState(false);
	const [errNomeProcedimento, setErrNomeProcedimento] = useState(false);
	const [errCobertura, setErrCobertura] = useState(false);
	const [errModelo, setErrModelo] = useState(false);
	const [errFornecedor, setErrFornecedor] = useState(false);
	const [errQuantidade, setErrQuantidade] = useState(false);
	const [errDescMaterial, setErrDescMaterial] = useState(false);
	const [errTel, setErrTel] = useState(false);
	const [errCelMedico, setErrCelMedico] = useState(false);
	const [errTelMedico, setErrTelMedico] = useState(false);
	const [errUnidade, setErrUnidade] = useState(false);
	const [errAnestesia, setErrAnestesia] = useState(false);
	const [errAcomodacao, setErrAcomodacao] = useState(false);
	const [errTipoAcomodacaoQtd, setErrTipoAcomodacaoQtd] = useState(false);
	const [errHemoterapia, setErrHemoterapia] = useState(false);
	const [errHemoterapiaQtd, setErrHemoterapiaQtd] = useState(false);
	const [errPatologia, setErrPatologia] = useState(false);
	const [errPatologiaQtd, setErrPatologiaQtd] = useState(false);
	const [errCongelacao, setErrCongelacao] = useState(false);
	const [errCongelacaoQtd, setErrCongelacaoQtd] = useState(false);

	const handleClick = path => history.push(path);

	const onSubmit = data => {
		setOpen(true);
		if (data.telefone.trim().length < 13 || data.telefone.trim().length > 14) {
			setOpen(false);
			setErrTel(true);
			return
		} else if (data.celularMedico.trim().length !== 15) {
			setOpen(false);
			setErrCelMedico(true);
			return
		} else if (!unidade) {
			setOpen(false);
			setErrUnidade(true);
			return
		} else if (!tipoAnestesia) {
			setOpen(false);
			setErrAnestesia(true);
			return
		} else if (!tipoAcomodacao) {
			setOpen(false);
			setErrAcomodacao(true);
			return
		} else if (tipoAcomodacao !== null && !tipoAcomodacaoQtd) {
			setOpen(false);
			setErrTipoAcomodacaoQtd(true);
		} else if (!hemoterapia) {
			setOpen(false);
			setErrHemoterapia(true);
			return
		} else if (!anatomiaPatologica) {
			setOpen(false);
			setErrPatologia(true);
			return
		} else if (!congelacao) {
			setOpen(false);
			setErrCongelacao(true);
			return
		} else if (procedimentos === '') {
			setOpen(false);
			alert('O preenchimento de ao menos um procedimento é obrigatório.')
		} else if (hemoterapia === "Sim" && !hemoterapiaQtd) {
			setOpen(false);
			setErrHemoterapiaQtd(true);
		} else if (anatomiaPatologica === "Sim" && !anatomiaPatologicaTipo) {
			setOpen(false);
			setErrPatologiaQtd(true);
		} else if (congelacao === "Sim" && !congelacaoQtd) {
			setOpen(false);
			setErrCongelacaoQtd(true);
		} else {
			setErrTel(false);
			setErrCelMedico(false);
			setErrTelMedico(false);
			setErrUnidade(true);
			setErrAnestesia(false);
			setErrAcomodacao(false);
			setErrHemoterapia(false);
			setErrPatologia(false);
			setErrCongelacao(false);

			console.log({
				...data,
				procedimentosLista: procedimentos,
				unidade: unidade,
				tipoAnestesia: tipoAnestesia,
				tipoAcomodacao: tipoAcomodacao,
				hemoterapia: hemoterapia,
				hemoterapiaQtd: hemoterapiaQtd,
				anatomiaPatologica: anatomiaPatologica,
				anatomiaPatologicaTipo: anatomiaPatologicaTipo,
				congelacao: congelacao,
				congelacaoQtd: congelacaoQtd,
				opmesList: opmesList
			})

			fetch(`${config.host}/send-mail/Orcamento`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					method: "POST",
					body: JSON.stringify({
						...data,
						procedimentosLista: procedimentos,
						unidade: unidade,
						tipoAnestesia: tipoAnestesia,
						tipoAcomodacao: tipoAcomodacao,
						tipoAcomodacaoQtd: tipoAcomodacaoQtd,
						hemoterapia: hemoterapia,
						hemoterapiaQtd: hemoterapiaQtd,
						anatomiaPatologica: anatomiaPatologica,
						congelacao: congelacao,
						congelacaoQtd: congelacaoQtd,
						opmesList: opmesList
					}),
				})
				.then(res => {
					console.log(res.status)
					if (res.status === 200) {
						setOpen(false);
						setShowAlert(true);
						setSuccess(true);

						reset()

						setTimeout(() => {
							window.location.reload();
						}, 3000);
					} else {
						setOpen(false);
						setShowAlert(true);
						setSuccess(false);
					}
				})
				.catch(err => {
					setOpen(false);
					setShowAlert(true);
					setSuccess(false);

					console.log(err)
				})
		}
	}

	const handleChangeUnidade = event => setUnidade(event.target.value);
	const handleChangeTipoAnestesia = event => setTipoAnestesia(event.target.value);
	const handleChangeTipoAcomodacao = event => setTipoAcomodacao(event.target.value);
	const handleChangeHemoterapia = event => setHemoterapia(event.target.value);
	const handleChangeAnatomiaPatologica = event => setAnatomiaPatologica(event.target.value);
	const handleChangeCongelacao = event => setCongelacao(event.target.value);

	const handleChangeTuss = event => setCodTuss(event.target.value);
	const handleChangeNomeProcedimento = event => setNomeProcedimento(event.target.value);
	const handleChangeCobertura = event => setCobertura(event.target.value);
	const cleanProcedimentoStates = async () => {
		setCodTuss('')
		setNomeProcedimento('')
	}

	const handleAddProcedimento = () => {
		if (codTuss.trim() === '') {
			setErrTuss(true)
			return
		} else if (nomeProcedimento.trim() === '') {
			setErrNomeProcedimento(true)
			return
		} else if (cobertura.trim() === '') {
			setErrCobertura(true)
			return
		} else {
			setErrTuss(false)
			setErrNomeProcedimento(false)
			setErrCobertura(false)

			const newProcedimento = {
				codTuss: codTuss,
				nome: nomeProcedimento,
				cobertura: cobertura
			}

			setProcedimentos([...procedimentos, newProcedimento])
			cleanProcedimentoStates();

		}
	}
	const handleRemoveProcedimento = procedimento => setProcedimentos(procedimentos.filter(el => el !== procedimento))

	const handleChangeOpmes = event => {
		const { name, value } = event.target;
		setOpmesItem(prevState => ({ ...prevState, [name]: value }));
	}
	const handleRemoveOpmes = opmesItem => setOpmesList(opmesList.filter(el => el !== opmesItem))
	const cleanOpmesItem = () => setOpmesItem(initialStateOpmes);
	const handleAddOpmes = () => {
		if (opmesItem.modelo.trim() === '') {
			setErrModelo(true);
			return
		} else if (opmesItem.fornecedor.trim() === '') {
			setErrFornecedor(true);
			return
		} else if (opmesItem.quantidade.trim() === '') {
			setErrQuantidade(true);
			return
		} else if (opmesItem.descricaoMaterial.trim() === '') {
			setErrDescMaterial(true);
			return
		} else {
			setErrModelo(false);
			setErrFornecedor(false);
			setErrQuantidade(false);
			setErrDescMaterial(false);

			setOpmesList([...opmesList, opmesItem])
			cleanOpmesItem()
		}
	}

	return (
		<>
			<Header />
			<MenuRapido />
			<section id="orcamento-section">
				<Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container-orcamento" separator="›">
					<a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
						<HomeIcon id="breadcrumb-home-icon" />
					</a>
					<a
						color="inherit"
						href="/orcamento"
						onClick={() => handleClick("/orcamento")}
						className="breadcrumb-link"
					>
						Solicitação de Orçamento Particular
					</a>
				</Breadcrumbs>
				<div id="form-container">
					<h2 className="h2-gray-center">Solicitação de Orçamento Particular</h2>
					<p>
						<strong>Prezado Doutor,</strong> 
						<br/><br/>
						A Rede de Hospitais São Camilo de São Paulo disponibiliza pacotes cirúrgicos em diversas especialidades. 
						<strong> Nosso objetivo é tornar mais simples o seu dia a dia, facilitando o contato com seu paciente particular e 
						assegurando as melhores condições para a prática de uma medicina de excelência.</strong>
						<br/><br/>
						Para que possamos formalizar os valores a seu paciente com maior segurança, pedimos, por gentileza, o 
						preenchimento dos dados abaixo, e, caso o procedimento que procura não esteja disponível como pacote, 
						todos os detalhes serão imprescindíveis para atendermos com excelência as solicitações de orçamentos. 
						<br/><br/>
						Se preferir, consulte a Central de Orçamentos pelos contatos: (11) 3677-4444 Ramais: 4509 | 4026 ou 2066-7000 Ramal: 7174 | 7712, WhatsApp: (11) 99255-2119 ou e-mail: <a href="mailto:orcamentoparticular@hospitalsaocamilosp.org.br">orcamentoparticular@hospitalsaocamilosp.org.br</a> 
					</p>
					<form id="form-orcamento" onSubmit={handleSubmit(onSubmit)}>
						<span className="form-divisor">Dados do Paciente</span>
						<ThemeProvider theme={theme}>
							<TextField
								color="red"
								required
								className="input-big"
								label="Nome Completo"
								{...register('nomeCompleto', {
									required: true
								})}
							/>
							<TextField
								required
								label="Data de Nascimento"
								type="date"
								fullWidth
								InputLabelProps={{
									shrink: true
								}}
								{...register('dataNascimento', {
									required: true
								})}
							/>

							<InputMask
								{...register('cpf')}
								mask="999.999.999-99"
								
							>
								{() =>
									<TextField
										color="red"
										required
										fullWidth
										//error={errTel}
										//helperText={errTel ? `Insira um número válido` : ''}
										label="CPF"
										{...register('cpf', {
											required: true
										})}
									/>}
							</InputMask>

							<InputMask
								{...register('telefone')}
								mask="(99) 999999999"
								minLength="5"
								maskChar=" "
							>
								{() =>
									<TextField
										color="red"
										required
										fullWidth
										error={errTel}
										helperText={errTel ? `Insira um número válido` : ''}
										label="Telefone de Contato"
										{...register('telefone', {
											required: true
										})}
									/>}
							</InputMask>
							<TextField
								color="red"
								required
								label="E-mail"
								type="email"
								fullWidth
								{...register('email', {
									required: true
								})}
							/>
							<span className="form-divisor">Dados do(a) Médico(a) Solicitante</span>
							<TextField
								color="red"
								required
								className="input-big"
								label="Nome do(a) Médico(a)"
								{...register('nomeMedico', {
									required: true
								})}
							/>
							<TextField
								color="red"
								required
								className="input-big"
								label="Especialidade"
								{...register('especialidade', {
									required: true
								})}
							/>
							<TextField
								color="red"
								required
								type="number"
								className="input-big"
								label="CRM"
								{...register('crm', {
									required: true
								})}
							/>
							<TextField
								color="red"
								required
								label="E-mail do(a) Médico(a)"
								type="email"
								className="input-big"
								{...register('emailMedico', {
									required: true
								})}
							/>
							<InputMask
								{...register('celularMedico')}
								mask="(99) 99999-9999"
								maskChar=" "
							>
								{() => <TextField
									color="red"
									required
									fullWidth
									label="Celular com DDD"
									error={errCelMedico}
									helperText={errCelMedico ? `Insira um número válido` : ''}
									{...register('celularMedico', {
										required: true
									})} />}
							</InputMask>
							<InputMask
								{...register('telefoneFixo')}
								mask="(99) 9999-9999"
								maskChar=" "
							>
								{() => <TextField
									color="red"
									fullWidth
									label="Telefone Fixo"
									error={errTelMedico}
									helperText={errTelMedico ? `Insira um número válido` : ''}
									{...register('telefoneFixo')} />}
							</InputMask>
							<span className="form-divisor">Informações do(s) Procedimento(s)</span>
							<div className='procedimentos-wrapper'>
								<TextField
									label="Código TUSS"
									fullWidth
									error={errTuss}
									helperText={errTuss ? `Preencha o campo` : ''}
									value={codTuss}
									onChange={handleChangeTuss}
								/>
								<TextField
									label="Nome do Procedimento"
									className="input-big"
									error={errNomeProcedimento}
									helperText={errNomeProcedimento ? `Preencha o campo` : ''}
									value={nomeProcedimento}
									onChange={handleChangeNomeProcedimento}
								/>
								<FormControl error={errCobertura} helperText={errCobertura ? `Preencha o campo` : ''} fullWidth style={{marginTop: '20px'}} component="fieldset" onChange={handleChangeCobertura}>
									<FormLabel component="legend" style={{fontSize: '16px'}}>Cobertura:</FormLabel>
									<RadioGroup row aria-label="position" name="position">
										<FormControlLabel value="Particular" control={<Radio color="primary" />} label="Particular" />
										<FormControlLabel value="Convênio" control={<Radio color="primary" />} label="Convênio" />
									</RadioGroup>
								</FormControl>

								<div className="input-big center-div" onClick={() => handleAddProcedimento()}>
									<Fab color="secondary" aria-label="add">
										<AddIcon className="" />
									</Fab>
								</div>
								<div className="input-big center-div">
									Clique no + para incluir Procedimentos <br />
									
									OBS: O procedimento só será adicionado no orçamento caso seja incluído.
								</div>
								{
									procedimentos.length > 0 ?
										<div className="items-container">
											{
												procedimentos.map((el, index) =>
													<div className="procedimento-item-div input-big">
														<p id="index-item">{index + 1 + "."}</p>
														<p id="item-tuss">{el.codTuss}</p>
														<p id="item-procedimento">{el.nome}</p>
														<p id="item-cobertura">{el.cobertura}</p>
														<DeleteIcon id="icon-form" onClick={() => handleRemoveProcedimento(el)} />
													</div>
												)}
										</div>
										: null
								}
							</div>

							<TextField
								required
								label="Data do Procedimento"
								type="date"
								fullWidth
								InputLabelProps={{
									shrink: true
								}}
								{...register('dataProcedimento')}
							/>

							<TextField
								required
								label="Tempo de realização"
								type="number"
								fullWidth
								placeholder="Tempo em horas"
								{...register('tempoProcedimento')}
							/>

							<TextField
								required
								label="Quantidade"
								type="number"
								fullWidth
								{...register('quantidadeProcedimento')}
							/>

							<div className="form-radio-container">
								<FormControl name="unidade" required component="fieldset" onChange={handleChangeUnidade} error={errUnidade}>
									<FormLabel component="legend"><strong>Unidade</strong></FormLabel>
									<RadioGroup column aria-label="position" name="position">
										<FormControlLabel value="Ipiranga" control={<Radio color="primary" />} label="Ipiranga" />
										<FormControlLabel value="Pompeia" control={<Radio color="primary" />} label="Pompeia" />
										<FormControlLabel value="Santana" control={<Radio color="primary" />} label="Santana" />
									</RadioGroup>
								</FormControl>
							</div>
							<div className="form-radio-container">
								<FormControl name="tipoAnestesia" required component="fieldset" onChange={handleChangeTipoAnestesia} error={errAnestesia}>
									<FormLabel component="legend"><strong>Tipo de Anestesia</strong></FormLabel>
									<RadioGroup column aria-label="position" name="position">
										<FormControlLabel value="Geral" control={<Radio color="primary" />} label="Geral" />
										<FormControlLabel value="HSC Raqui" control={<Radio color="primary" />} label="HSC Raqui" />
										<FormControlLabel value="Peridural" control={<Radio color="primary" />} label="Peridural" />
										<FormControlLabel value="Local/Sedação" control={<Radio color="primary" />} label="Local/Sedação" />
										<FormControlLabel value="Sedação" control={<Radio color="primary" />} label="Sedação" />
									</RadioGroup>
								</FormControl>
							</div>
							<div className="form-radio-container">
								<FormLabel component="legend"><strong>Tipo de Acomodação</strong></FormLabel>
								{
									tipoAcomodacao !== null ?
										<TextField
											label="Quantidade"
											required
											value={tipoAcomodacaoQtd}
											error={errTipoAcomodacaoQtd}
											helperText={errTipoAcomodacaoQtd ? `Preencha o campo` : ''}
											onChange={evt => setTipoAcomodacaoQtd(evt.target.value)}
										/>
										: ''
								}
								<FormControl name="tipoAcomodacao" required component="fieldset" onChange={handleChangeTipoAcomodacao} error={errAcomodacao}>
									<RadioGroup column aria-label="position" name="position" type="radio">
										<FormControlLabel value="UTI" control={<Radio color="primary" />} label="UTI" />
										<FormControlLabel value="Apartamento" control={<Radio color="primary" />} label="Apartamento" />
										<FormControlLabel value="Hospital Dia" control={<Radio color="primary" />} label="Hospital Dia" />
									</RadioGroup>
								</FormControl>
							</div>
							<div className="form-radio-container">
								<FormLabel component="legend"><strong>Hemoterapia*</strong></FormLabel>
								{
									hemoterapia === "Sim" ?
										<TextField
											label="Tipo / Quantidade"
											required
											value={hemoterapiaQtd}
											error={errHemoterapiaQtd}
											helperText={errHemoterapiaQtd ? `Preencha o campo` : ''}
											onChange={evt => setHemoterapiaQtd(evt.target.value)}
										/>
										: ''
								}
								<FormControl name="hemoterapia" required component="fieldset" onChange={handleChangeHemoterapia} error={errHemoterapia}>
									{/* <FormLabel component="legend"><strong>Hemoterapia</strong></FormLabel> */}
									<RadioGroup column required aria-label="position" name="position">
										<FormControlLabel value="Sim" control={<Radio color="primary" />} label="Sim" />
										<FormControlLabel value="Não" control={<Radio color="primary" />} label="Não" />
									</RadioGroup>
								</FormControl>

							</div>
							<div className="form-radio-container">
								<FormLabel component="legend"><strong>Anatomia Patológica*</strong></FormLabel>
								{
									anatomiaPatologica === "Sim" ?
										<TextField
											label="Tipo / Quantidade"
											required
											value={anatomiaPatologicaTipo}
											error={errPatologiaQtd}
											helperText={errPatologiaQtd ? `Preencha o campo` : ''} errPatologiaQtd
											onChange={evt => setAnatomiaPatologicaTipo(evt.target.value)}
										/>
										: ''
								}
								<FormControl name="anatomiaPatologica" required component="fieldset" onChange={handleChangeAnatomiaPatologica} error={errPatologia}>
									<RadioGroup column required aria-label="position" name="position">
										<FormControlLabel value="Sim" control={<Radio color="primary" />} label="Sim" />
										<FormControlLabel value="Não" control={<Radio color="primary" />} label="Não" />
									</RadioGroup>
								</FormControl>
							</div>
							<div className="form-radio-container">
								<FormLabel component="legend"><strong>Congelação*</strong></FormLabel>
								{
									congelacao === "Sim" ?
										<TextField
											label="Tipo / Quantidade"
											required
											value={congelacaoQtd}
											error={errCongelacaoQtd}
											helperText={errCongelacaoQtd ? `Preencha o campo` : ''}
											onChange={evt => setCongelacaoQtd(evt.target.value)}
										/>
										: ''
								}
								<FormControl name="congelacao" required component="fieldset" onChange={handleChangeCongelacao} error={errCongelacao}>
									<RadioGroup column aria-label="position" name="position">
										<FormControlLabel value="Sim" control={<Radio color="primary" />} label="Sim" />
										<FormControlLabel value="Não" control={<Radio color="primary" />} label="Não" />
									</RadioGroup>
								</FormControl>

							</div>

							<span className="form-divisor">Órteses, Próteses e Materiais Especiais - OPMES</span>
							<TextField
								label="Modelo"
								name="modelo"
								className="input-big"
								value={opmesItem.modelo}
								error={errModelo}
								helperText={errModelo ? "Preenchimento obrigatório" : ''}
								onChange={handleChangeOpmes}
							/>
							<TextField
								label="Fornecedor"
								name="fornecedor"
								fullWidth
								value={opmesItem.fornecedor}
								error={errFornecedor}
								helperText={errFornecedor ? "Preenchimento obrigatório" : ''}
								onChange={handleChangeOpmes}
							/>
							<TextField
								label="Quantidade"
								name="quantidade"
								type="number"
								value={opmesItem.quantidade}
								fullWidth
								error={errQuantidade}
								helperText={errQuantidade ? "Preenchimento obrigatório" : ''}
								onChange={handleChangeOpmes}
							/>
							<TextField
								label="Descrição do Material"
								name="descricaoMaterial"
								value={opmesItem.descricaoMaterial}
								className="input-big"
								error={errDescMaterial}
								helperText={errDescMaterial ? "Preenchimento obrigatório" : ''}
								onChange={handleChangeOpmes}
							/>
							<div className="input-big center-div" onClick={handleAddOpmes}>
								<Fab color="secondary" aria-label="add">
									<AddIcon className="input-big" />
								</Fab>
							</div>
							<div className="input-big center-div">
								Clique no + para incluir Materiais
							</div>

							{
								opmesList.length > 0 ?
									<div className="items-container">
										{
											opmesList.map((el, index) =>
												<div className="input-big">
													<div className="opmes-item-div input-big">
														<p id="index-item">{index + 1 + "."}</p>
														<p id="item-modelo">{el.modelo}</p>
														<p id="item-fornecedor">{el.fornecedor}</p>
														<p id="item-quantidade">{el.quantidade}</p>
														<p id="item-descricao">{el.descricaoMaterial}</p>
														<DeleteIcon id="icon-form" onClick={() => handleRemoveOpmes(el)} />
													</div>
												</div>
											)}
									</div>
									: null
							}

							<span className="form-divisor">Observações Adicionais</span>
							<TextField
								fullWidth
								id="outlined-multiline-static"
								label="Observações"
								multiline
								rows={5}
								placeholder="Descrever itens adicionais."
								{...register('observacoes')}
								variant="outlined"
							/>
						</ThemeProvider>
						<div className="input-big center-div">
							<input className="center-div" id="submit" type="submit" />
						</div>
						<Modal
							disablePortal
							disableEnforceFocus
							disableAutoFocus
							className="modal-loading" open={open}
						>
							<CircularProgress color="inherit" />
						</Modal>
					</form>
				</div>
			</section>
			{
				showAlert ?
					success ?
						<Alert severity="success">
							<AlertTitle>Sucesso</AlertTitle>
							Sua mensagem foi enviada. <strong>Agradecemos o seu contato!</strong>
						</Alert>
						:
						<Alert severity="error">
							<AlertTitle>Erro</AlertTitle>
							<strong>Sua mensagem não foi enviada.</strong> Se o problema persistir favor entrar em contato.
						</Alert>
					:
					null
			}
			<Footer />
		</>
	)
}

export default Orcamento